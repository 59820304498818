import React,{useEffect,useRef,useState} from 'react'
import "./commandpallate.css";

let themeList = [
    {
        name:"Light",
        st:"Light",
        ct:"Light"
    },
    {
        name:"Atom One Light",
        st:"Atom_Light",
        ct:"Light"
    },
    {
        name:"GitHub Plus",
        st:"GitHub_Plus",
        ct:"Light"
    },
    {
        name:"Ayu",
        st:"Ayu",
        ct:"Light"
    },
    {
        name:"Dark",
        st:"Dark",
        ct:"Dark",
    },
    {
        name:"One Dark Pro",
        st:"One_Dark",
        ct:"Dark", 
    },
    {
        name:"Dracula",
        st:"Dracula",
        ct:"Dark",
    },
    {
        name:"Winter is Coming",
        st:"Winter",
        ct:"Dark",
    },
    {
        name:"Noctis",
        st:"Noctis",
        ct:"Dark",
    },
    {
        name:"Monokai Pro",
        st:"Monokai",
        ct:"Dark",
    },
    {
        name:"Panda",
        st:"Panda",
        ct:"Dark",
    }
];
let firstL=0;
let firstD=0;
const CommandPallate = ({setPallateStatus,theme,setTheme}) => {
    const [search, setSearch] = useState("");
    const [list, setList] = useState([])
    // var(--tab-active-color)
    const ref = useRef(null)
    
    useEffect(() => {
        ref.current.focus();
    }, []);

    const changeTheme = (theme) =>{
        let color=theme;
        setTheme(color);
        setPallateStatus(false);
    }

    useEffect(() => {
        let newarr = themeList.filter((val)=>{
            return val.name.toLowerCase().indexOf(search.toLowerCase())>-1
        })
        firstL=0;
        firstD=0;
        setList(newarr)
    }, [search])

    useEffect(() => {
        firstL=0;
        firstD=0;
        setList(themeList)
    }, [])
    

    return (
        <div id="command_pallate_container" dataSpy="scroll">
            <div className="pallate_content">
            <div className="pallate_input">
                <input ref={ref} type="text" placeholder='Search Color Theme' onChange={(e)=>setSearch(e.target.value)}/>
            </div>
            <div className="theme_options">
                <div className="light_section">
                    {
                        list.length>0 && list.map((val,index)=>{
                            if(val.ct==="Light"){
                                firstL++;
                                return(
                                    <div key={index} className="options first" style={theme===val.st?{backgroundColor:"var(--tab-active-color)",color:"var(--tab-active-font-color)"}:{}}  onClick={()=>changeTheme(val.st)}><span>{val.name}</span>
                                    {firstL===1?(<span style={theme!==val.st?{color:"var(--theme-color-name)"}:{}} >light themes</span>):""}
                                    </div>
                                )
                            }
                        })
                    }
                </div>
                <div className="dark_section">
                    {
                        list.length>0 && list.map((val,index)=>{
                            if(val.ct==="Dark"){
                                firstD++;
                                return(
                                    <div key={index} className="options first" style={theme===val.st?{backgroundColor:"var(--tab-active-color)",color:"var(--tab-active-font-color)"}:{}}  onClick={()=>changeTheme(val.st)}><span>{val.name}</span>
                                    {firstD===1?(<span style={theme!==val.st?{color:"var(--theme-color-name)"}:{}} >dark themes</span>):""}
                                    </div>
                                )
                            }
                        })
                    }
                </div>
            </div>
            </div>
        </div>
    )
}

export default CommandPallate

import * as AIFont from "react-icons/ai";
import * as FAFont from "react-icons/fa";
import * as SIFont from "react-icons/si";
import flashtype from "../assets/img/flashtype.png";
import covid from "../assets/img/covid.png";
import github from "../assets/img/github.png";
import resume from "../assets/img/resume.png";
import form from "../assets/img/form.png";
import lg from "../assets/img/lg.png";
import blog from "../assets/img/blog.png";
import wp from "../assets/img/wp.png";
import book from "../assets/img/book.png";
import rh from "../assets/img/rh.png";
import flashtypeLg from "../assets/img/flashtype-lg.png";
import covidLg from "../assets/img/covid-lg.png";
import githubLg from "../assets/img/github-lg.png";
import resumeLg from "../assets/img/resume-lg.png";
import formLg from "../assets/img/form-lg.png";
import lgLg from "../assets/img/lg-lg.png";
import blogLg from "../assets/img/blog-lg.png";
import wpLg from "../assets/img/wp-lg.png";
import bookLg from "../assets/img/book-lg.png";
import rhLg from "../assets/img/rh-lg.png";

export const projects = [
    {
        name:"Frontend Projects",
        data:[
            {
                icon:"fab fa-react",
                color:"#61DBFB",
                name:"Flashtype.jsx",
                data:{
                    description:"A Typing speed calculator that calculate your typing speed per minutes. This app is built using react and it’s cool libraries. In this app the random paragraph appears infront of user and textbox is given to user to type that paragraph. As user start typing the timer of 60 seconds get started. After 60 second get completed a result consisting of words per minute get displayed to users. Only the correct word typed by the user as compared to given paragraph gets validate at time of finding speed.",
                    techStack:<><SIFont.SiHtml5 size={24} color={"#E54C21"}/><SIFont.SiCss3 size={24} color={"#214CE5"}/><SIFont.SiJavascript size={24} color={"#FCDC00"}/><SIFont.SiReact size={24} color={"#61DBFB"}/></>,
                    text:"Live Preview",
                    linkText:"FlashType",
                    link:"http://pateljay.me/flashtype",
                    imgLink:<img src={flashtype} alt="project flashtype sm" style={{width:"300px",cursor:"pointer"}}/>,
                    imgLgLink:<img src={flashtypeLg} alt="project flashtype Lg" style={{width:"100%"}}/>
                }
            },
            {
                icon:"fab fa-angular",
                color:"#DD0031",
                name:"CovidStats.ts",
                data:{
                    description:"A Covid 19 stats provider of India. It uses cowin api to get latest updates on the number of cases. It has various features of getting month wise, day wise data of confirmed, Active, recovered, dieseased cases of all over the India. We can also able to see the state wise cases and also the day wise cases for each states. In this I have also used the ng chart js library to get better visualization of the states.",
                    techStack:<><SIFont.SiTypescript size={24} color={"#0079CC"}/><SIFont.SiMaterialui size={24} color={"#0266CB"}/></>,
                    text:"GitHub Link",
                    linkText:"CovidStats",
                    link:"https://github.com/jaypatel31/Covid-Stats-Angular",
                    imgLink:<img src={covid} alt="project covidstats sm" style={{width:"300px",cursor:"pointer"}}/>,
                    imgLgLink:<img src={covidLg} alt="project covidstats Lg" style={{width:"100%"}}/>
                }
            },
            {
                icon:"fab fa-react",
                color:"#61DBFB",
                name:"Github-Stats.jsx",
                data:{
                    description:"A react app to find out the github activity for different users. The visulization is made easy by displaying stats through line chart using chartjs. The user can search for any user activity by entering their username. User can also view the activity year wise by choosing the desired year. GitHub Api is integrated to retrieve the stats for the specific user.",
                    techStack:<><SIFont.SiHtml5 size={24} color={"#E54C21"}/><SIFont.SiCss3 size={24} color={"#214CE5"}/><SIFont.SiJavascript size={24} color={"#FCDC00"}/><FAFont.FaReact size={24} color={"#61DBFB"}/><SIFont.SiBootstrap size={24} color={"#800AF9"}/></>,
                    text:"Live Preview",
                    linkText:"Github Stats",
                    link:"http://pateljay.me/github-activity/",
                    imgLink:<img src={github} alt="project github stats sm" style={{width:"300px",cursor:"pointer"}}/>,
                    imgLgLink:<img src={githubLg} alt="project github stats sm" style={{width:"100%"}}/>
                }
            },
            {
                icon:"fab fa-react",
                color:"#61DBFB",
                name:"Web-Resume.jsx",
                data:{
                    description:"My online version of resume made with react. It provides all my achievements and education details till now. It uses several cool libraries for water effect, gallery view, stats counter and many more. I have also used third party mailing system that gets triggered while filling contact us form.",
                    techStack:<><SIFont.SiHtml5 size={24} color={"#E54C21"}/><SIFont.SiCss3 size={24} color={"#214CE5"}/><SIFont.SiJavascript size={24} color={"#FCDC00"}/><FAFont.FaReact size={24} color={"#61DBFB"}/></>,
                    text:"Live Preview",
                    linkText:"Web Resume",
                    link:"http://pateljay.me/web-resume",
                    imgLink:<img src={resume} alt="project Jay Patel Web Resume sm" style={{width:"300px",cursor:"pointer"}}/>,
                    imgLgLink:<img src={resumeLg} alt="project Jay Patel Web Resume Lg" style={{width:"100%"}}/>
                }
            },
        ]
    },
    {
        name:"Backend Projects",
        data:[
            {
                icon:"fab fa-php",
                color:"#3CA9F2",
                name:"Form-Builder.php",
                data:{
                    description:"A portal that can be used to make the form for any event, activity with an ease. This includes the dynamic functionality to add text, number, email, checkbox, radio, files filled. This also provide the extra features to add start and stop time of the form, to add title, description, can also change the color of the form. The user can maximum create 5 forms and can see the responses by downloading the excel files. This project also uses the PhpSpreadsheet library for storing result of the response.",
                    techStack:<><SIFont.SiHtml5 size={24} color={"#E54C21"}/><SIFont.SiCss3 size={24} color={"#214CE5"}/><SIFont.SiJavascript size={24} color={"#FCDC00"}/><SIFont.SiBootstrap size={24} color={"#800AF9"}/><SIFont.SiPhp size={24} color={"#787CB4"}/><SIFont.SiMysql size={24} color={"#00758f"}/></>,
                    text:"GitHub Link",
                    linkText:"Form Builder",
                    link:"https://github.com/jaypatel31/Form-Builder",
                    imgLink:<img src={form} alt="project form builder sm" style={{width:"300px",cursor:"pointer"}}/>,
                    imgLgLink:<img src={formLg} alt="project form builder Lg" style={{width:"100%"}}/>
                }
            },
            {
                icon:"fab fa-php",
                color:"#3CA9F2",
                name:"Logic-Hunt.php",
                data:{
                    description:"A dynamic quiz portal built for one of the campus clubs to conduct their online quiz event know as Logic Hunt. This portal support dynamic functionality for the users and also for the admin. The features like time bound, wrong questions penality, hint system, coin system, live leaderboard. Many strong algorithm and techniques like questions distribution, question shuffling are also used in the portal.",
                    techStack:<><SIFont.SiHtml5 size={24} color={"#E54C21"}/><SIFont.SiCss3 size={24} color={"#214CE5"}/><SIFont.SiJavascript size={24} color={"#FCDC00"}/><SIFont.SiBootstrap size={24} color={"#800AF9"}/><SIFont.SiPhp size={24} color={"#787CB4"}/><SIFont.SiMysql size={24} color={"#00758f"}/></>,
                    text:"GitHub Link",
                    linkText:"Logic Hunt",
                    link:"https://github.com/jaypatel31/Logic-hunt-overview",
                    imgLink:<img src={lg} alt="project logic hunt sm" style={{width:"300px",cursor:"pointer"}}/>,
                    imgLgLink:<img src={lgLg} alt="project logic hunt Lg" style={{width:"100%"}}/>
                }
            },
            {
                icon:"fab fa-node-js",
                color:"#68a063",
                name:"Blog-Website.js",
                data:{
                    description:"A simple blogging website in MERN stack where user can create blog, edit blog, delete blog. It also include feature to comment on the blogs. The blogs also gets filtered through their theme and author. User can also add the cover image for the blog. The user can login through the portal using SAWO passwordless authentication.",
                    techStack:<><SIFont.SiHtml5 size={24} color={"#E54C21"}/><SIFont.SiCss3 size={24} color={"#214CE5"}/><SIFont.SiJavascript size={24} color={"#FCDC00"}/><SIFont.SiTailwindcss size={24} color={"#1ac1ba"}/><FAFont.FaNodeJs size={24} color={"#68a063"}/><SIFont.SiExpress size={24} color={"#67737c"}/><SIFont.SiMongodb size={24} color={"#07ac4f"}/></>,
                    text:"GitHub Link",
                    linkText:"Blog Website",
                    link:"https://github.com/jaypatel31/blog-website",
                    imgLink:<img src={blog} alt="project blog website sm" style={{width:"300px",cursor:"pointer"}}/>,
                    imgLgLink:<img src={blogLg} alt="project blog website lg" style={{width:"100%"}}/>
                }
            },
            {
                icon:"fab fa-node-js",
                color:"#68a063",
                name:"Whatsapp-Clone.js",
                data:{
                    description:"A Clone of the whatsapp web built during the learning phase of MERN Stack. It includes the features like Real Time messaging, SAWO login & Google Auth Integrated, Edit user name and change profile photo. This project gave me the exposure to Socket.io package and I had also tried like login with SAWO API and some other stuff to get something different than the present version.",
                    techStack:<><SIFont.SiHtml5 size={24} color={"#E54C21"}/><SIFont.SiCss3 size={24} color={"#214CE5"}/><SIFont.SiJavascript size={24} color={"#FCDC00"}/><SIFont.SiMaterialui size={24} color={"#0266CB"}/><FAFont.FaNodeJs size={24} color={"#68a063"}/><SIFont.SiExpress size={24} color={"#67737c"}/><SIFont.SiMongodb size={24} color={"#07ac4f"}/></>,
                    text:"GitHub Link",
                    linkText:"Whatsapp Clone",
                    link:"https://github.com/jaypatel31/whatsapp-clone",
                    imgLink:<img src={wp} alt="project whatsapp clone sm" style={{width:"300px",cursor:"pointer"}}/>,
                    imgLgLink:<img src={wpLg} alt="project whatsapp clone Lg" style={{width:"100%"}}/>
                }
            },
        ]
    },
    {
        name:"Research Works",
        data:[
            {
                icon:"fas fa-file-word",
                color:"#2B599C",
                name:"Research-Paper.docx",
                data:{
                    description:"A research work carried out by me and my peers in the field of Big Data which shows how Big Data can be useful in management of water and environment resources. It also covers current challenges and limitations pertaining to Big Data are discussed and proper (in theory) solutions are proposed which seem to have promising results.",
                    techStack:<><AIFont.AiFillFileWord size={24} color={"#2B599C"}/><FAFont.FaChessKnight size={24} color={"#0D3662"}/></>,
                    text:"Paper Link",
                    linkText:"Advent of Big Data technology in environment and water management sector",
                    link:"https://link.springer.com/article/10.1007/s11356-021-14017-y",
                    imgLink:<img src={rh} alt="Big Data Research Paper sm" style={{width:"300px",cursor:"pointer"}}/>,
                    imgLgLink:<img src={rhLg} alt="Big Data Research Paper Lg" style={{width:"100%"}}/>
                }
            },
            {
                icon:"fas fa-file-word",
                color:"#2B599C",
                name:"Book-Chapter.docx",
                data:{
                    description:"A chapter of the book Advanced Security Solutions for Multimedia which provides a comprehensive summary of two well-known data security techniques, which are content watermarking and data hiding or steganography. A thorough review of both the methods is provided that covers multiple facets like history, applications, advancement, etc.",
                    techStack:<><AIFont.AiFillFileWord size={24} color={"#2B599C"}/><AIFont.AiFillBook size={24} color={"#CCDDF6"}/></>,
                    text:"Chapter Link",
                    linkText:"Content watermarking and data hiding in multimedia security",
                    link:"https://iopscience.iop.org/book/978-0-7503-3735-9/chapter/bk978-0-7503-3735-9ch3",
                    imgLink:<img src={book} alt="Cybersecurity Book Chapter sm" style={{width:"300px",cursor:"pointer"}}/>,
                    imgLgLink:<img src={bookLg} alt="Cybersecurity Book Chapter Lg" style={{width:"100%"}}/>
                }
            },
        ]
    }
];
const themeData = [];
 
themeData['Dark'] = {
    primary_color: "#1E1E1E",
    secondary_color:"#252526",
    font_color:"#fff",
    html_tag:"#569CD6",
    input_background:"#3c3c3c",
    var:"#569CD6",
    var_name:"#4FC1FF",
    string_name:"#CE9178",
    inbuilt_func:"#4EC9B0",
    custom_func:"#DCDCAA",
    tag_highlight:"#C586C0",
    tag_symbol:"#808080",
    import_name:"#9CDCFE",
    num_color:"#B5CEA8",
    comment_color:"#6A9955",
    sidebar_color:"#cccccc",
    sidebar_border_color:"rgba(204,204,204,0.2)",
    hover_color:"#2a2d2e",
    tab_active_color:"#094771",
    tab_content_color:"rgba(212,212,212,0.4)",
    theme_name_color:"#3794ff",
    number_system_color:"#858585",
    tab_active_font_color:"#fff",
    menubar_background_color:"#333333",
    menubar_active_color:"#fff",
    menubar_font_color:"rgba(255,255,255,0.4)",
    footer_background_color:"#007ACC",
    footer_font_color:"#fff",
    border_sidebar_color:"transparent",
    border_footer_color:"transparent",
    cp_background_color:"#252526",
    cp_font_color:"#ccc",
    tab_active_border_bottom_color:"transparent",
    tab_active_border_top_color:"transparent",
    mouse_cover:"#079bff",
};

themeData['One_Dark'] = {
    primary_color: "#282c34",
    secondary_color:"#21252b",
    font_color:"#DCDCDC",
    html_tag:"#e5c07b",
    input_background:"#1d1f23",
    var:"#C678DD",
    var_name:"#e5c07b",
    string_name:"#98c379",
    inbuilt_func:"#e5c07b",
    custom_func:"#61afef",
    tag_highlight:"#C678DD",
    tag_symbol:"#ABB2BF",
    import_name:"#e06c75",
    num_color:"#d19a66",
    comment_color:"#7f848e",
    sidebar_color:"#ABB2BF",
    sidebar_border_color:"rgba(204,204,204,0.2)",
    hover_color:"#2c313a",
    tab_active_color:"#323842",
    tab_content_color:"rgba(212,212,212,0.4)",
    theme_name_color:"#3794FF",
    number_system_color:"#495162",
    tab_active_font_color:"#D7DAE0",
    menubar_background_color:"#282c34",
    menubar_active_color:"rgb(215, 218, 224)",
    menubar_font_color:"rgba(215, 218, 224, 0.4)",
    footer_background_color:"#21252b",
    footer_font_color:"#4d78cc",
    border_sidebar_color:"transparent",
    border_footer_color:"transparent",
    cp_background_color:"#21252b",
    cp_font_color:"#ccc",
    tab_active_border_bottom_color:"transparent",
    tab_active_border_top_color:"transparent",
    mouse_cover:"#4d78cc",
};

themeData['Dracula'] = {
    primary_color: "#282a36",
    secondary_color:"#21222c",
    font_color:"#F8F8F2",
    html_tag:"#8be9fd",
    input_background:"#282a36",
    var:"#ff79c6",
    var_name:"#f8f8f2",
    string_name:"#f1fa8c",
    inbuilt_func:"#8be9fd",
    custom_func:"#50FA7B",
    tag_highlight:"#ff79c6",
    tag_symbol:"#F8F8F2",
    import_name:"#F8F8F2",
    num_color:"#BD93F9",
    comment_color:"#6272A4",
    sidebar_color:"#F8F8F2",
    sidebar_border_color:"rgb(25, 26, 33)",
    hover_color:"rgba(68, 71, 90, 0.46)",
    tab_active_color:"#44475a",
    tab_content_color:"rgba(33, 34, 41, 0.4)",
    theme_name_color:"#8BE9FD",
    number_system_color:"#6272A4",
    tab_active_font_color:"#F8F8F2",
    menubar_background_color:"#343746",
    menubar_active_color:"#9e5b8b",
    menubar_font_color:"rgb(98, 114, 164)",
    footer_background_color:"#191a21",
    footer_font_color:"#bd93f9",
    border_sidebar_color:"transparent",
    border_footer_color:"transparent",
    cp_background_color:"#21222c",
    cp_font_color:"#F8F8F2",
    tab_active_border_bottom_color:"transparent",
    tab_active_border_top_color:"rgba(255, 121, 198, 0.5)",
    mouse_cover:"#9e5b8b",
};

themeData['Winter'] = {
    primary_color: "#282822",
    secondary_color:"#282822",
    font_color:"#D2DEE7",
    html_tag:"#7FDBCA",
    input_background:"#0b253a",
    var:"#00BFF9",
    var_name:"#92B6F4",
    string_name:"#6BFF81",
    inbuilt_func:"#7FDBCA",
    custom_func:"#87AFF4",
    tag_highlight:"#00BFF9",
    tag_symbol:"#FFF",
    import_name:"#D3EED9",
    num_color:"#8DEC95",
    comment_color:"#999",
    sidebar_color:"#7799BB",
    sidebar_border_color:"rgba(33, 159, 213, 0.27)",
    hover_color:"#282822",//rgba(68, 71, 90, 0.46)
    tab_active_color:"#219fd5",
    tab_content_color:"rgba(212,212,212,0.4)",
    theme_name_color:"#3794FF",
    number_system_color:"#219FD5",
    tab_active_font_color:"#FFF",
    menubar_background_color:"#282822",
    menubar_active_color:"rgb(153, 208, 247)",
    menubar_font_color:"rgb(153, 208, 247,0.4)",
    footer_background_color:"#219fd5",
    footer_font_color:"#FFF",
    border_sidebar_color:"rgba(33, 159, 213, 0.27)",
    border_footer_color:"#122d42",
    cp_background_color:"#0b2942",
    cp_font_color:"#D6DEEB",
    tab_active_border_bottom_color:"transparent",
    tab_active_border_top_color:"#219fd5",
    mouse_cover:"#219fd5",
};

themeData['Noctis'] = {
    primary_color: "#052529",
    secondary_color:"#041d20",
    font_color:"#40D4E7",
    html_tag:"#49ACE9",
    input_background:"#052529",
    var:"#E66533",
    var_name:"#D5971A",
    string_name:"#49E9A6",
    inbuilt_func:"#D67E5C",
    custom_func:"#16A3B6",
    tag_highlight:"#DF769B",
    tag_symbol:"#B2CACD",
    import_name:"#E4B781",
    num_color:"#7060EB",
    comment_color:"#5B858B",
    sidebar_color:"#A5B5B5",
    sidebar_border_color:"#3e6f74",
    hover_color:"#0b515b",
    tab_active_color:"#0e6671",
    tab_content_color:"#3e6f74",
    theme_name_color:"#169FB1",
    number_system_color:"#4E6B6E",
    tab_active_font_color:"#ebfdff",
    menubar_background_color:"#052529",
    menubar_active_color:"#40d4e7",
    menubar_font_color:"rgba(22, 159, 177, 0.47)",
    footer_background_color:"#041d20",
    footer_font_color:"#169FB1",
    border_sidebar_color:"rgb(15, 20, 21)",
    border_footer_color:"#0f1415",
    cp_background_color:"#073940",
    cp_font_color:"#B2CACD",
    tab_active_border_bottom_color:"transparent",
    tab_active_border_top_color:"#40d4e7",
    mouse_cover:"#40d4e7",
};

themeData['Monokai'] = {
    primary_color: "#272822",
    secondary_color:"#1d1e19",
    font_color:"#e6db74",
    html_tag:"#F92672",
    input_background:"#3b3c35",
    var:"#66D9EF",
    var_name:"#AE81FF",
    string_name:"#e6db74",
    inbuilt_func:"#66D9EF",
    custom_func:"#A6E22E",
    tag_highlight:"#F92672",
    tag_symbol:"#919288",
    import_name:"#FDFFF1",
    num_color:"#AE81FF",
    comment_color:"#6E7066",
    sidebar_color:"#919288",
    sidebar_border_color:"#6e7066",
    hover_color:"rgba(253, 255, 241, 0.05)",
    tab_active_color:"rgba(253, 255, 241, 0.05)",
    tab_content_color:"rgb(88,88,88)",
    theme_name_color:"#57584F",
    number_system_color:"#575848",
    tab_active_font_color:"#e6db74",
    menubar_background_color:"#161613",
    menubar_active_color:"rgb(192, 193, 181)",
    menubar_font_color:"rgb(87, 88, 79)",
    footer_background_color:"#1d1e19",
    footer_font_color:"rgb(166, 226, 46)",
    border_sidebar_color:"rgb(22, 22, 19)",
    border_footer_color:"#161613",
    cp_background_color:"#3b3c35",
    cp_font_color:"#FDFFF1",
    tab_active_border_bottom_color:"#e6db74",
    tab_active_border_top_color:"transparent",
    mouse_cover:"rgb(166, 226, 46)",
};

themeData['Panda'] = {
    primary_color: "#292a2b",
    secondary_color:"#242526",
    font_color:"#19F9D8",
    html_tag:"#FF75B5",
    input_background:"#3c3c3c",
    var:"#FFB86C",
    var_name:"#FF9AC1",
    string_name:"#19F9D8",
    inbuilt_func:"#FFCC95",
    custom_func:"#6FC1FF",
    tag_highlight:"#FF75B5",
    tag_symbol:"#FFCC95",
    import_name:"#e6e6e6",
    num_color:"#FFB86C",
    comment_color:"#676B79",
    sidebar_color:"#bbb",
    sidebar_border_color:"rgb(88,88,88)",
    hover_color:"#292a2b",
    tab_active_color:"#292a2b",
    tab_content_color:"rgb(88,88,88)",
    theme_name_color:"#3794FF",
    number_system_color:"#858585",
    tab_active_font_color:"#19f9d8",
    menubar_background_color:"#222223",
    menubar_active_color:"rgb(230, 230, 230)",
    menubar_font_color:"rgba(230, 230, 230,0.4)",
    footer_background_color:"#222223",
    footer_font_color:"#19f9d8",
    border_sidebar_color:"rgb(34, 34, 35)",
    border_footer_color:"transparent",
    cp_background_color:"#2a2c2d",
    cp_font_color:"#e6e6e6",
    tab_active_border_bottom_color:"#19f9d8",
    tab_active_border_top_color:"transparent",
    mouse_cover:"#19f9d8",
};

themeData['Light'] = {
    primary_color: "#fff",
    secondary_color:"#f3f3f3",
    font_color:"#000",
    html_tag:"#800000",
    input_background:"#fff",
    var:"#0000ff",
    var_name:"#0070c1",
    string_name:"#a31515",
    inbuilt_func:"#267f99",
    custom_func:"#795e26",
    tag_highlight:"#af00db",
    tag_symbol:"#800000",
    import_name:"#001080",
    num_color:"#098658",
    comment_color:"#008000",
    sidebar_color:"#616161",
    sidebar_border_color:"rgba(97,97,97,0.2)",
    hover_color:"#e8e8e8",
    tab_active_color:"#0060c0",
    tab_content_color:"rgba(105,105,105,0.4)",
    theme_name_color:"#0066bf",
    number_system_color:"#237893",
    tab_active_font_color:"#fff",
    menubar_background_color:"#333333",
    menubar_active_color:"#fff",
    menubar_font_color:"rgba(255,255,255,0.4)",
    footer_background_color:"#007ACC",
    footer_font_color:"#fff",
    border_sidebar_color:"transparent",
    border_footer_color:"transparent",
    cp_background_color:"#f3f3f3",
    cp_font_color:"#616161",
    tab_active_border_bottom_color:"transparent",
    tab_active_border_top_color:"transparent",
    mouse_cover:"#007ACC",
};

themeData['Atom_Light'] = {
    primary_color: "#fafafa",
    secondary_color:"#eaeaeb",
    font_color:"#121417",
    html_tag:"#c18401",
    input_background:"#fff",
    var:"#a626a4",
    var_name:"#986801",
    string_name:"#50a14f",
    inbuilt_func:"#c18401",
    custom_func:"#0184bc",
    tag_highlight:"#a626a4",
    tag_symbol:"#383a42",
    import_name:"#e45649",
    num_color:"#986801",
    comment_color:"#a0a1a7",
    sidebar_color:"#616161",
    sidebar_border_color:"rgba(161,161,161,0.2)",
    hover_color:"rgba(219,219,220,0.4)",
    tab_active_color:"#dbdbdc",
    tab_content_color:"rgba(169,169,169,0.4)",
    theme_name_color:"#0066bf",
    number_system_color:"#9d9d9f",
    tab_active_font_color:"#232323",
    menubar_background_color:"#fafafa",
    menubar_active_color:"rgb(18, 20, 23)",
    menubar_font_color:"rgba(18, 20, 23, 0.4)",
    footer_background_color:"#eaeaeb",
    footer_font_color:"#526fff",
    border_sidebar_color:"transparent",
    border_footer_color:"transparent",
    cp_background_color:"#eaeaeb",
    cp_font_color:"#616161",
    tab_active_border_bottom_color:"transparent",
    tab_active_border_top_color:"transparent",
    mouse_cover:"#526fff",
};

themeData['GitHub_Plus'] = {
    primary_color: "#fff",
    secondary_color:"#fafbfc",
    font_color:"#333",
    html_tag:"#005CC5",
    input_background:"#fff",
    var:"#D73A49",
    var_name:"#005CC5",
    string_name:"#22863A",
    inbuilt_func:"#005CC5",
    custom_func:"#6F42C1",
    tag_highlight:"#D73A49",
    tag_symbol:"#24292E",
    import_name:"#24292E",
    num_color:"#005CC5",
    comment_color:"#6A737D",
    sidebar_color:"#24292E",
    sidebar_border_color:"rgba(161,161,161,0.2)",
    hover_color:"#f0f0f0",
    tab_active_color:"#0060C0",
    tab_content_color:"#586069",
    theme_name_color:"#0066BF",
    number_system_color:"#CCC",
    tab_active_font_color:"#fff",
    menubar_background_color:"#24292e",
    menubar_active_color:"rgb(250, 251, 252)",
    menubar_font_color:"rgba(250, 251, 252, 0.4)",
    footer_background_color:"#fafbfc",
    footer_font_color:"#54a3ff",
    border_sidebar_color:"rgb(225, 228, 232)",
    border_footer_color:"#e1e4e8",
    cp_background_color:"#f3f3f3",
    cp_font_color:"#616161",
    tab_active_border_bottom_color:"#e36209",
    tab_active_border_top_color:"transparent",
    mouse_cover:"#54a3ff",
};

themeData['Ayu'] = {
    primary_color: "#f8f9fa",
    secondary_color:"#f8f9fa",
    font_color:"#5C6166",
    html_tag:"#399EE6",
    input_background:"#fcfcfc",
    var:"#FA8D3E",
    var_name:"#5C6166",
    string_name:"#86B300",
    inbuilt_func:"#55B4D4",
    custom_func:"#F2AE49",
    tag_highlight:"#FA8D3E",
    tag_symbol:"#5C6166",
    import_name:"#5C6166",
    num_color:"#A37ACC",
    comment_color:"#787B8099",
    sidebar_color:"#8A9199",
    sidebar_border_color:"rgba(161,161,161,0.2)",
    hover_color:"rgba(86, 114, 143, 0.12)",
    tab_active_color:"rgba(86, 114, 143, 0.12)",
    tab_content_color:"#8A9199",
    theme_name_color:"#8A919980",
    number_system_color:"#8A9199CC",
    tab_active_font_color:"#5C6166",
    menubar_background_color:"#f8f9fa",
    menubar_active_color:"#ffaa33",
    menubar_font_color:"rgba(138, 145, 153, 0.6)",
    footer_background_color:"#f8f9fa",
    footer_font_color:"#ffaa33",
    border_sidebar_color:"rgb(225, 228, 232)",
    border_footer_color:"#e1e4e8",
    cp_background_color:"#f3f4f5",
    cp_font_color:"#8A9199",
    tab_active_border_bottom_color:"#ffaa33",
    tab_active_border_top_color:"transparent",
    mouse_cover:"#ffaa33"
};



export default themeData;
import React, {useState, useEffect} from 'react'
import "./skillinfo.css"
import Loader from '../loader/Loader'
import { Helmet } from 'react-helmet'

const SkillInfo = ({loading,setLoading,insideDate,currentData,setPallateStatus}) => {
    const [scipt, setScipt] = useState("")
    let x;

    useEffect(() => {
        const load = async () =>{
            if(x) x.destroy()
            x = await window['vantaeffect']();
            setLoading(prevState=>!prevState)
        }
        load()
    }, [insideDate])

//   useEffect(() => {
//     return () => {
//         x.destroy()
//         setLoading(true)
//     }
// }, [])

    
    

    return (
        <div id="skillinfo_container" onClick={()=>setPallateStatus(false)}>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Jay Patel - Skills</title>
                <link rel="canonical" href="https://pateljay.me/#/skills" />
                <meta name="description" content="All the technical skills of Jay Patel acquired in the various Tech Stack." />
            </Helmet>
            {(insideDate && insideDate.data.length)?(
                <>
                    <div className="skill_info_heading"><span style={{fontFamily:"Consolas-Bold",padding:"0px 5px",display:"inline-block",fontSize:"30px"}}>{`${insideDate.name.slice(0,insideDate.name.indexOf("."))} ${(insideDate.name.indexOf("Frontend")>-1 || insideDate.name.indexOf("Backend")>-1)?"Developement":""}`}</span></div>

                    {
                        insideDate.data.map((skill,index)=>{
                            
                            return(
                                <div className="skill_container" key={index}>
                                    <div className="skill_container_info">
                                        <div className="skill_left">
                                            {skill.icon}
                                            <div className="skill_name">{skill.name}</div>
                                        </div>
                                        <div className="skill_right">{skill.value}</div>
                                    </div>
                                    <div className="skill_bar_container">
                                        <div className="skill_bar" style={{width:`${skill.value}`,background:`${skill.color}`,boxShadow:`0px 0px 2px 3px ${skill.color}55`}}></div>
                                    </div>
                                </div>
                            )
                        })
                    }

                    
                </>
            ):<Loader/>}
        </div>
    )
}

export default SkillInfo

import React,{useEffect,useState} from 'react'
import "./homeinfo.css"
import Loader from '../loader/Loader'
import img from "../../assets/img/Home.gif"
import { Helmet } from 'react-helmet'

const HomeInfo = ({loading,setLoading,setPallateStatus}) => {

    useEffect(() => {
        // const load = async () =>{ 
        //     let x = await window['fc']();
        //     setLoading(prevState=>!prevState)
        // }
        // //load()
        window['setTextAnimation'](0.1,3.2,1,'linear','var(--font-color)',false);
        return () => {
            setLoading(true)
        }
    }, [])

    return (
        <>
        <div className="home_container" onClick={()=>setPallateStatus(false)}>
		<Helmet>
                <meta charSet="utf-8" />
                <title>Jay Patel</title>
                <link rel="canonical" href="https://pateljay.me/" />
                <meta name="description" content="Jay Patel is a Fusion of Researcher, Public Speaker, Community Contributor, and Engineer who has expertise in the field of Web Development, AI/ML, Open Source, Cybersecurity, etc." />
            </Helmet>
        {/* {
            (loading)?(
                <Loader/>  
            ):"" 
        } */}
            
        
                    <div className="heading"><span className="html_tag">&lt;h1&gt;</span><h1 style={{fontFamily:"Consolas-Bold",padding:"0px 5px",display:"inline-block",fontSize:"30px"}}>Patel Jay</h1><span className="html_tag">&lt;/h1&gt;</span></div>
                    <div className="gif_container">
                        <img src={img} id="c" style={{overflow:"hidden"}} alt="Home Page Gif"/>
                    </div>
                    
                    {(window.innerWidth > 686)?(
                        <>
                        <svg width="321.36" height="37.44" id="wb" className="position_box" viewBox="0 0 321.36 37.44" xmlns="http://www.w3.org/2000/svg">
	<g id="svgGroup" strokeLinecap="round" fillRule="evenodd" fontSize="9pt" stroke="#000" strokeWidth="0.25mm" fill="none" style={{stroke:"#000",strokeWidth:"0.25mm",fill:"none"}}>
		<path d="M 38.56 1.68 L 44.76 1.68 L 35.6 29.68 L 28.64 29.68 L 22.48 10.72 L 16.12 29.68 L 9.2 29.68 L 0 1.68 L 6.72 1.68 L 13.04 21.36 L 19.64 1.68 L 25.64 1.68 L 32.04 21.52 L 38.56 1.68 Z" id="0" vectorEffect="non-scaling-stroke"/>
		<path d="M 67.28 20.68 L 51 20.68 Q 51.44 22.68 53.08 23.84 Q 54.72 25 57.16 25 Q 58.84 25 60.14 24.5 Q 61.44 24 62.56 22.92 L 65.88 26.52 A 9.777 9.777 0 0 1 60.76 29.57 Q 59.16 29.973 57.255 29.998 A 19.291 19.291 0 0 1 57 30 Q 53.36 30 50.56 28.58 Q 47.76 27.16 46.24 24.64 A 10.65 10.65 0 0 1 44.744 19.715 A 12.96 12.96 0 0 1 44.72 18.92 A 11.442 11.442 0 0 1 45.366 15.04 A 10.568 10.568 0 0 1 46.22 13.22 Q 47.72 10.68 50.34 9.26 A 11.851 11.851 0 0 1 55.301 7.868 A 14.22 14.22 0 0 1 56.2 7.84 A 12.664 12.664 0 0 1 59.916 8.371 A 11.282 11.282 0 0 1 61.92 9.2 Q 64.48 10.56 65.94 13.1 Q 67.4 15.64 67.4 19 Q 67.4 19.113 67.293 20.508 A 495.184 495.184 0 0 1 67.28 20.68 Z M 50.92 17.04 L 61.52 17.04 A 5.728 5.728 0 0 0 60.961 15.282 A 4.912 4.912 0 0 0 59.76 13.78 A 5.122 5.122 0 0 0 56.812 12.584 A 6.662 6.662 0 0 0 56.24 12.56 A 6.191 6.191 0 0 0 54.487 12.798 A 4.991 4.991 0 0 0 52.68 13.76 A 4.916 4.916 0 0 0 51.071 16.321 A 6.551 6.551 0 0 0 50.92 17.04 Z" id="1" vectorEffect="non-scaling-stroke"/>
		<path d="M 77.52 27.2 L 77.52 29.68 L 71.56 29.68 L 71.56 0 L 77.8 0 L 77.8 10.44 A 7.761 7.761 0 0 1 82.388 7.993 A 11.256 11.256 0 0 1 84.28 7.84 Q 87.28 7.84 89.74 9.22 Q 92.2 10.6 93.6 13.1 A 10.909 10.909 0 0 1 94.863 16.925 A 14.105 14.105 0 0 1 95 18.92 A 13.322 13.322 0 0 1 94.661 21.989 A 10.574 10.574 0 0 1 93.6 24.74 Q 92.2 27.24 89.74 28.62 Q 87.28 30 84.28 30 A 11.204 11.204 0 0 1 81.536 29.684 A 7.422 7.422 0 0 1 77.52 27.2 Z M 83.2 24.88 Q 85.6 24.88 87.14 23.26 Q 88.68 21.64 88.68 18.92 A 7.787 7.787 0 0 0 88.472 17.071 A 5.401 5.401 0 0 0 87.14 14.58 A 5.137 5.137 0 0 0 83.497 12.966 A 6.88 6.88 0 0 0 83.2 12.96 Q 80.8 12.96 79.26 14.58 Q 77.72 16.2 77.72 18.92 A 7.787 7.787 0 0 0 77.928 20.769 A 5.401 5.401 0 0 0 79.26 23.26 A 5.137 5.137 0 0 0 82.903 24.874 A 6.88 6.88 0 0 0 83.2 24.88 Z" id="2" vectorEffect="non-scaling-stroke"/>
		<path d="M 110.92 29.68 L 110.92 1.68 L 123.64 1.68 Q 128.2 1.68 131.7 3.42 A 13.308 13.308 0 0 1 135.999 6.741 A 12.842 12.842 0 0 1 137.14 8.32 A 13.107 13.107 0 0 1 138.959 13.645 A 16.649 16.649 0 0 1 139.08 15.68 Q 139.08 19.88 137.14 23.04 A 13.003 13.003 0 0 1 131.809 27.885 A 15.468 15.468 0 0 1 131.7 27.94 A 16.493 16.493 0 0 1 126.65 29.474 A 21.317 21.317 0 0 1 123.64 29.68 L 110.92 29.68 Z M 117.4 24.36 L 123.32 24.36 Q 127.52 24.36 130.02 22.02 A 7.744 7.744 0 0 0 132.38 17.478 A 11.12 11.12 0 0 0 132.52 15.68 A 10.319 10.319 0 0 0 132.169 12.914 A 7.569 7.569 0 0 0 130.02 9.34 A 8.422 8.422 0 0 0 126.007 7.277 A 12.362 12.362 0 0 0 123.32 7 L 117.4 7 L 117.4 24.36 Z" id="4" vectorEffect="non-scaling-stroke"/>
		<path d="M 164.48 20.68 L 148.2 20.68 Q 148.64 22.68 150.28 23.84 Q 151.92 25 154.36 25 Q 156.04 25 157.34 24.5 Q 158.64 24 159.76 22.92 L 163.08 26.52 A 9.777 9.777 0 0 1 157.96 29.57 Q 156.36 29.973 154.455 29.998 A 19.291 19.291 0 0 1 154.2 30 Q 150.56 30 147.76 28.58 Q 144.96 27.16 143.44 24.64 A 10.65 10.65 0 0 1 141.944 19.715 A 12.96 12.96 0 0 1 141.92 18.92 A 11.442 11.442 0 0 1 142.566 15.04 A 10.568 10.568 0 0 1 143.42 13.22 Q 144.92 10.68 147.54 9.26 A 11.851 11.851 0 0 1 152.501 7.868 A 14.22 14.22 0 0 1 153.4 7.84 A 12.664 12.664 0 0 1 157.116 8.371 A 11.282 11.282 0 0 1 159.12 9.2 Q 161.68 10.56 163.14 13.1 Q 164.6 15.64 164.6 19 Q 164.6 19.113 164.493 20.508 A 495.184 495.184 0 0 1 164.48 20.68 Z M 148.12 17.04 L 158.72 17.04 A 5.728 5.728 0 0 0 158.161 15.282 A 4.912 4.912 0 0 0 156.96 13.78 A 5.122 5.122 0 0 0 154.012 12.584 A 6.662 6.662 0 0 0 153.44 12.56 A 6.191 6.191 0 0 0 151.687 12.798 A 4.991 4.991 0 0 0 149.88 13.76 A 4.916 4.916 0 0 0 148.271 16.321 A 6.551 6.551 0 0 0 148.12 17.04 Z" id="5" vectorEffect="non-scaling-stroke"/>
		<path d="M 183.72 8.16 L 189.72 8.16 L 180.64 29.68 L 174.2 29.68 L 165.16 8.16 L 171.6 8.16 L 177.56 22.8 L 183.72 8.16 Z" id="6" vectorEffect="non-scaling-stroke"/>
		<path d="M 212.64 20.68 L 196.36 20.68 Q 196.8 22.68 198.44 23.84 Q 200.08 25 202.52 25 Q 204.2 25 205.5 24.5 Q 206.8 24 207.92 22.92 L 211.24 26.52 A 9.777 9.777 0 0 1 206.12 29.57 Q 204.52 29.973 202.615 29.998 A 19.291 19.291 0 0 1 202.36 30 Q 198.72 30 195.92 28.58 Q 193.12 27.16 191.6 24.64 A 10.65 10.65 0 0 1 190.104 19.715 A 12.96 12.96 0 0 1 190.08 18.92 A 11.442 11.442 0 0 1 190.726 15.04 A 10.568 10.568 0 0 1 191.58 13.22 Q 193.08 10.68 195.7 9.26 A 11.851 11.851 0 0 1 200.661 7.868 A 14.22 14.22 0 0 1 201.56 7.84 A 12.664 12.664 0 0 1 205.276 8.371 A 11.282 11.282 0 0 1 207.28 9.2 Q 209.84 10.56 211.3 13.1 Q 212.76 15.64 212.76 19 Q 212.76 19.113 212.653 20.508 A 495.184 495.184 0 0 1 212.64 20.68 Z M 196.28 17.04 L 206.88 17.04 A 5.728 5.728 0 0 0 206.321 15.282 A 4.912 4.912 0 0 0 205.12 13.78 A 5.122 5.122 0 0 0 202.172 12.584 A 6.662 6.662 0 0 0 201.6 12.56 A 6.191 6.191 0 0 0 199.847 12.798 A 4.991 4.991 0 0 0 198.04 13.76 A 4.916 4.916 0 0 0 196.431 16.321 A 6.551 6.551 0 0 0 196.28 17.04 Z" id="7" vectorEffect="non-scaling-stroke"/>
		<path d="M 216.92 29.68 L 216.92 0 L 223.16 0 L 223.16 29.68 L 216.92 29.68 Z" id="8" vectorEffect="non-scaling-stroke"/>
		<path d="M 235.596 29.551 A 13.985 13.985 0 0 0 239.2 30 A 15.164 15.164 0 0 0 240.754 29.922 A 12.248 12.248 0 0 0 245.28 28.58 Q 247.96 27.16 249.48 24.64 A 10.283 10.283 0 0 0 250.464 22.48 A 11.525 11.525 0 0 0 251 18.92 A 12.96 12.96 0 0 0 250.976 18.125 A 10.65 10.65 0 0 0 249.48 13.2 Q 247.96 10.68 245.28 9.26 A 11.761 11.761 0 0 0 242.75 8.277 A 13.938 13.938 0 0 0 239.2 7.84 A 15.263 15.263 0 0 0 237.746 7.908 A 12.418 12.418 0 0 0 233.1 9.26 Q 230.4 10.68 228.88 13.2 A 10.283 10.283 0 0 0 227.896 15.36 A 11.525 11.525 0 0 0 227.36 18.92 A 12.96 12.96 0 0 0 227.384 19.715 A 10.65 10.65 0 0 0 228.88 24.64 Q 230.4 27.16 233.1 28.58 A 11.921 11.921 0 0 0 235.596 29.551 Z M 239.2 24.88 Q 241.6 24.88 243.14 23.26 Q 244.68 21.64 244.68 18.92 A 7.787 7.787 0 0 0 244.472 17.071 A 5.401 5.401 0 0 0 243.14 14.58 A 5.137 5.137 0 0 0 239.497 12.966 A 6.88 6.88 0 0 0 239.2 12.96 A 5.821 5.821 0 0 0 237.197 13.293 A 5.123 5.123 0 0 0 235.24 14.58 A 5.443 5.443 0 0 0 233.833 17.325 A 7.917 7.917 0 0 0 233.68 18.92 A 7.676 7.676 0 0 0 233.895 20.787 A 5.376 5.376 0 0 0 235.24 23.26 A 5.218 5.218 0 0 0 238.977 24.876 A 6.94 6.94 0 0 0 239.2 24.88 Z" id="9" vectorEffect="non-scaling-stroke"/>
		<path d="M 261.4 27.4 L 261.4 37.44 L 255.16 37.44 L 255.16 8.16 L 261.12 8.16 L 261.12 10.64 A 7.488 7.488 0 0 1 265.431 8.087 A 11.488 11.488 0 0 1 267.88 7.84 Q 270.88 7.84 273.34 9.22 Q 275.8 10.6 277.2 13.1 A 10.909 10.909 0 0 1 278.463 16.925 A 14.105 14.105 0 0 1 278.6 18.92 A 13.322 13.322 0 0 1 278.261 21.989 A 10.574 10.574 0 0 1 277.2 24.74 Q 275.8 27.24 273.34 28.62 Q 270.88 30 267.88 30 A 10.498 10.498 0 0 1 265.092 29.649 A 7.597 7.597 0 0 1 261.4 27.4 Z M 266.8 24.88 Q 269.2 24.88 270.74 23.26 Q 272.28 21.64 272.28 18.92 A 7.787 7.787 0 0 0 272.072 17.071 A 5.401 5.401 0 0 0 270.74 14.58 A 5.137 5.137 0 0 0 267.097 12.966 A 6.88 6.88 0 0 0 266.8 12.96 Q 264.4 12.96 262.86 14.58 Q 261.32 16.2 261.32 18.92 A 7.787 7.787 0 0 0 261.528 20.769 A 5.401 5.401 0 0 0 262.86 23.26 A 5.137 5.137 0 0 0 266.503 24.874 A 6.88 6.88 0 0 0 266.8 24.88 Z" id="10" vectorEffect="non-scaling-stroke"/>
		<path d="M 303.72 20.68 L 287.44 20.68 Q 287.88 22.68 289.52 23.84 Q 291.16 25 293.6 25 Q 295.28 25 296.58 24.5 Q 297.88 24 299 22.92 L 302.32 26.52 A 9.777 9.777 0 0 1 297.2 29.57 Q 295.6 29.973 293.695 29.998 A 19.291 19.291 0 0 1 293.44 30 Q 289.8 30 287 28.58 Q 284.2 27.16 282.68 24.64 A 10.65 10.65 0 0 1 281.184 19.715 A 12.96 12.96 0 0 1 281.16 18.92 A 11.442 11.442 0 0 1 281.806 15.04 A 10.568 10.568 0 0 1 282.66 13.22 Q 284.16 10.68 286.78 9.26 A 11.851 11.851 0 0 1 291.741 7.868 A 14.22 14.22 0 0 1 292.64 7.84 A 12.664 12.664 0 0 1 296.356 8.371 A 11.282 11.282 0 0 1 298.36 9.2 Q 300.92 10.56 302.38 13.1 Q 303.84 15.64 303.84 19 Q 303.84 19.113 303.733 20.508 A 495.184 495.184 0 0 1 303.72 20.68 Z M 287.36 17.04 L 297.96 17.04 A 5.728 5.728 0 0 0 297.401 15.282 A 4.912 4.912 0 0 0 296.2 13.78 A 5.122 5.122 0 0 0 293.252 12.584 A 6.662 6.662 0 0 0 292.68 12.56 A 6.191 6.191 0 0 0 290.927 12.798 A 4.991 4.991 0 0 0 289.12 13.76 A 4.916 4.916 0 0 0 287.511 16.321 A 6.551 6.551 0 0 0 287.36 17.04 Z" id="11" vectorEffect="non-scaling-stroke"/>
		<path d="M 321.36 7.84 L 321.36 13.6 A 62.949 62.949 0 0 0 320.938 13.569 Q 320.241 13.52 319.96 13.52 A 7.732 7.732 0 0 0 318.255 13.697 Q 317.015 13.977 316.118 14.701 A 4.779 4.779 0 0 0 315.76 15.02 Q 314.485 16.278 314.28 18.59 A 10.494 10.494 0 0 0 314.24 19.52 L 314.24 29.68 L 308 29.68 L 308 8.16 L 313.96 8.16 L 313.96 11 A 6.85 6.85 0 0 1 316.631 8.797 A 8.238 8.238 0 0 1 316.98 8.64 A 9.974 9.974 0 0 1 319.371 7.982 A 13.324 13.324 0 0 1 321.36 7.84 Z" id="12" vectorEffect="non-scaling-stroke"/>
	</g>
</svg>

<svg width="231.6" height="32" id="rh" className="position_box" viewBox="0 0 231.6 30" xmlns="http://www.w3.org/2000/svg">
	<g id="svgGroup" strokeLinecap="round" fillRule="evenodd" fontSize="9pt" stroke="#000" strokeWidth="0.25mm" fill="none" style={{stroke:"#000",strokeWidth:"0.25mm",fill:"none"}}>
		<path d="M 24.8 29.68 L 17.84 29.68 L 12.44 21.88 L 12.12 21.88 L 6.48 21.88 L 6.48 29.68 L 0 29.68 L 0 1.68 L 12.12 1.68 Q 15.84 1.68 18.58 2.92 A 10.449 10.449 0 0 1 21.168 4.559 A 8.979 8.979 0 0 1 22.8 6.44 A 9.131 9.131 0 0 1 24.169 10.197 A 11.846 11.846 0 0 1 24.28 11.84 A 10.891 10.891 0 0 1 23.95 14.576 A 8.673 8.673 0 0 1 22.78 17.22 A 9.194 9.194 0 0 1 19.511 20.193 A 11.507 11.507 0 0 1 18.52 20.68 L 24.8 29.68 Z M 11.76 6.96 L 6.48 6.96 L 6.48 16.72 L 11.76 16.72 Q 13.673 16.72 14.986 16.17 A 4.671 4.671 0 0 0 16.2 15.44 Q 17.72 14.16 17.72 11.84 Q 17.72 9.48 16.2 8.22 A 4.891 4.891 0 0 0 14.602 7.359 Q 13.89 7.122 13.021 7.026 A 11.476 11.476 0 0 0 11.76 6.96 Z" id="0" vectorEffect="non-scaling-stroke"/>
		<path d="M 49.92 20.68 L 33.64 20.68 Q 34.08 22.68 35.72 23.84 Q 37.36 25 39.8 25 Q 41.48 25 42.78 24.5 Q 44.08 24 45.2 22.92 L 48.52 26.52 A 9.777 9.777 0 0 1 43.4 29.57 Q 41.8 29.973 39.895 29.998 A 19.291 19.291 0 0 1 39.64 30 Q 36 30 33.2 28.58 Q 30.4 27.16 28.88 24.64 A 10.65 10.65 0 0 1 27.384 19.715 A 12.96 12.96 0 0 1 27.36 18.92 A 11.442 11.442 0 0 1 28.006 15.04 A 10.568 10.568 0 0 1 28.86 13.22 Q 30.36 10.68 32.98 9.26 A 11.851 11.851 0 0 1 37.941 7.868 A 14.22 14.22 0 0 1 38.84 7.84 A 12.664 12.664 0 0 1 42.556 8.371 A 11.282 11.282 0 0 1 44.56 9.2 Q 47.12 10.56 48.58 13.1 Q 50.04 15.64 50.04 19 Q 50.04 19.113 49.933 20.508 A 495.184 495.184 0 0 1 49.92 20.68 Z M 33.56 17.04 L 44.16 17.04 A 5.728 5.728 0 0 0 43.601 15.282 A 4.912 4.912 0 0 0 42.4 13.78 A 5.122 5.122 0 0 0 39.452 12.584 A 6.662 6.662 0 0 0 38.88 12.56 A 6.191 6.191 0 0 0 37.127 12.798 A 4.991 4.991 0 0 0 35.32 13.76 A 4.916 4.916 0 0 0 33.711 16.321 A 6.551 6.551 0 0 0 33.56 17.04 Z" id="1" vectorEffect="non-scaling-stroke"/>
		<path d="M 52.12 27.68 L 54.2 23.2 A 11.188 11.188 0 0 0 55.845 24.058 Q 56.665 24.408 57.618 24.682 A 18.201 18.201 0 0 0 57.68 24.7 A 14.98 14.98 0 0 0 60.788 25.25 A 13.458 13.458 0 0 0 61.68 25.28 A 12.012 12.012 0 0 0 63.001 25.214 Q 64.322 25.067 64.982 24.593 A 1.489 1.489 0 0 0 65.64 23.32 Q 65.64 22.4 64.56 22 Q 63.662 21.667 61.962 21.418 A 34.027 34.027 0 0 0 61.24 21.32 A 46.16 46.16 0 0 1 59.461 21.017 Q 57.982 20.733 56.88 20.4 Q 55.16 19.88 53.9 18.56 Q 52.783 17.39 52.656 15.338 A 8.744 8.744 0 0 1 52.64 14.8 Q 52.64 12.76 53.82 11.18 A 6.69 6.69 0 0 1 55.6 9.552 A 9.402 9.402 0 0 1 57.26 8.72 Q 59.379 7.895 62.218 7.843 A 21.028 21.028 0 0 1 62.6 7.84 Q 64.88 7.84 67.14 8.34 A 16.589 16.589 0 0 1 68.84 8.806 Q 69.998 9.196 70.88 9.72 L 68.8 14.16 Q 65.96 12.56 62.6 12.56 Q 60.6 12.56 59.6 13.12 A 2.651 2.651 0 0 0 59.122 13.455 Q 58.6 13.924 58.6 14.56 Q 58.6 15.341 59.258 15.756 A 2.262 2.262 0 0 0 59.68 15.96 Q 60.504 16.265 62.072 16.547 A 40.339 40.339 0 0 0 63.12 16.72 Q 64.894 17.016 66.234 17.338 A 23.652 23.652 0 0 1 67.44 17.66 Q 69.12 18.16 70.36 19.48 Q 71.501 20.695 71.592 22.79 A 8.524 8.524 0 0 1 71.6 23.16 Q 71.6 25.16 70.4 26.72 A 6.628 6.628 0 0 1 68.702 28.254 Q 67.906 28.764 66.9 29.14 A 12.83 12.83 0 0 1 64.47 29.771 Q 63.069 30 61.44 30 Q 58.76 30 56.2 29.34 A 19.591 19.591 0 0 1 54.494 28.822 Q 53.094 28.321 52.12 27.68 Z" id="2" vectorEffect="non-scaling-stroke"/>
		<path d="M 96.4 20.68 L 80.12 20.68 Q 80.56 22.68 82.2 23.84 Q 83.84 25 86.28 25 Q 87.96 25 89.26 24.5 Q 90.56 24 91.68 22.92 L 95 26.52 A 9.777 9.777 0 0 1 89.88 29.57 Q 88.28 29.973 86.375 29.998 A 19.291 19.291 0 0 1 86.12 30 Q 82.48 30 79.68 28.58 Q 76.88 27.16 75.36 24.64 A 10.65 10.65 0 0 1 73.864 19.715 A 12.96 12.96 0 0 1 73.84 18.92 A 11.442 11.442 0 0 1 74.486 15.04 A 10.568 10.568 0 0 1 75.34 13.22 Q 76.84 10.68 79.46 9.26 A 11.851 11.851 0 0 1 84.421 7.868 A 14.22 14.22 0 0 1 85.32 7.84 A 12.664 12.664 0 0 1 89.036 8.371 A 11.282 11.282 0 0 1 91.04 9.2 Q 93.6 10.56 95.06 13.1 Q 96.52 15.64 96.52 19 Q 96.52 19.113 96.413 20.508 A 495.184 495.184 0 0 1 96.4 20.68 Z M 80.04 17.04 L 90.64 17.04 A 5.728 5.728 0 0 0 90.081 15.282 A 4.912 4.912 0 0 0 88.88 13.78 A 5.122 5.122 0 0 0 85.932 12.584 A 6.662 6.662 0 0 0 85.36 12.56 A 6.191 6.191 0 0 0 83.607 12.798 A 4.991 4.991 0 0 0 81.8 13.76 A 4.916 4.916 0 0 0 80.191 16.321 A 6.551 6.551 0 0 0 80.04 17.04 Z" id="3" vectorEffect="non-scaling-stroke"/>
		<path d="M 119.12 17.4 L 119.12 29.68 L 113.28 29.68 L 113.28 27 A 5.65 5.65 0 0 1 110.034 29.533 Q 108.614 30 106.72 30 A 12.358 12.358 0 0 1 104.676 29.839 Q 103.482 29.639 102.484 29.189 A 7.756 7.756 0 0 1 102.42 29.16 Q 100.6 28.32 99.64 26.84 A 5.988 5.988 0 0 1 98.684 23.713 A 7.221 7.221 0 0 1 98.68 23.48 A 6.38 6.38 0 0 1 99.046 21.273 A 5.496 5.496 0 0 1 100.94 18.76 A 7.077 7.077 0 0 1 102.969 17.716 Q 104.834 17.083 107.548 17.043 A 25.11 25.11 0 0 1 107.92 17.04 L 112.88 17.04 A 5.361 5.361 0 0 0 112.735 15.755 A 3.548 3.548 0 0 0 111.64 13.9 A 3.886 3.886 0 0 0 110.318 13.148 Q 109.738 12.947 109.027 12.862 A 9.352 9.352 0 0 0 107.92 12.8 Q 106.2 12.8 104.54 13.34 A 10.144 10.144 0 0 0 102.961 13.998 A 7.895 7.895 0 0 0 101.72 14.8 L 99.48 10.44 A 12.185 12.185 0 0 1 101.817 9.165 A 15.876 15.876 0 0 1 103.7 8.52 Q 106.16 7.84 108.76 7.84 Q 112.273 7.84 114.641 9.015 A 8.146 8.146 0 0 1 116.44 10.22 Q 119.12 12.6 119.12 17.4 Z M 112.88 22.88 L 112.88 20.68 L 108.6 20.68 A 8.762 8.762 0 0 0 107.295 20.769 Q 105.615 21.023 105.049 22.008 A 2.35 2.35 0 0 0 104.76 23.2 Q 104.76 24.378 105.666 25.075 A 2.825 2.825 0 0 0 105.7 25.1 Q 106.476 25.678 107.73 25.779 A 6.874 6.874 0 0 0 108.28 25.8 A 5.891 5.891 0 0 0 109.944 25.572 A 5.065 5.065 0 0 0 111.12 25.06 Q 112.36 24.32 112.88 22.88 Z" id="4" vectorEffect="non-scaling-stroke"/>
		<path d="M 138.12 7.84 L 138.12 13.6 A 62.949 62.949 0 0 0 137.698 13.569 Q 137.001 13.52 136.72 13.52 A 7.732 7.732 0 0 0 135.015 13.697 Q 133.775 13.977 132.878 14.701 A 4.779 4.779 0 0 0 132.52 15.02 Q 131.245 16.278 131.04 18.59 A 10.494 10.494 0 0 0 131 19.52 L 131 29.68 L 124.76 29.68 L 124.76 8.16 L 130.72 8.16 L 130.72 11 A 6.85 6.85 0 0 1 133.391 8.797 A 8.238 8.238 0 0 1 133.74 8.64 A 9.974 9.974 0 0 1 136.131 7.982 A 13.324 13.324 0 0 1 138.12 7.84 Z" id="5" vectorEffect="non-scaling-stroke"/>
		<path d="M 161.68 13.32 L 156.84 15.92 A 6.611 6.611 0 0 0 155.578 14.296 A 5.094 5.094 0 0 0 152 12.96 A 6.101 6.101 0 0 0 149.961 13.289 A 5.285 5.285 0 0 0 147.96 14.56 Q 146.36 16.16 146.36 18.92 Q 146.36 21.68 147.96 23.28 Q 149.56 24.88 152 24.88 A 5.421 5.421 0 0 0 154.422 24.353 Q 155.57 23.789 156.4 22.621 A 7.406 7.406 0 0 0 156.84 21.92 L 161.68 24.56 Q 160.52 27.12 157.98 28.56 A 10.966 10.966 0 0 1 154.179 29.845 A 14.265 14.265 0 0 1 152.04 30 A 14.337 14.337 0 0 1 148.391 29.551 A 12.217 12.217 0 0 1 145.86 28.58 Q 143.12 27.16 141.58 24.64 A 10.552 10.552 0 0 1 140.064 19.709 A 12.796 12.796 0 0 1 140.04 18.92 A 11.362 11.362 0 0 1 140.605 15.294 A 10.219 10.219 0 0 1 141.58 13.2 Q 143.12 10.68 145.86 9.26 Q 148.6 7.84 152.04 7.84 A 13.807 13.807 0 0 1 155.039 8.152 A 10.781 10.781 0 0 1 157.98 9.26 Q 160.52 10.68 161.68 13.32 Z" id="6" vectorEffect="non-scaling-stroke"/>
		<path d="M 187.12 17.36 L 187.12 29.68 L 180.88 29.68 L 180.88 18.32 A 9.319 9.319 0 0 0 180.781 16.915 Q 180.672 16.198 180.44 15.623 A 3.811 3.811 0 0 0 179.76 14.5 A 3.614 3.614 0 0 0 177.873 13.392 Q 177.296 13.25 176.612 13.241 A 7.026 7.026 0 0 0 176.52 13.24 A 6.177 6.177 0 0 0 174.885 13.445 A 4.551 4.551 0 0 0 172.76 14.7 A 4.599 4.599 0 0 0 171.732 16.448 Q 171.36 17.555 171.36 19.04 L 171.36 29.68 L 165.12 29.68 L 165.12 0 L 171.36 0 L 171.36 10.4 A 8.206 8.206 0 0 1 174.035 8.629 A 9.539 9.539 0 0 1 174.36 8.5 Q 176.12 7.84 178.2 7.84 Q 182.2 7.84 184.66 10.24 Q 186.76 12.288 187.067 16.027 A 16.266 16.266 0 0 1 187.12 17.36 Z" id="7" vectorEffect="non-scaling-stroke"/>
		<path d="M 213.96 20.68 L 197.68 20.68 Q 198.12 22.68 199.76 23.84 Q 201.4 25 203.84 25 Q 205.52 25 206.82 24.5 Q 208.12 24 209.24 22.92 L 212.56 26.52 A 9.777 9.777 0 0 1 207.44 29.57 Q 205.84 29.973 203.935 29.998 A 19.291 19.291 0 0 1 203.68 30 Q 200.04 30 197.24 28.58 Q 194.44 27.16 192.92 24.64 A 10.65 10.65 0 0 1 191.424 19.715 A 12.96 12.96 0 0 1 191.4 18.92 A 11.442 11.442 0 0 1 192.046 15.04 A 10.568 10.568 0 0 1 192.9 13.22 Q 194.4 10.68 197.02 9.26 A 11.851 11.851 0 0 1 201.981 7.868 A 14.22 14.22 0 0 1 202.88 7.84 A 12.664 12.664 0 0 1 206.596 8.371 A 11.282 11.282 0 0 1 208.6 9.2 Q 211.16 10.56 212.62 13.1 Q 214.08 15.64 214.08 19 Q 214.08 19.113 213.973 20.508 A 495.184 495.184 0 0 1 213.96 20.68 Z M 197.6 17.04 L 208.2 17.04 A 5.728 5.728 0 0 0 207.641 15.282 A 4.912 4.912 0 0 0 206.44 13.78 A 5.122 5.122 0 0 0 203.492 12.584 A 6.662 6.662 0 0 0 202.92 12.56 A 6.191 6.191 0 0 0 201.167 12.798 A 4.991 4.991 0 0 0 199.36 13.76 A 4.916 4.916 0 0 0 197.751 16.321 A 6.551 6.551 0 0 0 197.6 17.04 Z" id="8" vectorEffect="non-scaling-stroke"/>
		<path d="M 231.6 7.84 L 231.6 13.6 A 62.949 62.949 0 0 0 231.178 13.569 Q 230.481 13.52 230.2 13.52 A 7.732 7.732 0 0 0 228.495 13.697 Q 227.255 13.977 226.358 14.701 A 4.779 4.779 0 0 0 226 15.02 Q 224.725 16.278 224.52 18.59 A 10.494 10.494 0 0 0 224.48 19.52 L 224.48 29.68 L 218.24 29.68 L 218.24 8.16 L 224.2 8.16 L 224.2 11 A 6.85 6.85 0 0 1 226.871 8.797 A 8.238 8.238 0 0 1 227.22 8.64 A 9.974 9.974 0 0 1 229.611 7.982 A 13.324 13.324 0 0 1 231.6 7.84 Z" id="9" vectorEffect="non-scaling-stroke"/>
	</g>
</svg>

<svg width="246.52" height="39.56" id="cc" className="position_box" viewBox="0 0 245.52 39.56" xmlns="http://www.w3.org/2000/svg">
<g id="svgGroup" strokeLinecap="round" fillRule="evenodd" fontSize="9pt" stroke="#000" strokeWidth="0.25mm" fill="none" style={{stroke:"#000",strokeWidth:"0.25mm",fill:"none"}}>
		<path d="M 26.72 7.96 L 22.56 11.8 Q 19.72 8.52 15.52 8.52 A 10.13 10.13 0 0 0 12.773 8.881 A 8.662 8.662 0 0 0 10.88 9.66 Q 8.84 10.8 7.7 12.84 A 8.998 8.998 0 0 0 6.615 16.369 A 11.087 11.087 0 0 0 6.56 17.48 A 10.13 10.13 0 0 0 6.921 20.228 A 8.662 8.662 0 0 0 7.7 22.12 Q 8.84 24.16 10.88 25.3 A 8.998 8.998 0 0 0 14.41 26.386 A 11.087 11.087 0 0 0 15.52 26.44 A 8.805 8.805 0 0 0 22.108 23.619 A 11.46 11.46 0 0 0 22.56 23.12 L 26.72 26.96 Q 24.72 29.4 21.76 30.68 A 15.619 15.619 0 0 1 17.154 31.859 A 19.197 19.197 0 0 1 15.16 31.96 A 16.97 16.97 0 0 1 10.131 31.234 A 15.134 15.134 0 0 1 7.42 30.1 Q 3.96 28.24 1.98 24.94 A 13.993 13.993 0 0 1 0.024 18.38 A 16.914 16.914 0 0 1 0 17.48 Q 0 13.32 1.98 10.02 Q 3.96 6.72 7.42 4.86 Q 10.88 3 15.2 3 A 17.854 17.854 0 0 1 19.074 3.405 A 14.779 14.779 0 0 1 21.78 4.28 Q 24.72 5.56 26.72 7.96 Z" id="0" vectorEffect="non-scaling-stroke"/>
		<path d="M 36.517 31.351 A 13.985 13.985 0 0 0 40.12 31.8 A 15.164 15.164 0 0 0 41.675 31.722 A 12.248 12.248 0 0 0 46.2 30.38 Q 48.88 28.96 50.4 26.44 A 10.283 10.283 0 0 0 51.385 24.28 A 11.525 11.525 0 0 0 51.92 20.72 A 12.96 12.96 0 0 0 51.896 19.925 A 10.65 10.65 0 0 0 50.4 15 Q 48.88 12.48 46.2 11.06 A 11.761 11.761 0 0 0 43.671 10.077 A 13.938 13.938 0 0 0 40.12 9.64 A 15.263 15.263 0 0 0 38.666 9.708 A 12.418 12.418 0 0 0 34.02 11.06 Q 31.32 12.48 29.8 15 A 10.283 10.283 0 0 0 28.816 17.16 A 11.525 11.525 0 0 0 28.28 20.72 A 12.96 12.96 0 0 0 28.305 21.516 A 10.65 10.65 0 0 0 29.8 26.44 Q 31.32 28.96 34.02 30.38 A 11.921 11.921 0 0 0 36.517 31.351 Z M 40.12 26.68 Q 42.52 26.68 44.06 25.06 Q 45.6 23.44 45.6 20.72 A 7.787 7.787 0 0 0 45.393 18.871 A 5.401 5.401 0 0 0 44.06 16.38 A 5.137 5.137 0 0 0 40.417 14.766 A 6.88 6.88 0 0 0 40.12 14.76 A 5.821 5.821 0 0 0 38.118 15.093 A 5.123 5.123 0 0 0 36.16 16.38 A 5.443 5.443 0 0 0 34.753 19.125 A 7.917 7.917 0 0 0 34.6 20.72 A 7.676 7.676 0 0 0 34.815 22.587 A 5.376 5.376 0 0 0 36.16 25.06 A 5.218 5.218 0 0 0 39.897 26.677 A 6.94 6.94 0 0 0 40.12 26.68 Z" id="1" vectorEffect="non-scaling-stroke"/>
		<path d="M 92.44 19.16 L 92.44 31.48 L 86.2 31.48 L 86.2 20.12 A 9.957 9.957 0 0 0 86.117 18.783 Q 85.902 17.206 85.14 16.3 Q 84.08 15.04 82.12 15.04 A 5.495 5.495 0 0 0 80.6 15.24 A 4.114 4.114 0 0 0 78.64 16.46 Q 77.36 17.88 77.36 20.68 L 77.36 31.48 L 71.12 31.48 L 71.12 20.12 A 9.286 9.286 0 0 0 70.952 18.266 Q 70.37 15.424 67.777 15.086 A 5.702 5.702 0 0 0 67.04 15.04 A 5.303 5.303 0 0 0 65.494 15.255 A 4.115 4.115 0 0 0 63.6 16.46 Q 62.32 17.88 62.32 20.68 L 62.32 31.48 L 56.08 31.48 L 56.08 9.96 L 62.04 9.96 L 62.04 12.44 Q 63.24 11.08 64.98 10.36 A 9.561 9.561 0 0 1 67.907 9.675 A 11.414 11.414 0 0 1 68.8 9.64 A 9.957 9.957 0 0 1 71.321 9.949 A 8.526 8.526 0 0 1 72.92 10.54 Q 74.76 11.44 75.88 13.16 Q 77.2 11.48 79.22 10.56 Q 81.24 9.64 83.64 9.64 A 11.094 11.094 0 0 1 86.384 9.961 A 7.822 7.822 0 0 1 90.06 12.02 A 7.468 7.468 0 0 1 91.845 14.995 Q 92.236 16.167 92.37 17.594 A 16.742 16.742 0 0 1 92.44 19.16 Z" id="2" vectorEffect="non-scaling-stroke"/>
		<path d="M 134.4 19.16 L 134.4 31.48 L 128.16 31.48 L 128.16 20.12 A 9.957 9.957 0 0 0 128.077 18.783 Q 127.862 17.206 127.1 16.3 Q 126.04 15.04 124.08 15.04 A 5.495 5.495 0 0 0 122.56 15.24 A 4.114 4.114 0 0 0 120.6 16.46 Q 119.32 17.88 119.32 20.68 L 119.32 31.48 L 113.08 31.48 L 113.08 20.12 A 9.286 9.286 0 0 0 112.912 18.266 Q 112.33 15.424 109.737 15.086 A 5.702 5.702 0 0 0 109 15.04 A 5.303 5.303 0 0 0 107.454 15.255 A 4.115 4.115 0 0 0 105.56 16.46 Q 104.28 17.88 104.28 20.68 L 104.28 31.48 L 98.04 31.48 L 98.04 9.96 L 104 9.96 L 104 12.44 Q 105.2 11.08 106.94 10.36 A 9.561 9.561 0 0 1 109.867 9.675 A 11.414 11.414 0 0 1 110.76 9.64 A 9.957 9.957 0 0 1 113.281 9.949 A 8.526 8.526 0 0 1 114.88 10.54 Q 116.72 11.44 117.84 13.16 Q 119.16 11.48 121.18 10.56 Q 123.2 9.64 125.6 9.64 A 11.094 11.094 0 0 1 128.344 9.961 A 7.822 7.822 0 0 1 132.02 12.02 A 7.468 7.468 0 0 1 133.805 14.995 Q 134.196 16.167 134.33 17.594 A 16.742 16.742 0 0 1 134.4 19.16 Z" id="3" vectorEffect="non-scaling-stroke"/>
		<path d="M 155.44 9.96 L 161.68 9.96 L 161.68 31.48 L 155.76 31.48 L 155.76 28.92 Q 154.52 30.32 152.8 31.06 Q 151.08 31.8 149.08 31.8 A 11.927 11.927 0 0 1 146.26 31.485 A 8.202 8.202 0 0 1 142.36 29.36 Q 139.88 26.92 139.88 22.12 L 139.88 9.96 L 146.12 9.96 L 146.12 21.2 A 9.064 9.064 0 0 0 146.307 23.13 Q 146.918 25.919 149.522 26.329 A 6.159 6.159 0 0 0 150.48 26.4 Q 152.72 26.4 154.08 24.94 A 4.598 4.598 0 0 0 155.057 23.259 Q 155.44 22.13 155.44 20.6 L 155.44 9.96 Z" id="4" vectorEffect="non-scaling-stroke"/>
		<path d="M 189.48 19.16 L 189.48 31.48 L 183.24 31.48 L 183.24 20.12 A 9.319 9.319 0 0 0 183.142 18.715 Q 183.032 17.998 182.801 17.423 A 3.811 3.811 0 0 0 182.12 16.3 A 3.614 3.614 0 0 0 180.234 15.192 Q 179.656 15.05 178.972 15.041 A 7.026 7.026 0 0 0 178.88 15.04 A 6.177 6.177 0 0 0 177.245 15.245 A 4.551 4.551 0 0 0 175.12 16.5 A 4.599 4.599 0 0 0 174.093 18.248 Q 173.72 19.355 173.72 20.84 L 173.72 31.48 L 167.48 31.48 L 167.48 9.96 L 173.44 9.96 L 173.44 12.48 A 8.157 8.157 0 0 1 176.125 10.549 A 9.59 9.59 0 0 1 176.52 10.38 A 10.331 10.331 0 0 1 179.549 9.681 A 12.377 12.377 0 0 1 180.56 9.64 Q 184.56 9.64 187.02 12.04 Q 189.12 14.089 189.428 17.827 A 16.266 16.266 0 0 1 189.48 19.16 Z" id="5" vectorEffect="non-scaling-stroke"/>
		<path d="M 195.12 31.48 L 195.12 9.96 L 201.36 9.96 L 201.36 31.48 L 195.12 31.48 Z M 196.971 6.801 A 4.823 4.823 0 0 0 198.24 6.96 Q 199.96 6.96 201.04 5.94 A 3.355 3.355 0 0 0 201.883 4.704 A 3.69 3.69 0 0 0 202.12 3.36 A 3.217 3.217 0 0 0 201.899 2.154 A 3.164 3.164 0 0 0 201.04 0.96 A 3.663 3.663 0 0 0 199.487 0.147 A 5.032 5.032 0 0 0 198.24 0 A 5.159 5.159 0 0 0 197.396 0.067 A 3.698 3.698 0 0 0 195.44 1 A 3.401 3.401 0 0 0 194.72 1.932 A 3.315 3.315 0 0 0 194.36 3.48 A 3.966 3.966 0 0 0 194.363 3.618 A 3.23 3.23 0 0 0 195.44 5.96 A 3.613 3.613 0 0 0 196.971 6.801 Z" id="6" vectorEffect="non-scaling-stroke"/>
		<path d="M 219.36 26.04 L 221.04 30.44 A 5.667 5.667 0 0 1 219.873 31.098 A 7.608 7.608 0 0 1 218.78 31.46 A 11.274 11.274 0 0 1 216.328 31.795 A 12.662 12.662 0 0 1 215.96 31.8 Q 212.12 31.8 210.02 29.84 Q 208.1 28.048 207.936 24.718 A 12.926 12.926 0 0 1 207.92 24.08 L 207.92 15.24 L 204.6 15.24 L 204.6 10.44 L 207.92 10.44 L 207.92 5.2 L 214.16 5.2 L 214.16 10.44 L 219.52 10.44 L 219.52 15.24 L 214.16 15.24 L 214.16 24 Q 214.16 25.36 214.86 26.1 A 2.278 2.278 0 0 0 215.995 26.745 Q 216.356 26.834 216.783 26.84 A 4.363 4.363 0 0 0 216.84 26.84 A 4.554 4.554 0 0 0 218.129 26.665 A 3.722 3.722 0 0 0 219.36 26.04 Z" id="7" vectorEffect="non-scaling-stroke"/>
		<path d="M 239.52 9.96 L 245.52 9.96 L 235.8 32.8 Q 234.829 35.241 233.556 36.735 A 7.524 7.524 0 0 1 232.14 38.04 Q 229.96 39.56 226.88 39.56 Q 225.2 39.56 223.56 39.04 A 8.897 8.897 0 0 1 222.24 38.509 Q 221.502 38.136 220.936 37.648 A 5.561 5.561 0 0 1 220.88 37.6 L 223.16 33.16 Q 223.88 33.8 224.82 34.16 A 5.406 5.406 0 0 0 226.1 34.485 A 4.782 4.782 0 0 0 226.68 34.52 A 4.315 4.315 0 0 0 227.573 34.433 Q 228.153 34.31 228.6 34.015 A 2.619 2.619 0 0 0 228.76 33.9 Q 229.326 33.462 229.812 32.614 A 8.534 8.534 0 0 0 230.2 31.84 L 230.28 31.64 L 220.96 9.96 L 227.4 9.96 L 233.44 24.56 L 239.52 9.96 Z" id="8" vectorEffect="non-scaling-stroke"/>
	</g>
</svg>

<svg width="242.6" height="31.961" id="cc1" className="position_box" viewBox="0 0 241.6 31.961" xmlns="http://www.w3.org/2000/svg">
    <g id="svgGroup" strokeLinecap="round" fillRule="evenodd" fontSize="9pt" stroke="#000" strokeWidth="0.25mm" fill="none" style={{stroke:"#000",strokeWidth:"0.25mm",fill:"none"}}>
		<path d="M 26.72 7.96 L 22.56 11.8 Q 19.72 8.52 15.52 8.52 A 10.13 10.13 0 0 0 12.773 8.881 A 8.662 8.662 0 0 0 10.88 9.66 Q 8.84 10.8 7.7 12.84 A 8.998 8.998 0 0 0 6.615 16.369 A 11.087 11.087 0 0 0 6.56 17.48 A 10.13 10.13 0 0 0 6.921 20.228 A 8.662 8.662 0 0 0 7.7 22.12 Q 8.84 24.16 10.88 25.3 A 8.998 8.998 0 0 0 14.41 26.386 A 11.087 11.087 0 0 0 15.52 26.44 A 8.805 8.805 0 0 0 22.108 23.619 A 11.46 11.46 0 0 0 22.56 23.12 L 26.72 26.96 Q 24.72 29.4 21.76 30.68 A 15.619 15.619 0 0 1 17.154 31.859 A 19.197 19.197 0 0 1 15.16 31.96 A 16.97 16.97 0 0 1 10.131 31.234 A 15.134 15.134 0 0 1 7.42 30.1 Q 3.96 28.24 1.98 24.94 A 13.993 13.993 0 0 1 0.024 18.38 A 16.914 16.914 0 0 1 0 17.48 Q 0 13.32 1.98 10.02 Q 3.96 6.72 7.42 4.86 Q 10.88 3 15.2 3 A 17.854 17.854 0 0 1 19.074 3.405 A 14.779 14.779 0 0 1 21.78 4.28 Q 24.72 5.56 26.72 7.96 Z" id="0" vectorEffect="non-scaling-stroke"/>
		<path d="M 36.517 31.351 A 13.985 13.985 0 0 0 40.12 31.8 A 15.164 15.164 0 0 0 41.675 31.722 A 12.248 12.248 0 0 0 46.2 30.38 Q 48.88 28.96 50.4 26.44 A 10.283 10.283 0 0 0 51.385 24.28 A 11.525 11.525 0 0 0 51.92 20.72 A 12.96 12.96 0 0 0 51.896 19.925 A 10.65 10.65 0 0 0 50.4 15 Q 48.88 12.48 46.2 11.06 A 11.761 11.761 0 0 0 43.671 10.077 A 13.938 13.938 0 0 0 40.12 9.64 A 15.263 15.263 0 0 0 38.666 9.708 A 12.418 12.418 0 0 0 34.02 11.06 Q 31.32 12.48 29.8 15 A 10.283 10.283 0 0 0 28.816 17.16 A 11.525 11.525 0 0 0 28.28 20.72 A 12.96 12.96 0 0 0 28.305 21.516 A 10.65 10.65 0 0 0 29.8 26.44 Q 31.32 28.96 34.02 30.38 A 11.921 11.921 0 0 0 36.517 31.351 Z M 40.12 26.68 Q 42.52 26.68 44.06 25.06 Q 45.6 23.44 45.6 20.72 A 7.787 7.787 0 0 0 45.393 18.871 A 5.401 5.401 0 0 0 44.06 16.38 A 5.137 5.137 0 0 0 40.417 14.766 A 6.88 6.88 0 0 0 40.12 14.76 A 5.821 5.821 0 0 0 38.118 15.093 A 5.123 5.123 0 0 0 36.16 16.38 A 5.443 5.443 0 0 0 34.753 19.125 A 7.917 7.917 0 0 0 34.6 20.72 A 7.676 7.676 0 0 0 34.815 22.587 A 5.376 5.376 0 0 0 36.16 25.06 A 5.218 5.218 0 0 0 39.897 26.677 A 6.94 6.94 0 0 0 40.12 26.68 Z" id="1" vectorEffect="non-scaling-stroke"/>
		<path d="M 78.08 19.16 L 78.08 31.48 L 71.84 31.48 L 71.84 20.12 A 9.319 9.319 0 0 0 71.742 18.715 Q 71.632 17.998 71.401 17.423 A 3.811 3.811 0 0 0 70.72 16.3 A 3.614 3.614 0 0 0 68.834 15.192 Q 68.256 15.05 67.572 15.041 A 7.026 7.026 0 0 0 67.48 15.04 A 6.177 6.177 0 0 0 65.845 15.245 A 4.551 4.551 0 0 0 63.72 16.5 A 4.599 4.599 0 0 0 62.693 18.248 Q 62.32 19.355 62.32 20.84 L 62.32 31.48 L 56.08 31.48 L 56.08 9.96 L 62.04 9.96 L 62.04 12.48 A 8.157 8.157 0 0 1 64.725 10.549 A 9.59 9.59 0 0 1 65.12 10.38 A 10.331 10.331 0 0 1 68.149 9.681 A 12.377 12.377 0 0 1 69.16 9.64 Q 73.16 9.64 75.62 12.04 Q 77.72 14.089 78.028 17.827 A 16.266 16.266 0 0 1 78.08 19.16 Z" id="2" vectorEffect="non-scaling-stroke"/>
		<path d="M 95.92 26.04 L 97.6 30.44 A 5.667 5.667 0 0 1 96.433 31.098 A 7.608 7.608 0 0 1 95.34 31.46 A 11.274 11.274 0 0 1 92.888 31.795 A 12.662 12.662 0 0 1 92.52 31.8 Q 88.68 31.8 86.58 29.84 Q 84.66 28.048 84.496 24.718 A 12.926 12.926 0 0 1 84.48 24.08 L 84.48 15.24 L 81.16 15.24 L 81.16 10.44 L 84.48 10.44 L 84.48 5.2 L 90.72 5.2 L 90.72 10.44 L 96.08 10.44 L 96.08 15.24 L 90.72 15.24 L 90.72 24 Q 90.72 25.36 91.42 26.1 A 2.278 2.278 0 0 0 92.555 26.745 Q 92.916 26.834 93.343 26.84 A 4.363 4.363 0 0 0 93.4 26.84 A 4.554 4.554 0 0 0 94.689 26.665 A 3.722 3.722 0 0 0 95.92 26.04 Z" id="3" vectorEffect="non-scaling-stroke"/>
		<path d="M 114.48 9.64 L 114.48 15.4 A 62.949 62.949 0 0 0 114.058 15.369 Q 113.362 15.32 113.08 15.32 A 7.732 7.732 0 0 0 111.375 15.497 Q 110.135 15.778 109.238 16.501 A 4.779 4.779 0 0 0 108.88 16.82 Q 107.606 18.078 107.4 20.39 A 10.494 10.494 0 0 0 107.36 21.32 L 107.36 31.48 L 101.12 31.48 L 101.12 9.96 L 107.08 9.96 L 107.08 12.8 A 6.85 6.85 0 0 1 109.751 10.597 A 8.238 8.238 0 0 1 110.1 10.44 A 9.974 9.974 0 0 1 112.491 9.783 A 13.324 13.324 0 0 1 114.48 9.64 Z" id="4" vectorEffect="non-scaling-stroke"/>
		<path d="M 118.2 31.48 L 118.2 9.96 L 124.44 9.96 L 124.44 31.48 L 118.2 31.48 Z M 120.051 6.801 A 4.823 4.823 0 0 0 121.32 6.96 Q 123.04 6.96 124.12 5.94 A 3.355 3.355 0 0 0 124.963 4.704 A 3.69 3.69 0 0 0 125.2 3.36 A 3.217 3.217 0 0 0 124.979 2.154 A 3.164 3.164 0 0 0 124.12 0.96 A 3.663 3.663 0 0 0 122.567 0.147 A 5.032 5.032 0 0 0 121.32 0 A 5.159 5.159 0 0 0 120.476 0.067 A 3.698 3.698 0 0 0 118.52 1 A 3.401 3.401 0 0 0 117.8 1.932 A 3.315 3.315 0 0 0 117.44 3.48 A 3.966 3.966 0 0 0 117.443 3.618 A 3.23 3.23 0 0 0 118.52 5.96 A 3.613 3.613 0 0 0 120.051 6.801 Z" id="5" vectorEffect="non-scaling-stroke"/>
		<path d="M 136.2 29 L 136.2 31.48 L 130.24 31.48 L 130.24 1.8 L 136.48 1.8 L 136.48 12.24 A 7.761 7.761 0 0 1 141.068 9.793 A 11.256 11.256 0 0 1 142.96 9.64 Q 145.96 9.64 148.42 11.02 Q 150.88 12.4 152.28 14.9 A 10.909 10.909 0 0 1 153.544 18.725 A 14.105 14.105 0 0 1 153.68 20.72 A 13.322 13.322 0 0 1 153.341 23.79 A 10.574 10.574 0 0 1 152.28 26.54 Q 150.88 29.04 148.42 30.42 Q 145.96 31.8 142.96 31.8 A 11.204 11.204 0 0 1 140.217 31.484 A 7.422 7.422 0 0 1 136.2 29 Z M 141.88 26.68 Q 144.28 26.68 145.82 25.06 Q 147.36 23.44 147.36 20.72 A 7.787 7.787 0 0 0 147.153 18.871 A 5.401 5.401 0 0 0 145.82 16.38 A 5.137 5.137 0 0 0 142.177 14.766 A 6.88 6.88 0 0 0 141.88 14.76 Q 139.48 14.76 137.94 16.38 Q 136.4 18 136.4 20.72 A 7.787 7.787 0 0 0 136.608 22.569 A 5.401 5.401 0 0 0 137.94 25.06 A 5.137 5.137 0 0 0 141.584 26.674 A 6.88 6.88 0 0 0 141.88 26.68 Z" id="6" vectorEffect="non-scaling-stroke"/>
		<path d="M 173.28 9.96 L 179.52 9.96 L 179.52 31.48 L 173.6 31.48 L 173.6 28.92 Q 172.36 30.32 170.64 31.06 Q 168.92 31.8 166.92 31.8 A 11.927 11.927 0 0 1 164.1 31.485 A 8.202 8.202 0 0 1 160.2 29.36 Q 157.72 26.92 157.72 22.12 L 157.72 9.96 L 163.96 9.96 L 163.96 21.2 A 9.064 9.064 0 0 0 164.147 23.13 Q 164.758 25.919 167.362 26.329 A 6.159 6.159 0 0 0 168.32 26.4 Q 170.56 26.4 171.92 24.94 A 4.598 4.598 0 0 0 172.897 23.259 Q 173.28 22.13 173.28 20.6 L 173.28 9.96 Z" id="7" vectorEffect="non-scaling-stroke"/>
		<path d="M 197.52 26.04 L 199.2 30.44 A 5.667 5.667 0 0 1 198.033 31.098 A 7.608 7.608 0 0 1 196.94 31.46 A 11.274 11.274 0 0 1 194.488 31.795 A 12.662 12.662 0 0 1 194.12 31.8 Q 190.28 31.8 188.18 29.84 Q 186.26 28.048 186.096 24.718 A 12.926 12.926 0 0 1 186.08 24.08 L 186.08 15.24 L 182.76 15.24 L 182.76 10.44 L 186.08 10.44 L 186.08 5.2 L 192.32 5.2 L 192.32 10.44 L 197.68 10.44 L 197.68 15.24 L 192.32 15.24 L 192.32 24 Q 192.32 25.36 193.02 26.1 A 2.278 2.278 0 0 0 194.155 26.745 Q 194.516 26.834 194.943 26.84 A 4.363 4.363 0 0 0 195 26.84 A 4.554 4.554 0 0 0 196.289 26.665 A 3.722 3.722 0 0 0 197.52 26.04 Z" id="8" vectorEffect="non-scaling-stroke"/>
		<path d="M 208.677 31.351 A 13.985 13.985 0 0 0 212.28 31.8 A 15.164 15.164 0 0 0 213.835 31.722 A 12.248 12.248 0 0 0 218.36 30.38 Q 221.04 28.96 222.56 26.44 A 10.283 10.283 0 0 0 223.545 24.28 A 11.525 11.525 0 0 0 224.08 20.72 A 12.96 12.96 0 0 0 224.056 19.925 A 10.65 10.65 0 0 0 222.56 15 Q 221.04 12.48 218.36 11.06 A 11.761 11.761 0 0 0 215.831 10.077 A 13.938 13.938 0 0 0 212.28 9.64 A 15.263 15.263 0 0 0 210.826 9.708 A 12.418 12.418 0 0 0 206.18 11.06 Q 203.48 12.48 201.96 15 A 10.283 10.283 0 0 0 200.976 17.16 A 11.525 11.525 0 0 0 200.44 20.72 A 12.96 12.96 0 0 0 200.465 21.516 A 10.65 10.65 0 0 0 201.96 26.44 Q 203.48 28.96 206.18 30.38 A 11.921 11.921 0 0 0 208.677 31.351 Z M 212.28 26.68 Q 214.68 26.68 216.22 25.06 Q 217.76 23.44 217.76 20.72 A 7.787 7.787 0 0 0 217.553 18.871 A 5.401 5.401 0 0 0 216.22 16.38 A 5.137 5.137 0 0 0 212.577 14.766 A 6.88 6.88 0 0 0 212.28 14.76 A 5.821 5.821 0 0 0 210.278 15.093 A 5.123 5.123 0 0 0 208.32 16.38 A 5.443 5.443 0 0 0 206.913 19.125 A 7.917 7.917 0 0 0 206.76 20.72 A 7.676 7.676 0 0 0 206.975 22.587 A 5.376 5.376 0 0 0 208.32 25.06 A 5.218 5.218 0 0 0 212.057 26.677 A 6.94 6.94 0 0 0 212.28 26.68 Z" id="9" vectorEffect="non-scaling-stroke"/>
		<path d="M 241.6 9.64 L 241.6 15.4 A 62.949 62.949 0 0 0 241.178 15.369 Q 240.482 15.32 240.2 15.32 A 7.732 7.732 0 0 0 238.495 15.497 Q 237.255 15.778 236.358 16.501 A 4.779 4.779 0 0 0 236 16.82 Q 234.726 18.078 234.52 20.39 A 10.494 10.494 0 0 0 234.48 21.32 L 234.48 31.48 L 228.24 31.48 L 228.24 9.96 L 234.2 9.96 L 234.2 12.8 A 6.85 6.85 0 0 1 236.871 10.597 A 8.238 8.238 0 0 1 237.22 10.44 A 9.974 9.974 0 0 1 239.611 9.783 A 13.324 13.324 0 0 1 241.6 9.64 Z" id="10" vectorEffect="non-scaling-stroke"/>
	</g>
</svg>

<svg width="309.48" height="39.24" id="ps" className="position_box" viewBox="0 0 309.48 39.24" xmlns="http://www.w3.org/2000/svg">
<g id="svgGroup" strokeLinecap="round" fillRule="evenodd" fontSize="9pt" stroke="#000" strokeWidth="0.25mm" fill="none" style={{stroke:"#000",strokeWidth:"0.25mm",fill:"none"}}>
		<path d="M 12.12 23.76 L 6.48 23.76 L 6.48 31.48 L 0 31.48 L 0 3.48 L 12.12 3.48 Q 15.84 3.48 18.58 4.72 A 10.449 10.449 0 0 1 21.168 6.359 A 8.979 8.979 0 0 1 22.8 8.24 A 9.131 9.131 0 0 1 24.169 11.997 A 11.846 11.846 0 0 1 24.28 13.64 Q 24.28 16.72 22.8 19.02 A 9.186 9.186 0 0 1 19.405 22.133 A 11.377 11.377 0 0 1 18.58 22.54 A 13.572 13.572 0 0 1 15.392 23.492 Q 13.864 23.76 12.12 23.76 Z M 11.76 8.76 L 6.48 8.76 L 6.48 18.48 L 11.76 18.48 A 10.832 10.832 0 0 0 13.429 18.36 Q 15.149 18.091 16.2 17.22 A 4.097 4.097 0 0 0 17.637 14.669 A 6.071 6.071 0 0 0 17.72 13.64 Q 17.72 11.28 16.2 10.02 A 4.891 4.891 0 0 0 14.602 9.159 Q 13.89 8.922 13.021 8.826 A 11.476 11.476 0 0 0 11.76 8.76 Z" id="0" vectorEffect="non-scaling-stroke"/>
		<path d="M 44.28 9.96 L 50.52 9.96 L 50.52 31.48 L 44.6 31.48 L 44.6 28.92 Q 43.36 30.32 41.64 31.06 Q 39.92 31.8 37.92 31.8 A 11.927 11.927 0 0 1 35.1 31.485 A 8.202 8.202 0 0 1 31.2 29.36 Q 28.72 26.92 28.72 22.12 L 28.72 9.96 L 34.96 9.96 L 34.96 21.2 A 9.064 9.064 0 0 0 35.147 23.13 Q 35.757 25.919 38.362 26.329 A 6.159 6.159 0 0 0 39.32 26.4 Q 41.56 26.4 42.92 24.94 A 4.598 4.598 0 0 0 43.896 23.259 Q 44.28 22.13 44.28 20.6 L 44.28 9.96 Z" id="1" vectorEffect="non-scaling-stroke"/>
		<path d="M 62.28 29 L 62.28 31.48 L 56.32 31.48 L 56.32 1.8 L 62.56 1.8 L 62.56 12.24 A 7.761 7.761 0 0 1 67.148 9.793 A 11.256 11.256 0 0 1 69.04 9.64 Q 72.04 9.64 74.5 11.02 Q 76.96 12.4 78.36 14.9 A 10.909 10.909 0 0 1 79.623 18.725 A 14.105 14.105 0 0 1 79.76 20.72 A 13.322 13.322 0 0 1 79.421 23.79 A 10.574 10.574 0 0 1 78.36 26.54 Q 76.96 29.04 74.5 30.42 Q 72.04 31.8 69.04 31.8 A 11.204 11.204 0 0 1 66.296 31.484 A 7.422 7.422 0 0 1 62.28 29 Z M 67.96 26.68 Q 70.36 26.68 71.9 25.06 Q 73.44 23.44 73.44 20.72 A 7.787 7.787 0 0 0 73.232 18.871 A 5.401 5.401 0 0 0 71.9 16.38 A 5.137 5.137 0 0 0 68.257 14.766 A 6.88 6.88 0 0 0 67.96 14.76 Q 65.56 14.76 64.02 16.38 Q 62.48 18 62.48 20.72 A 7.787 7.787 0 0 0 62.688 22.569 A 5.401 5.401 0 0 0 64.02 25.06 A 5.137 5.137 0 0 0 67.663 26.674 A 6.88 6.88 0 0 0 67.96 26.68 Z" id="2" vectorEffect="non-scaling-stroke"/>
		<path d="M 83.92 31.48 L 83.92 1.8 L 90.16 1.8 L 90.16 31.48 L 83.92 31.48 Z" id="3" vectorEffect="non-scaling-stroke"/>
		<path d="M 95.96 31.48 L 95.96 9.96 L 102.2 9.96 L 102.2 31.48 L 95.96 31.48 Z M 97.811 6.801 A 4.823 4.823 0 0 0 99.08 6.96 Q 100.8 6.96 101.88 5.94 A 3.355 3.355 0 0 0 102.723 4.704 A 3.69 3.69 0 0 0 102.96 3.36 A 3.217 3.217 0 0 0 102.738 2.154 A 3.164 3.164 0 0 0 101.88 0.96 A 3.663 3.663 0 0 0 100.326 0.147 A 5.032 5.032 0 0 0 99.08 0 A 5.159 5.159 0 0 0 98.236 0.067 A 3.698 3.698 0 0 0 96.28 1 A 3.401 3.401 0 0 0 95.56 1.932 A 3.315 3.315 0 0 0 95.2 3.48 A 3.966 3.966 0 0 0 95.202 3.618 A 3.23 3.23 0 0 0 96.28 5.96 A 3.613 3.613 0 0 0 97.811 6.801 Z" id="4" vectorEffect="non-scaling-stroke"/>
		<path d="M 128.04 15.12 L 123.2 17.72 A 6.611 6.611 0 0 0 121.938 16.096 A 5.094 5.094 0 0 0 118.36 14.76 A 6.101 6.101 0 0 0 116.321 15.089 A 5.285 5.285 0 0 0 114.32 16.36 Q 112.72 17.96 112.72 20.72 Q 112.72 23.48 114.32 25.08 Q 115.92 26.68 118.36 26.68 A 5.421 5.421 0 0 0 120.782 26.153 Q 121.93 25.589 122.76 24.421 A 7.406 7.406 0 0 0 123.2 23.72 L 128.04 26.36 Q 126.88 28.92 124.34 30.36 A 10.966 10.966 0 0 1 120.539 31.645 A 14.265 14.265 0 0 1 118.4 31.8 A 14.337 14.337 0 0 1 114.751 31.351 A 12.217 12.217 0 0 1 112.22 30.38 Q 109.48 28.96 107.94 26.44 A 10.552 10.552 0 0 1 106.424 21.51 A 12.796 12.796 0 0 1 106.4 20.72 A 11.362 11.362 0 0 1 106.965 17.094 A 10.219 10.219 0 0 1 107.94 15 Q 109.48 12.48 112.22 11.06 Q 114.96 9.64 118.4 9.64 A 13.807 13.807 0 0 1 121.399 9.952 A 10.781 10.781 0 0 1 124.34 11.06 Q 126.88 12.48 128.04 15.12 Z" id="5" vectorEffect="non-scaling-stroke"/>
		<path d="M 141.2 28.72 L 143.4 23.84 A 13.669 13.669 0 0 0 145.898 25.279 A 17.401 17.401 0 0 0 147.68 25.96 Q 150.16 26.76 152.64 26.76 Q 155.106 26.76 156.422 26.106 A 3.607 3.607 0 0 0 156.72 25.94 A 3.274 3.274 0 0 0 157.437 25.352 A 2.306 2.306 0 0 0 158.04 23.76 A 2.065 2.065 0 0 0 157.383 22.211 A 2.617 2.617 0 0 0 157.26 22.1 A 5.012 5.012 0 0 0 156.339 21.489 Q 155.889 21.254 155.352 21.071 A 8.455 8.455 0 0 0 155.26 21.04 A 22.987 22.987 0 0 0 154.414 20.782 Q 153.39 20.49 151.96 20.16 Q 148.76 19.4 146.72 18.64 Q 144.68 17.88 143.22 16.2 A 5.742 5.742 0 0 1 141.983 13.737 Q 141.774 12.872 141.761 11.856 A 10.587 10.587 0 0 1 141.76 11.72 Q 141.76 9.28 143.08 7.3 A 7.897 7.897 0 0 1 145.186 5.192 A 11.002 11.002 0 0 1 147.06 4.16 A 12.993 12.993 0 0 1 149.813 3.328 Q 151.093 3.082 152.561 3.021 A 23.914 23.914 0 0 1 153.56 3 Q 156.24 3 158.8 3.64 A 17.247 17.247 0 0 1 161.409 4.508 A 13.693 13.693 0 0 1 163.28 5.48 L 161.28 10.4 Q 157.4 8.2 153.52 8.2 Q 151.055 8.2 149.756 8.923 A 3.446 3.446 0 0 0 149.5 9.08 A 3.269 3.269 0 0 0 148.737 9.781 A 2.572 2.572 0 0 0 148.2 11.4 Q 148.2 12.84 149.7 13.54 A 11.263 11.263 0 0 0 150.669 13.932 Q 151.739 14.314 153.287 14.69 A 48.7 48.7 0 0 0 154.28 14.92 Q 157.48 15.68 159.52 16.44 Q 161.56 17.2 163.02 18.84 Q 164.48 20.48 164.48 23.28 Q 164.48 25.68 163.14 27.66 A 7.991 7.991 0 0 1 161.026 29.757 A 11.148 11.148 0 0 1 159.12 30.8 Q 156.749 31.826 153.47 31.945 A 24.13 24.13 0 0 1 152.6 31.96 Q 149.28 31.96 146.18 31.06 A 20.284 20.284 0 0 1 144.027 30.309 Q 142.943 29.857 142.064 29.314 A 10.487 10.487 0 0 1 141.2 28.72 Z" id="7" vectorEffect="non-scaling-stroke"/>
		<path d="M 174.72 29.2 L 174.72 39.24 L 168.48 39.24 L 168.48 9.96 L 174.44 9.96 L 174.44 12.44 A 7.488 7.488 0 0 1 178.751 9.887 A 11.488 11.488 0 0 1 181.2 9.64 Q 184.2 9.64 186.66 11.02 Q 189.12 12.4 190.52 14.9 A 10.909 10.909 0 0 1 191.783 18.725 A 14.105 14.105 0 0 1 191.92 20.72 A 13.322 13.322 0 0 1 191.581 23.79 A 10.574 10.574 0 0 1 190.52 26.54 Q 189.12 29.04 186.66 30.42 Q 184.2 31.8 181.2 31.8 A 10.498 10.498 0 0 1 178.412 31.45 A 7.597 7.597 0 0 1 174.72 29.2 Z M 180.12 26.68 Q 182.52 26.68 184.06 25.06 Q 185.6 23.44 185.6 20.72 A 7.787 7.787 0 0 0 185.392 18.871 A 5.401 5.401 0 0 0 184.06 16.38 A 5.137 5.137 0 0 0 180.417 14.766 A 6.88 6.88 0 0 0 180.12 14.76 Q 177.72 14.76 176.18 16.38 Q 174.64 18 174.64 20.72 A 7.787 7.787 0 0 0 174.848 22.569 A 5.401 5.401 0 0 0 176.18 25.06 A 5.137 5.137 0 0 0 179.823 26.674 A 6.88 6.88 0 0 0 180.12 26.68 Z" id="8" vectorEffect="non-scaling-stroke"/>
		<path d="M 217.04 22.48 L 200.76 22.48 Q 201.2 24.48 202.84 25.64 Q 204.48 26.8 206.92 26.8 Q 208.6 26.8 209.9 26.3 Q 211.2 25.8 212.32 24.72 L 215.64 28.32 A 9.777 9.777 0 0 1 210.52 31.37 Q 208.92 31.773 207.015 31.798 A 19.291 19.291 0 0 1 206.76 31.8 Q 203.12 31.8 200.32 30.38 Q 197.52 28.96 196 26.44 A 10.65 10.65 0 0 1 194.504 21.516 A 12.96 12.96 0 0 1 194.48 20.72 A 11.442 11.442 0 0 1 195.126 16.84 A 10.568 10.568 0 0 1 195.98 15.02 Q 197.48 12.48 200.1 11.06 A 11.851 11.851 0 0 1 205.061 9.668 A 14.22 14.22 0 0 1 205.96 9.64 A 12.664 12.664 0 0 1 209.676 10.171 A 11.282 11.282 0 0 1 211.68 11 Q 214.24 12.36 215.7 14.9 Q 217.16 17.44 217.16 20.8 Q 217.16 20.913 217.053 22.308 A 495.184 495.184 0 0 1 217.04 22.48 Z M 200.68 18.84 L 211.28 18.84 A 5.728 5.728 0 0 0 210.721 17.082 A 4.912 4.912 0 0 0 209.52 15.58 A 5.122 5.122 0 0 0 206.572 14.384 A 6.662 6.662 0 0 0 206 14.36 A 6.191 6.191 0 0 0 204.247 14.598 A 4.991 4.991 0 0 0 202.44 15.56 A 4.916 4.916 0 0 0 200.831 18.121 A 6.551 6.551 0 0 0 200.68 18.84 Z" id="9" vectorEffect="non-scaling-stroke"/>
		<path d="M 239.76 19.2 L 239.76 31.48 L 233.92 31.48 L 233.92 28.8 A 5.65 5.65 0 0 1 230.674 31.333 Q 229.254 31.8 227.36 31.8 A 12.358 12.358 0 0 1 225.316 31.639 Q 224.122 31.439 223.124 30.989 A 7.756 7.756 0 0 1 223.06 30.96 Q 221.24 30.12 220.28 28.64 A 5.988 5.988 0 0 1 219.324 25.514 A 7.221 7.221 0 0 1 219.32 25.28 A 6.38 6.38 0 0 1 219.686 23.073 A 5.496 5.496 0 0 1 221.58 20.56 A 7.077 7.077 0 0 1 223.609 19.516 Q 225.474 18.883 228.188 18.843 A 25.11 25.11 0 0 1 228.56 18.84 L 233.52 18.84 A 5.361 5.361 0 0 0 233.375 17.555 A 3.548 3.548 0 0 0 232.28 15.7 A 3.886 3.886 0 0 0 230.958 14.948 Q 230.378 14.747 229.667 14.662 A 9.352 9.352 0 0 0 228.56 14.6 Q 226.84 14.6 225.18 15.14 A 10.144 10.144 0 0 0 223.601 15.798 A 7.895 7.895 0 0 0 222.36 16.6 L 220.12 12.24 A 12.185 12.185 0 0 1 222.457 10.965 A 15.876 15.876 0 0 1 224.34 10.32 Q 226.8 9.64 229.4 9.64 Q 232.913 9.64 235.281 10.815 A 8.146 8.146 0 0 1 237.08 12.02 Q 239.76 14.4 239.76 19.2 Z M 233.52 24.68 L 233.52 22.48 L 229.24 22.48 A 8.762 8.762 0 0 0 227.935 22.569 Q 226.255 22.823 225.689 23.808 A 2.35 2.35 0 0 0 225.4 25 Q 225.4 26.178 226.306 26.875 A 2.825 2.825 0 0 0 226.34 26.9 Q 227.116 27.478 228.37 27.579 A 6.874 6.874 0 0 0 228.92 27.6 A 5.891 5.891 0 0 0 230.584 27.372 A 5.065 5.065 0 0 0 231.76 26.86 Q 233 26.12 233.52 24.68 Z" id="10" vectorEffect="non-scaling-stroke"/>
		<path d="M 261.44 31.48 L 254.64 23.04 L 251.64 26 L 251.64 31.48 L 245.4 31.48 L 245.4 1.8 L 251.64 1.8 L 251.64 18.6 L 260.76 9.96 L 268.2 9.96 L 259.24 19.08 L 269 31.48 L 261.44 31.48 Z" id="11" vectorEffect="non-scaling-stroke"/>
		<path d="M 291.84 22.48 L 275.56 22.48 Q 276 24.48 277.64 25.64 Q 279.28 26.8 281.72 26.8 Q 283.4 26.8 284.7 26.3 Q 286 25.8 287.12 24.72 L 290.44 28.32 A 9.777 9.777 0 0 1 285.32 31.37 Q 283.72 31.773 281.815 31.798 A 19.291 19.291 0 0 1 281.56 31.8 Q 277.92 31.8 275.12 30.38 Q 272.32 28.96 270.8 26.44 A 10.65 10.65 0 0 1 269.304 21.516 A 12.96 12.96 0 0 1 269.28 20.72 A 11.442 11.442 0 0 1 269.926 16.84 A 10.568 10.568 0 0 1 270.78 15.02 Q 272.28 12.48 274.9 11.06 A 11.851 11.851 0 0 1 279.861 9.668 A 14.22 14.22 0 0 1 280.76 9.64 A 12.664 12.664 0 0 1 284.476 10.171 A 11.282 11.282 0 0 1 286.48 11 Q 289.04 12.36 290.5 14.9 Q 291.96 17.44 291.96 20.8 Q 291.96 20.913 291.853 22.308 A 495.184 495.184 0 0 1 291.84 22.48 Z M 275.48 18.84 L 286.08 18.84 A 5.728 5.728 0 0 0 285.521 17.082 A 4.912 4.912 0 0 0 284.32 15.58 A 5.122 5.122 0 0 0 281.372 14.384 A 6.662 6.662 0 0 0 280.8 14.36 A 6.191 6.191 0 0 0 279.047 14.598 A 4.991 4.991 0 0 0 277.24 15.56 A 4.916 4.916 0 0 0 275.631 18.121 A 6.551 6.551 0 0 0 275.48 18.84 Z" id="12" vectorEffect="non-scaling-stroke"/>
		<path d="M 309.48 9.64 L 309.48 15.4 A 62.949 62.949 0 0 0 309.058 15.369 Q 308.361 15.32 308.08 15.32 A 7.732 7.732 0 0 0 306.375 15.497 Q 305.135 15.778 304.238 16.501 A 4.779 4.779 0 0 0 303.88 16.82 Q 302.605 18.078 302.4 20.39 A 10.494 10.494 0 0 0 302.36 21.32 L 302.36 31.48 L 296.12 31.48 L 296.12 9.96 L 302.08 9.96 L 302.08 12.8 A 6.85 6.85 0 0 1 304.751 10.597 A 8.238 8.238 0 0 1 305.1 10.44 A 9.974 9.974 0 0 1 307.491 9.783 A 13.324 13.324 0 0 1 309.48 9.64 Z" id="13" vectorEffect="non-scaling-stroke"/>
	</g>
</svg>
                    </>
                    ):(
                        <>
<svg width="232.986" height="27.144" id="wb" className="position_box" viewBox="0 0 232.986 27.144" xmlns="http://www.w3.org/2000/svg">
<g id="svgGroup" strokeLinecap="round" fillRule="evenodd" fontSize="9pt" stroke="#000" strokeWidth="0.25mm" fill="none" style={{stroke:"#000",strokeWidth:"0.25mm",fill:"none"}}>
		<path d="M 27.956 1.218 L 32.451 1.218 L 25.81 21.518 L 20.764 21.518 L 16.298 7.772 L 11.687 21.518 L 6.67 21.518 L 0 1.218 L 4.872 1.218 L 9.454 15.486 L 14.239 1.218 L 18.589 1.218 L 23.229 15.602 L 27.956 1.218 Z" id="0" vectorEffect="non-scaling-stroke"/>
		<path d="M 48.778 14.993 L 36.975 14.993 Q 37.294 16.443 38.483 17.284 A 4.469 4.469 0 0 0 40.196 18.007 A 6.246 6.246 0 0 0 41.441 18.125 A 6.979 6.979 0 0 0 42.556 18.04 A 5.235 5.235 0 0 0 43.602 17.763 A 5.012 5.012 0 0 0 44.942 16.982 A 6.075 6.075 0 0 0 45.356 16.617 L 47.763 19.227 A 7.088 7.088 0 0 1 44.051 21.438 Q 42.891 21.731 41.51 21.749 A 13.989 13.989 0 0 1 41.325 21.75 Q 38.686 21.75 36.656 20.721 Q 34.626 19.691 33.524 17.864 A 7.721 7.721 0 0 1 32.439 14.294 A 9.396 9.396 0 0 1 32.422 13.717 Q 32.422 11.426 33.51 9.585 Q 34.597 7.743 36.497 6.714 A 8.592 8.592 0 0 1 40.093 5.704 A 10.31 10.31 0 0 1 40.745 5.684 Q 43.036 5.684 44.892 6.67 Q 46.748 7.656 47.807 9.498 A 7.93 7.93 0 0 1 48.779 12.434 A 10.153 10.153 0 0 1 48.865 13.775 Q 48.865 13.857 48.788 14.868 A 356.268 356.268 0 0 1 48.778 14.993 Z M 36.917 12.354 L 44.602 12.354 Q 44.37 10.875 43.326 9.991 A 3.713 3.713 0 0 0 41.189 9.123 A 4.83 4.83 0 0 0 40.774 9.106 A 4.488 4.488 0 0 0 39.503 9.279 A 3.619 3.619 0 0 0 38.193 9.976 A 3.564 3.564 0 0 0 37.027 11.833 A 4.75 4.75 0 0 0 36.917 12.354 Z" id="1" vectorEffect="non-scaling-stroke"/>
		<path d="M 56.202 19.72 L 56.202 21.518 L 51.881 21.518 L 51.881 0 L 56.405 0 L 56.405 7.569 A 5.626 5.626 0 0 1 59.731 5.795 A 8.161 8.161 0 0 1 61.103 5.684 A 8.168 8.168 0 0 1 64.027 6.201 A 7.695 7.695 0 0 1 65.061 6.685 Q 66.845 7.685 67.86 9.498 A 7.909 7.909 0 0 1 68.776 12.271 A 10.226 10.226 0 0 1 68.875 13.717 A 9.658 9.658 0 0 1 68.629 15.942 A 7.666 7.666 0 0 1 67.86 17.937 Q 66.845 19.749 65.062 20.75 Q 63.278 21.75 61.103 21.75 Q 57.884 21.75 56.202 19.72 Z M 60.32 18.038 A 4.207 4.207 0 0 0 61.746 17.805 A 3.651 3.651 0 0 0 63.177 16.864 A 3.95 3.95 0 0 0 64.173 14.925 A 5.765 5.765 0 0 0 64.293 13.717 A 5.646 5.646 0 0 0 64.142 12.376 A 3.916 3.916 0 0 0 63.177 10.571 A 3.725 3.725 0 0 0 60.535 9.401 A 4.988 4.988 0 0 0 60.32 9.396 A 4.207 4.207 0 0 0 58.894 9.629 A 3.651 3.651 0 0 0 57.463 10.57 A 3.95 3.95 0 0 0 56.467 12.509 A 5.765 5.765 0 0 0 56.347 13.717 A 5.646 5.646 0 0 0 56.498 15.058 A 3.916 3.916 0 0 0 57.463 16.863 A 3.725 3.725 0 0 0 60.105 18.033 A 4.988 4.988 0 0 0 60.32 18.038 Z" id="2" vectorEffect="non-scaling-stroke"/>
		<path d="M 80.417 21.518 L 80.417 1.218 L 89.639 1.218 Q 92.945 1.218 95.482 2.479 A 9.648 9.648 0 0 1 98.599 4.888 A 9.31 9.31 0 0 1 99.426 6.032 A 9.502 9.502 0 0 1 100.745 9.893 A 12.071 12.071 0 0 1 100.833 11.368 A 11.148 11.148 0 0 1 100.47 14.266 A 9.178 9.178 0 0 1 99.427 16.704 A 9.427 9.427 0 0 1 95.562 20.217 A 11.222 11.222 0 0 1 95.482 20.256 A 11.957 11.957 0 0 1 91.821 21.369 A 15.455 15.455 0 0 1 89.639 21.518 L 80.417 21.518 Z M 85.115 17.661 L 89.407 17.661 Q 92.452 17.661 94.265 15.965 A 5.614 5.614 0 0 0 95.976 12.671 A 8.062 8.062 0 0 0 96.077 11.368 A 7.481 7.481 0 0 0 95.822 9.363 A 5.488 5.488 0 0 0 94.264 6.772 A 6.106 6.106 0 0 0 91.355 5.275 A 8.963 8.963 0 0 0 89.407 5.075 L 85.115 5.075 L 85.115 17.661 Z" id="4" vectorEffect="non-scaling-stroke"/>
		<path d="M 119.248 14.993 L 107.445 14.993 Q 107.764 16.443 108.953 17.284 A 4.469 4.469 0 0 0 110.666 18.007 A 6.246 6.246 0 0 0 111.911 18.125 A 6.979 6.979 0 0 0 113.026 18.04 A 5.235 5.235 0 0 0 114.072 17.763 A 5.012 5.012 0 0 0 115.412 16.982 A 6.075 6.075 0 0 0 115.826 16.617 L 118.233 19.227 A 7.088 7.088 0 0 1 114.521 21.438 Q 113.361 21.731 111.98 21.749 A 13.989 13.989 0 0 1 111.795 21.75 Q 109.156 21.75 107.126 20.721 Q 105.096 19.691 103.994 17.864 A 7.721 7.721 0 0 1 102.909 14.294 A 9.396 9.396 0 0 1 102.892 13.717 Q 102.892 11.426 103.98 9.585 Q 105.067 7.743 106.967 6.714 A 8.592 8.592 0 0 1 110.563 5.704 A 10.31 10.31 0 0 1 111.215 5.684 Q 113.506 5.684 115.362 6.67 Q 117.218 7.656 118.277 9.498 A 7.93 7.93 0 0 1 119.249 12.434 A 10.153 10.153 0 0 1 119.335 13.775 Q 119.335 13.857 119.258 14.868 A 356.268 356.268 0 0 1 119.248 14.993 Z M 107.387 12.354 L 115.072 12.354 Q 114.84 10.875 113.796 9.991 A 3.713 3.713 0 0 0 111.659 9.123 A 4.83 4.83 0 0 0 111.244 9.106 A 4.488 4.488 0 0 0 109.973 9.279 A 3.619 3.619 0 0 0 108.663 9.976 A 3.564 3.564 0 0 0 107.497 11.833 A 4.75 4.75 0 0 0 107.387 12.354 Z" id="5" vectorEffect="non-scaling-stroke"/>
		<path d="M 133.197 5.916 L 137.547 5.916 L 130.964 21.518 L 126.295 21.518 L 119.741 5.916 L 124.41 5.916 L 128.731 16.53 L 133.197 5.916 Z" id="6" vectorEffect="non-scaling-stroke"/>
		<path d="M 154.164 14.993 L 142.361 14.993 Q 142.68 16.443 143.869 17.284 A 4.469 4.469 0 0 0 145.582 18.007 A 6.246 6.246 0 0 0 146.827 18.125 A 6.979 6.979 0 0 0 147.942 18.04 A 5.235 5.235 0 0 0 148.988 17.763 A 5.012 5.012 0 0 0 150.328 16.982 A 6.075 6.075 0 0 0 150.742 16.617 L 153.149 19.227 A 7.088 7.088 0 0 1 149.437 21.438 Q 148.277 21.731 146.896 21.749 A 13.989 13.989 0 0 1 146.711 21.75 Q 144.072 21.75 142.042 20.721 Q 140.012 19.691 138.91 17.864 A 7.721 7.721 0 0 1 137.825 14.294 A 9.396 9.396 0 0 1 137.808 13.717 Q 137.808 11.426 138.896 9.585 Q 139.983 7.743 141.883 6.714 A 8.592 8.592 0 0 1 145.479 5.704 A 10.31 10.31 0 0 1 146.131 5.684 Q 148.422 5.684 150.278 6.67 Q 152.134 7.656 153.193 9.498 A 7.93 7.93 0 0 1 154.165 12.434 A 10.153 10.153 0 0 1 154.251 13.775 Q 154.251 13.857 154.174 14.868 A 356.268 356.268 0 0 1 154.164 14.993 Z M 142.303 12.354 L 149.988 12.354 Q 149.756 10.875 148.712 9.991 A 3.713 3.713 0 0 0 146.575 9.123 A 4.83 4.83 0 0 0 146.16 9.106 A 4.488 4.488 0 0 0 144.889 9.279 A 3.619 3.619 0 0 0 143.579 9.976 A 3.564 3.564 0 0 0 142.413 11.833 A 4.75 4.75 0 0 0 142.303 12.354 Z" id="7" vectorEffect="non-scaling-stroke"/>
		<path d="M 157.267 21.518 L 157.267 0 L 161.791 0 L 161.791 21.518 L 157.267 21.518 Z" id="8" vectorEffect="non-scaling-stroke"/>
		<path d="M 170.807 21.424 A 10.139 10.139 0 0 0 173.42 21.75 A 10.994 10.994 0 0 0 174.547 21.693 A 8.88 8.88 0 0 0 177.828 20.72 Q 179.771 19.691 180.873 17.864 A 7.455 7.455 0 0 0 181.587 16.298 A 8.356 8.356 0 0 0 181.975 13.717 A 9.396 9.396 0 0 0 181.958 13.14 A 7.721 7.721 0 0 0 180.873 9.57 Q 179.771 7.743 177.828 6.714 A 8.526 8.526 0 0 0 175.994 6.001 A 10.105 10.105 0 0 0 173.42 5.684 A 11.066 11.066 0 0 0 172.366 5.733 A 9.003 9.003 0 0 0 168.998 6.714 Q 167.04 7.743 165.938 9.57 A 7.455 7.455 0 0 0 165.224 11.136 A 8.356 8.356 0 0 0 164.836 13.717 A 9.396 9.396 0 0 0 164.853 14.294 A 7.721 7.721 0 0 0 165.938 17.864 Q 167.04 19.691 168.998 20.721 A 8.643 8.643 0 0 0 170.807 21.424 Z M 173.42 18.038 A 4.207 4.207 0 0 0 174.846 17.805 A 3.651 3.651 0 0 0 176.277 16.864 A 3.95 3.95 0 0 0 177.273 14.925 A 5.765 5.765 0 0 0 177.393 13.717 A 5.646 5.646 0 0 0 177.242 12.376 A 3.916 3.916 0 0 0 176.277 10.571 A 3.725 3.725 0 0 0 173.635 9.401 A 4.988 4.988 0 0 0 173.42 9.396 Q 171.68 9.396 170.549 10.571 A 3.946 3.946 0 0 0 169.529 12.561 A 5.74 5.74 0 0 0 169.418 13.717 A 5.565 5.565 0 0 0 169.574 15.071 A 3.897 3.897 0 0 0 170.549 16.863 A 3.783 3.783 0 0 0 173.258 18.035 A 5.032 5.032 0 0 0 173.42 18.038 Z" id="9" vectorEffect="non-scaling-stroke"/>
		<path d="M 189.515 19.865 L 189.515 27.144 L 184.991 27.144 L 184.991 5.916 L 189.312 5.916 L 189.312 7.714 Q 190.994 5.684 194.213 5.684 A 8.168 8.168 0 0 1 197.137 6.201 A 7.695 7.695 0 0 1 198.171 6.685 Q 199.955 7.685 200.97 9.498 A 7.909 7.909 0 0 1 201.886 12.271 A 10.226 10.226 0 0 1 201.985 13.717 A 9.658 9.658 0 0 1 201.739 15.942 A 7.666 7.666 0 0 1 200.97 17.937 Q 199.955 19.749 198.172 20.75 Q 196.388 21.75 194.213 21.75 A 7.611 7.611 0 0 1 192.191 21.496 A 5.508 5.508 0 0 1 189.515 19.865 Z M 193.43 18.038 A 4.207 4.207 0 0 0 194.856 17.805 A 3.651 3.651 0 0 0 196.287 16.864 A 3.95 3.95 0 0 0 197.283 14.925 A 5.765 5.765 0 0 0 197.403 13.717 A 5.646 5.646 0 0 0 197.252 12.376 A 3.916 3.916 0 0 0 196.287 10.571 A 3.725 3.725 0 0 0 193.645 9.401 A 4.988 4.988 0 0 0 193.43 9.396 A 4.207 4.207 0 0 0 192.004 9.629 A 3.651 3.651 0 0 0 190.573 10.57 A 3.95 3.95 0 0 0 189.577 12.509 A 5.765 5.765 0 0 0 189.457 13.717 A 5.646 5.646 0 0 0 189.608 15.058 A 3.916 3.916 0 0 0 190.573 16.863 A 3.725 3.725 0 0 0 193.215 18.033 A 4.988 4.988 0 0 0 193.43 18.038 Z" id="10" vectorEffect="non-scaling-stroke"/>
		<path d="M 220.197 14.993 L 208.394 14.993 Q 208.713 16.443 209.902 17.284 A 4.469 4.469 0 0 0 211.615 18.007 A 6.246 6.246 0 0 0 212.86 18.125 A 6.979 6.979 0 0 0 213.975 18.04 A 5.235 5.235 0 0 0 215.021 17.763 A 5.012 5.012 0 0 0 216.361 16.982 A 6.075 6.075 0 0 0 216.775 16.617 L 219.182 19.227 A 7.088 7.088 0 0 1 215.47 21.438 Q 214.31 21.731 212.929 21.749 A 13.989 13.989 0 0 1 212.744 21.75 Q 210.105 21.75 208.075 20.721 Q 206.045 19.691 204.943 17.864 A 7.721 7.721 0 0 1 203.858 14.294 A 9.396 9.396 0 0 1 203.841 13.717 Q 203.841 11.426 204.929 9.585 Q 206.016 7.743 207.916 6.714 A 8.592 8.592 0 0 1 211.512 5.704 A 10.31 10.31 0 0 1 212.164 5.684 Q 214.455 5.684 216.311 6.67 Q 218.167 7.656 219.226 9.498 A 7.93 7.93 0 0 1 220.198 12.434 A 10.153 10.153 0 0 1 220.284 13.775 Q 220.284 13.857 220.207 14.868 A 356.268 356.268 0 0 1 220.197 14.993 Z M 208.336 12.354 L 216.021 12.354 Q 215.789 10.875 214.745 9.991 A 3.713 3.713 0 0 0 212.608 9.123 A 4.83 4.83 0 0 0 212.193 9.106 A 4.488 4.488 0 0 0 210.922 9.279 A 3.619 3.619 0 0 0 209.612 9.976 A 3.564 3.564 0 0 0 208.446 11.833 A 4.75 4.75 0 0 0 208.336 12.354 Z" id="11" vectorEffect="non-scaling-stroke"/>
		<path d="M 232.986 5.684 L 232.986 9.86 Q 232.232 9.802 231.971 9.802 Q 230.247 9.802 229.185 10.658 A 3.465 3.465 0 0 0 228.926 10.889 Q 228.002 11.801 227.853 13.478 A 7.608 7.608 0 0 0 227.824 14.152 L 227.824 21.518 L 223.3 21.518 L 223.3 5.916 L 227.621 5.916 L 227.621 7.975 A 4.967 4.967 0 0 1 229.557 6.378 A 5.973 5.973 0 0 1 229.811 6.264 A 7.231 7.231 0 0 1 231.544 5.787 A 9.66 9.66 0 0 1 232.986 5.684 Z" id="12" vectorEffect="non-scaling-stroke"/>
	</g>
</svg>

<svg width="167.91" height="22.75" id="rh" className="position_box" viewBox="0 0 167.91 22.75" xmlns="http://www.w3.org/2000/svg">
<g id="svgGroup" strokeLinecap="round" fillRule="evenodd" fontSize="9pt" stroke="#000" strokeWidth="0.25mm" fill="none" style={{stroke:"#000",strokeWidth:"0.25mm",fill:"none"}}>
		<path d="M 17.98 21.518 L 12.934 21.518 L 9.019 15.863 L 8.787 15.863 L 4.698 15.863 L 4.698 21.518 L 0 21.518 L 0 1.218 L 8.787 1.218 Q 11.437 1.218 13.4 2.086 A 8.584 8.584 0 0 1 13.471 2.117 Q 15.457 3.016 16.53 4.669 A 6.62 6.62 0 0 1 17.523 7.393 A 8.588 8.588 0 0 1 17.603 8.584 Q 17.603 10.846 16.516 12.485 A 6.666 6.666 0 0 1 14.145 14.64 A 8.342 8.342 0 0 1 13.427 14.993 L 17.98 21.518 Z M 8.526 5.046 L 4.698 5.046 L 4.698 12.122 L 8.526 12.122 A 7.722 7.722 0 0 0 9.743 12.033 Q 10.364 11.933 10.865 11.724 A 3.386 3.386 0 0 0 11.745 11.194 A 3.026 3.026 0 0 0 12.792 9.296 A 4.447 4.447 0 0 0 12.847 8.584 Q 12.847 6.873 11.745 5.96 A 3.546 3.546 0 0 0 10.586 5.335 Q 9.717 5.046 8.526 5.046 Z" id="0" vectorEffect="non-scaling-stroke"/>
		<path d="M 36.192 14.993 L 24.389 14.993 Q 24.708 16.443 25.897 17.284 A 4.469 4.469 0 0 0 27.61 18.007 A 6.246 6.246 0 0 0 28.855 18.125 A 6.979 6.979 0 0 0 29.97 18.04 A 5.235 5.235 0 0 0 31.016 17.763 A 5.012 5.012 0 0 0 32.356 16.982 A 6.075 6.075 0 0 0 32.77 16.617 L 35.177 19.227 A 7.088 7.088 0 0 1 31.465 21.438 Q 30.305 21.731 28.924 21.749 A 13.989 13.989 0 0 1 28.739 21.75 Q 26.1 21.75 24.07 20.721 Q 22.04 19.691 20.938 17.864 A 7.721 7.721 0 0 1 19.853 14.294 A 9.396 9.396 0 0 1 19.836 13.717 Q 19.836 11.426 20.924 9.585 Q 22.011 7.743 23.911 6.714 A 8.592 8.592 0 0 1 27.507 5.704 A 10.31 10.31 0 0 1 28.159 5.684 Q 30.45 5.684 32.306 6.67 Q 34.162 7.656 35.221 9.498 A 7.93 7.93 0 0 1 36.193 12.434 A 10.153 10.153 0 0 1 36.279 13.775 Q 36.279 13.857 36.202 14.868 A 356.268 356.268 0 0 1 36.192 14.993 Z M 24.331 12.354 L 32.016 12.354 Q 31.784 10.875 30.74 9.991 A 3.713 3.713 0 0 0 28.603 9.123 A 4.83 4.83 0 0 0 28.188 9.106 A 4.488 4.488 0 0 0 26.917 9.279 A 3.619 3.619 0 0 0 25.607 9.976 A 3.564 3.564 0 0 0 24.441 11.833 A 4.75 4.75 0 0 0 24.331 12.354 Z" id="1" vectorEffect="non-scaling-stroke"/>
		<path d="M 37.787 20.068 L 39.295 16.82 Q 40.323 17.477 41.773 17.895 A 13.226 13.226 0 0 0 41.818 17.908 A 10.861 10.861 0 0 0 44.071 18.306 A 9.757 9.757 0 0 0 44.718 18.328 A 8.709 8.709 0 0 0 45.675 18.28 Q 46.633 18.173 47.112 17.83 A 1.08 1.08 0 0 0 47.589 16.907 A 0.885 0.885 0 0 0 47.206 16.159 Q 47.062 16.052 46.864 15.972 A 2.141 2.141 0 0 0 46.806 15.95 Q 46.023 15.66 44.399 15.457 Q 43.021 15.248 41.989 14.994 A 14.885 14.885 0 0 1 41.238 14.79 Q 39.991 14.413 39.078 13.456 Q 38.164 12.499 38.164 10.73 Q 38.164 9.251 39.02 8.106 A 4.851 4.851 0 0 1 40.31 6.925 A 6.817 6.817 0 0 1 41.513 6.322 Q 43.152 5.684 45.385 5.684 Q 47.038 5.684 48.677 6.047 A 12.027 12.027 0 0 1 49.909 6.385 Q 50.749 6.667 51.388 7.047 L 49.88 10.266 Q 47.821 9.106 45.385 9.106 Q 44.09 9.106 43.373 9.43 A 2.124 2.124 0 0 0 43.21 9.512 A 1.922 1.922 0 0 0 42.864 9.755 Q 42.668 9.93 42.574 10.138 A 0.996 0.996 0 0 0 42.485 10.556 A 1.019 1.019 0 0 0 42.604 11.055 Q 42.724 11.272 42.962 11.423 A 1.64 1.64 0 0 0 43.268 11.571 A 5.739 5.739 0 0 0 43.717 11.714 Q 44.479 11.926 45.762 12.122 A 39.706 39.706 0 0 1 46.996 12.346 Q 48.095 12.566 48.894 12.804 Q 50.112 13.166 51.011 14.123 A 3.154 3.154 0 0 1 51.747 15.471 Q 51.91 16.062 51.91 16.791 Q 51.91 18.241 51.04 19.372 A 4.806 4.806 0 0 1 49.809 20.484 Q 49.232 20.854 48.503 21.127 Q 46.969 21.7 44.909 21.746 A 16.291 16.291 0 0 1 44.544 21.75 Q 42.601 21.75 40.745 21.272 Q 38.889 20.793 37.787 20.068 Z" id="2" vectorEffect="non-scaling-stroke"/>
		<path d="M 69.89 14.993 L 58.087 14.993 Q 58.406 16.443 59.595 17.284 A 4.469 4.469 0 0 0 61.308 18.007 A 6.246 6.246 0 0 0 62.553 18.125 A 6.979 6.979 0 0 0 63.668 18.04 A 5.235 5.235 0 0 0 64.714 17.763 A 5.012 5.012 0 0 0 66.054 16.982 A 6.075 6.075 0 0 0 66.468 16.617 L 68.875 19.227 A 7.088 7.088 0 0 1 65.163 21.438 Q 64.003 21.731 62.622 21.749 A 13.989 13.989 0 0 1 62.437 21.75 Q 59.798 21.75 57.768 20.721 Q 55.738 19.691 54.636 17.864 A 7.721 7.721 0 0 1 53.551 14.294 A 9.396 9.396 0 0 1 53.534 13.717 Q 53.534 11.426 54.622 9.585 Q 55.709 7.743 57.609 6.714 A 8.592 8.592 0 0 1 61.205 5.704 A 10.31 10.31 0 0 1 61.857 5.684 Q 64.148 5.684 66.004 6.67 Q 67.86 7.656 68.919 9.498 A 7.93 7.93 0 0 1 69.891 12.434 A 10.153 10.153 0 0 1 69.977 13.775 Q 69.977 13.857 69.9 14.868 A 356.268 356.268 0 0 1 69.89 14.993 Z M 58.029 12.354 L 65.714 12.354 Q 65.482 10.875 64.438 9.991 A 3.713 3.713 0 0 0 62.301 9.123 A 4.83 4.83 0 0 0 61.886 9.106 A 4.488 4.488 0 0 0 60.615 9.279 A 3.619 3.619 0 0 0 59.305 9.976 A 3.564 3.564 0 0 0 58.139 11.833 A 4.75 4.75 0 0 0 58.029 12.354 Z" id="3" vectorEffect="non-scaling-stroke"/>
		<path d="M 86.362 12.615 L 86.362 21.518 L 82.128 21.518 L 82.128 19.575 Q 80.852 21.75 77.372 21.75 Q 75.606 21.75 74.301 21.162 A 5.627 5.627 0 0 1 74.254 21.141 Q 72.935 20.532 72.239 19.459 A 4.341 4.341 0 0 1 71.546 17.192 A 5.235 5.235 0 0 1 71.543 17.023 A 4.626 4.626 0 0 1 71.808 15.423 A 3.985 3.985 0 0 1 73.181 13.601 Q 74.82 12.354 78.242 12.354 L 81.838 12.354 A 3.887 3.887 0 0 0 81.733 11.423 A 2.572 2.572 0 0 0 80.939 10.077 Q 80.253 9.469 79.045 9.325 A 6.78 6.78 0 0 0 78.242 9.28 Q 76.995 9.28 75.792 9.672 A 7.354 7.354 0 0 0 74.647 10.148 A 5.724 5.724 0 0 0 73.747 10.73 L 72.123 7.569 A 8.834 8.834 0 0 1 73.817 6.645 A 11.51 11.51 0 0 1 75.183 6.177 Q 76.966 5.684 78.851 5.684 A 12.212 12.212 0 0 1 81.001 5.861 Q 83.098 6.237 84.419 7.409 Q 86.153 8.95 86.34 11.888 A 11.497 11.497 0 0 1 86.362 12.615 Z M 81.838 16.588 L 81.838 14.993 L 78.735 14.993 A 6.352 6.352 0 0 0 77.789 15.057 Q 75.951 15.336 75.951 16.82 A 1.784 1.784 0 0 0 76.072 17.49 Q 76.239 17.904 76.633 18.198 A 2.351 2.351 0 0 0 77.383 18.562 Q 77.871 18.705 78.503 18.705 A 4.271 4.271 0 0 0 79.71 18.54 A 3.672 3.672 0 0 0 80.562 18.168 A 2.922 2.922 0 0 0 81.814 16.653 A 3.667 3.667 0 0 0 81.838 16.588 Z" id="4" vectorEffect="non-scaling-stroke"/>
		<path d="M 100.137 5.684 L 100.137 9.86 Q 99.383 9.802 99.122 9.802 Q 97.398 9.802 96.336 10.658 A 3.465 3.465 0 0 0 96.077 10.889 Q 95.153 11.801 95.004 13.478 A 7.608 7.608 0 0 0 94.975 14.152 L 94.975 21.518 L 90.451 21.518 L 90.451 5.916 L 94.772 5.916 L 94.772 7.975 A 4.967 4.967 0 0 1 96.708 6.378 A 5.973 5.973 0 0 1 96.962 6.264 A 7.231 7.231 0 0 1 98.695 5.787 A 9.66 9.66 0 0 1 100.137 5.684 Z" id="5" vectorEffect="non-scaling-stroke"/>
		<path d="M 117.218 9.657 L 113.709 11.542 A 4.793 4.793 0 0 0 112.794 10.365 A 3.693 3.693 0 0 0 110.2 9.396 A 4.423 4.423 0 0 0 108.722 9.634 A 3.832 3.832 0 0 0 107.271 10.556 Q 106.111 11.716 106.111 13.717 A 5.572 5.572 0 0 0 106.261 15.047 A 3.82 3.82 0 0 0 107.271 16.878 A 3.914 3.914 0 0 0 109.927 18.031 A 5.187 5.187 0 0 0 110.2 18.038 A 3.931 3.931 0 0 0 111.956 17.656 Q 112.789 17.247 113.39 16.4 A 5.369 5.369 0 0 0 113.709 15.892 L 117.218 17.806 Q 116.377 19.662 114.536 20.706 A 7.95 7.95 0 0 1 111.78 21.638 A 10.342 10.342 0 0 1 110.229 21.75 A 10.394 10.394 0 0 1 107.584 21.424 A 8.858 8.858 0 0 1 105.748 20.721 Q 103.762 19.691 102.646 17.864 A 7.65 7.65 0 0 1 101.546 14.289 A 9.277 9.277 0 0 1 101.529 13.717 A 8.237 8.237 0 0 1 101.938 11.088 A 7.409 7.409 0 0 1 102.646 9.57 Q 103.762 7.743 105.749 6.714 A 9.229 9.229 0 0 1 109.153 5.734 A 11.33 11.33 0 0 1 110.229 5.684 A 10.01 10.01 0 0 1 112.403 5.91 A 7.816 7.816 0 0 1 114.536 6.713 Q 116.377 7.743 117.218 9.657 Z" id="6" vectorEffect="non-scaling-stroke"/>
		<path d="M 135.662 12.586 L 135.662 21.518 L 131.138 21.518 L 131.138 13.282 A 6.756 6.756 0 0 0 131.066 12.263 Q 130.987 11.744 130.819 11.326 A 2.763 2.763 0 0 0 130.326 10.512 A 2.62 2.62 0 0 0 128.958 9.709 Q 128.539 9.606 128.043 9.599 A 5.094 5.094 0 0 0 127.977 9.599 A 4.479 4.479 0 0 0 126.792 9.748 A 3.3 3.3 0 0 0 125.251 10.657 A 3.334 3.334 0 0 0 124.506 11.924 Q 124.331 12.445 124.269 13.081 A 7.514 7.514 0 0 0 124.236 13.804 L 124.236 21.518 L 119.712 21.518 L 119.712 0 L 124.236 0 L 124.236 7.54 A 5.949 5.949 0 0 1 126.175 6.256 A 6.916 6.916 0 0 1 126.411 6.162 A 7.591 7.591 0 0 1 128.524 5.709 A 9.037 9.037 0 0 1 129.195 5.684 A 7.858 7.858 0 0 1 131.29 5.95 A 5.883 5.883 0 0 1 133.878 7.424 Q 135.401 8.909 135.624 11.62 A 11.793 11.793 0 0 1 135.662 12.586 Z" id="7" vectorEffect="non-scaling-stroke"/>
		<path d="M 155.121 14.993 L 143.318 14.993 Q 143.637 16.443 144.826 17.284 A 4.469 4.469 0 0 0 146.539 18.007 A 6.246 6.246 0 0 0 147.784 18.125 A 6.979 6.979 0 0 0 148.899 18.04 A 5.235 5.235 0 0 0 149.945 17.763 A 5.012 5.012 0 0 0 151.285 16.982 A 6.075 6.075 0 0 0 151.699 16.617 L 154.106 19.227 A 7.088 7.088 0 0 1 150.394 21.438 Q 149.234 21.731 147.853 21.749 A 13.989 13.989 0 0 1 147.668 21.75 Q 145.029 21.75 142.999 20.721 Q 140.969 19.691 139.867 17.864 A 7.721 7.721 0 0 1 138.782 14.294 A 9.396 9.396 0 0 1 138.765 13.717 Q 138.765 11.426 139.853 9.585 Q 140.94 7.743 142.84 6.714 A 8.592 8.592 0 0 1 146.436 5.704 A 10.31 10.31 0 0 1 147.088 5.684 Q 149.379 5.684 151.235 6.67 Q 153.091 7.656 154.15 9.498 A 7.93 7.93 0 0 1 155.122 12.434 A 10.153 10.153 0 0 1 155.208 13.775 Q 155.208 13.857 155.131 14.868 A 356.268 356.268 0 0 1 155.121 14.993 Z M 143.26 12.354 L 150.945 12.354 Q 150.713 10.875 149.669 9.991 A 3.713 3.713 0 0 0 147.532 9.123 A 4.83 4.83 0 0 0 147.117 9.106 A 4.488 4.488 0 0 0 145.846 9.279 A 3.619 3.619 0 0 0 144.536 9.976 A 3.564 3.564 0 0 0 143.37 11.833 A 4.75 4.75 0 0 0 143.26 12.354 Z" id="8" vectorEffect="non-scaling-stroke"/>
		<path d="M 167.91 5.684 L 167.91 9.86 Q 167.156 9.802 166.895 9.802 Q 165.171 9.802 164.109 10.658 A 3.465 3.465 0 0 0 163.85 10.889 Q 162.926 11.801 162.777 13.478 A 7.608 7.608 0 0 0 162.748 14.152 L 162.748 21.518 L 158.224 21.518 L 158.224 5.916 L 162.545 5.916 L 162.545 7.975 A 4.967 4.967 0 0 1 164.481 6.378 A 5.973 5.973 0 0 1 164.735 6.264 A 7.231 7.231 0 0 1 166.468 5.787 A 9.66 9.66 0 0 1 167.91 5.684 Z" id="9" vectorEffect="non-scaling-stroke"/>
	</g>
</svg>

                    <svg width="181.002" height="28.681" id="cc" className="position_box" viewBox="0 0 181.002 28.681" xmlns="http://www.w3.org/2000/svg">
                    <g id="svgGroup" strokeLinecap="round" fillRule="evenodd" fontSize="9pt" stroke="#000" strokeWidth="0.25mm" fill="none" style={{stroke:"#000",strokeWidth:"0.25mm",fill:"none"}}>
		<path d="M 19.372 5.771 L 16.356 8.555 A 6.981 6.981 0 0 0 14.214 6.851 A 6.506 6.506 0 0 0 11.252 6.177 A 7.344 7.344 0 0 0 9.26 6.439 A 6.28 6.28 0 0 0 7.888 7.004 Q 6.409 7.83 5.583 9.309 A 6.524 6.524 0 0 0 4.796 11.868 A 8.038 8.038 0 0 0 4.756 12.673 A 7.344 7.344 0 0 0 5.018 14.665 A 6.28 6.28 0 0 0 5.583 16.037 Q 6.409 17.516 7.888 18.343 A 6.524 6.524 0 0 0 10.447 19.13 A 8.038 8.038 0 0 0 11.252 19.169 A 6.383 6.383 0 0 0 16.029 17.124 A 8.308 8.308 0 0 0 16.356 16.762 L 19.372 19.546 Q 17.922 21.315 15.776 22.243 A 11.324 11.324 0 0 1 12.437 23.098 A 13.918 13.918 0 0 1 10.991 23.171 A 12.303 12.303 0 0 1 7.345 22.644 A 10.972 10.972 0 0 1 5.38 21.823 Q 2.871 20.474 1.436 18.082 A 10.145 10.145 0 0 1 0.017 13.325 A 12.263 12.263 0 0 1 0 12.673 A 10.853 10.853 0 0 1 0.547 9.188 A 9.816 9.816 0 0 1 1.436 7.265 Q 2.871 4.872 5.38 3.524 Q 7.888 2.175 11.02 2.175 A 12.944 12.944 0 0 1 13.829 2.469 A 10.715 10.715 0 0 1 15.791 3.103 Q 17.922 4.031 19.372 5.771 Z" id="0" vectorEffect="non-scaling-stroke"/>
		<path d="M 26.475 22.729 A 10.139 10.139 0 0 0 29.087 23.055 A 10.994 10.994 0 0 0 30.214 22.999 A 8.88 8.88 0 0 0 33.495 22.026 Q 35.438 20.996 36.54 19.169 A 7.455 7.455 0 0 0 37.254 17.603 A 8.356 8.356 0 0 0 37.642 15.022 A 9.396 9.396 0 0 0 37.625 14.445 A 7.721 7.721 0 0 0 36.54 10.875 Q 35.438 9.048 33.495 8.019 A 8.526 8.526 0 0 0 31.661 7.306 A 10.105 10.105 0 0 0 29.087 6.989 A 11.066 11.066 0 0 0 28.033 7.038 A 9.003 9.003 0 0 0 24.665 8.019 Q 22.707 9.048 21.605 10.875 A 7.455 7.455 0 0 0 20.892 12.441 A 8.356 8.356 0 0 0 20.503 15.022 A 9.396 9.396 0 0 0 20.521 15.599 A 7.721 7.721 0 0 0 21.605 19.169 Q 22.707 20.996 24.665 22.026 A 8.643 8.643 0 0 0 26.475 22.729 Z M 29.087 19.343 A 4.207 4.207 0 0 0 30.513 19.11 A 3.651 3.651 0 0 0 31.944 18.169 A 3.95 3.95 0 0 0 32.94 16.23 A 5.765 5.765 0 0 0 33.06 15.022 A 5.646 5.646 0 0 0 32.91 13.681 A 3.916 3.916 0 0 0 31.944 11.876 A 3.725 3.725 0 0 0 29.302 10.706 A 4.988 4.988 0 0 0 29.087 10.701 Q 27.347 10.701 26.216 11.876 A 3.946 3.946 0 0 0 25.196 13.866 A 5.74 5.74 0 0 0 25.085 15.022 A 5.565 5.565 0 0 0 25.241 16.376 A 3.897 3.897 0 0 0 26.216 18.169 A 3.783 3.783 0 0 0 28.926 19.341 A 5.032 5.032 0 0 0 29.087 19.343 Z" id="1" vectorEffect="non-scaling-stroke"/>
		<path d="M 67.019 13.891 L 67.019 22.823 L 62.495 22.823 L 62.495 14.587 A 7.219 7.219 0 0 0 62.435 13.618 Q 62.367 13.117 62.223 12.71 A 2.844 2.844 0 0 0 61.727 11.818 A 2.497 2.497 0 0 0 60.227 10.965 A 3.73 3.73 0 0 0 59.537 10.904 A 3.984 3.984 0 0 0 58.435 11.049 A 2.983 2.983 0 0 0 57.014 11.934 A 3.245 3.245 0 0 0 56.364 13.072 Q 56.195 13.566 56.129 14.171 A 7.582 7.582 0 0 0 56.086 14.993 L 56.086 22.823 L 51.562 22.823 L 51.562 14.587 Q 51.562 10.904 48.604 10.904 A 3.845 3.845 0 0 0 47.483 11.06 A 2.983 2.983 0 0 0 46.11 11.934 A 3.245 3.245 0 0 0 45.46 13.072 Q 45.291 13.566 45.225 14.171 A 7.582 7.582 0 0 0 45.182 14.993 L 45.182 22.823 L 40.658 22.823 L 40.658 7.221 L 44.979 7.221 L 44.979 9.019 Q 45.849 8.033 47.111 7.511 A 6.932 6.932 0 0 1 49.232 7.014 A 8.275 8.275 0 0 1 49.88 6.989 A 7.219 7.219 0 0 1 51.708 7.213 A 6.181 6.181 0 0 1 52.867 7.642 Q 54.201 8.294 55.013 9.541 Q 55.97 8.323 57.435 7.656 A 7.513 7.513 0 0 1 60.209 7 A 8.82 8.82 0 0 1 60.639 6.989 Q 63.568 6.989 65.294 8.715 Q 67.019 10.44 67.019 13.891 Z" id="2" vectorEffect="non-scaling-stroke"/>
		<path d="M 97.44 13.891 L 97.44 22.823 L 92.916 22.823 L 92.916 14.587 A 7.219 7.219 0 0 0 92.856 13.618 Q 92.788 13.117 92.644 12.71 A 2.844 2.844 0 0 0 92.148 11.818 A 2.497 2.497 0 0 0 90.648 10.965 A 3.73 3.73 0 0 0 89.958 10.904 A 3.984 3.984 0 0 0 88.856 11.049 A 2.983 2.983 0 0 0 87.435 11.934 A 3.245 3.245 0 0 0 86.785 13.072 Q 86.616 13.566 86.55 14.171 A 7.582 7.582 0 0 0 86.507 14.993 L 86.507 22.823 L 81.983 22.823 L 81.983 14.587 Q 81.983 10.904 79.025 10.904 A 3.845 3.845 0 0 0 77.904 11.06 A 2.983 2.983 0 0 0 76.531 11.934 A 3.245 3.245 0 0 0 75.881 13.072 Q 75.712 13.566 75.646 14.171 A 7.582 7.582 0 0 0 75.603 14.993 L 75.603 22.823 L 71.079 22.823 L 71.079 7.221 L 75.4 7.221 L 75.4 9.019 Q 76.27 8.033 77.532 7.511 A 6.932 6.932 0 0 1 79.653 7.014 A 8.275 8.275 0 0 1 80.301 6.989 A 7.219 7.219 0 0 1 82.129 7.213 A 6.181 6.181 0 0 1 83.288 7.642 Q 84.622 8.294 85.434 9.541 Q 86.391 8.323 87.856 7.656 A 7.513 7.513 0 0 1 90.63 7 A 8.82 8.82 0 0 1 91.06 6.989 Q 93.989 6.989 95.715 8.715 Q 97.44 10.44 97.44 13.891 Z" id="3" vectorEffect="non-scaling-stroke"/>
		<path d="M 112.694 7.221 L 117.218 7.221 L 117.218 22.823 L 112.926 22.823 L 112.926 20.967 Q 112.027 21.982 110.78 22.519 A 6.69 6.69 0 0 1 108.309 23.052 A 7.712 7.712 0 0 1 108.083 23.055 A 8.647 8.647 0 0 1 106.039 22.827 A 5.946 5.946 0 0 1 103.211 21.286 Q 101.668 19.767 101.449 16.988 A 12.141 12.141 0 0 1 101.413 16.037 L 101.413 7.221 L 105.937 7.221 L 105.937 15.37 Q 105.937 18.7 108.404 19.089 A 4.465 4.465 0 0 0 109.098 19.14 A 4.038 4.038 0 0 0 110.291 18.972 A 3.188 3.188 0 0 0 111.708 18.082 A 3.334 3.334 0 0 0 112.416 16.863 Q 112.59 16.351 112.655 15.726 A 7.632 7.632 0 0 0 112.694 14.935 L 112.694 7.221 Z" id="4" vectorEffect="non-scaling-stroke"/>
		<path d="M 137.373 13.891 L 137.373 22.823 L 132.849 22.823 L 132.849 14.587 A 6.756 6.756 0 0 0 132.778 13.568 Q 132.698 13.049 132.531 12.632 A 2.763 2.763 0 0 0 132.037 11.818 A 2.62 2.62 0 0 0 130.669 11.015 Q 130.251 10.911 129.755 10.905 A 5.094 5.094 0 0 0 129.688 10.904 A 4.479 4.479 0 0 0 128.503 11.053 A 3.3 3.3 0 0 0 126.962 11.963 A 3.334 3.334 0 0 0 126.217 13.23 Q 126.042 13.75 125.981 14.386 A 7.514 7.514 0 0 0 125.947 15.109 L 125.947 22.823 L 121.423 22.823 L 121.423 7.221 L 125.744 7.221 L 125.744 9.048 A 5.914 5.914 0 0 1 127.691 7.648 A 6.953 6.953 0 0 1 127.977 7.526 A 7.49 7.49 0 0 1 130.173 7.019 A 8.973 8.973 0 0 1 130.906 6.989 A 7.858 7.858 0 0 1 133.002 7.255 A 5.883 5.883 0 0 1 135.59 8.729 Q 137.112 10.214 137.335 12.925 A 11.793 11.793 0 0 1 137.373 13.891 Z" id="5" vectorEffect="non-scaling-stroke"/>
		<path d="M 141.462 22.823 L 141.462 7.221 L 145.986 7.221 L 145.986 22.823 L 141.462 22.823 Z M 142.804 4.931 A 3.497 3.497 0 0 0 143.724 5.046 A 3.708 3.708 0 0 0 144.288 5.005 A 2.671 2.671 0 0 0 145.754 4.307 A 2.433 2.433 0 0 0 146.365 3.41 A 2.675 2.675 0 0 0 146.537 2.436 A 2.332 2.332 0 0 0 146.377 1.561 A 2.294 2.294 0 0 0 145.754 0.696 A 2.656 2.656 0 0 0 144.628 0.106 A 3.648 3.648 0 0 0 143.724 0 A 3.741 3.741 0 0 0 143.112 0.048 A 2.681 2.681 0 0 0 141.694 0.725 A 2.466 2.466 0 0 0 141.172 1.4 A 2.403 2.403 0 0 0 140.911 2.523 A 2.876 2.876 0 0 0 140.913 2.623 A 2.342 2.342 0 0 0 141.694 4.321 A 2.619 2.619 0 0 0 142.804 4.931 Z" id="6" vectorEffect="non-scaling-stroke"/>
		<path d="M 159.036 18.879 L 160.254 22.069 A 4.109 4.109 0 0 1 159.408 22.546 A 5.516 5.516 0 0 1 158.616 22.809 A 8.174 8.174 0 0 1 156.838 23.051 A 9.18 9.18 0 0 1 156.571 23.055 Q 153.787 23.055 152.265 21.634 A 4.535 4.535 0 0 1 151.013 19.545 Q 150.742 18.62 150.742 17.458 L 150.742 11.049 L 148.335 11.049 L 148.335 7.569 L 150.742 7.569 L 150.742 3.77 L 155.266 3.77 L 155.266 7.569 L 159.152 7.569 L 159.152 11.049 L 155.266 11.049 L 155.266 17.4 A 3.076 3.076 0 0 0 155.321 17.999 Q 155.391 18.351 155.55 18.624 A 1.629 1.629 0 0 0 155.774 18.923 A 1.652 1.652 0 0 0 156.597 19.39 Q 156.858 19.455 157.168 19.459 A 3.16 3.16 0 0 0 157.209 19.459 A 3.302 3.302 0 0 0 158.143 19.332 A 2.699 2.699 0 0 0 159.036 18.879 Z" id="7" vectorEffect="non-scaling-stroke"/>
		<path d="M 173.652 7.221 L 178.002 7.221 L 170.955 23.78 A 12.856 12.856 0 0 1 170.235 25.31 Q 169.388 26.821 168.302 27.579 A 6.082 6.082 0 0 1 165.695 28.594 A 8.049 8.049 0 0 1 164.488 28.681 Q 163.27 28.681 162.081 28.304 Q 160.924 27.937 160.178 27.295 A 4.032 4.032 0 0 1 160.138 27.26 L 161.791 24.041 Q 162.313 24.505 162.995 24.766 A 3.919 3.919 0 0 0 163.922 25.001 A 3.467 3.467 0 0 0 164.343 25.027 A 3.128 3.128 0 0 0 164.99 24.964 Q 165.411 24.875 165.735 24.661 A 1.899 1.899 0 0 0 165.851 24.578 Q 166.431 24.128 166.895 23.084 L 166.953 22.939 L 160.196 7.221 L 164.865 7.221 L 169.244 17.806 L 173.652 7.221 Z" id="8" vectorEffect="non-scaling-stroke"/>
	</g>
</svg>


                    <svg width="179.16" height="23.171" id="cc1" className="position_box" viewBox="0 0 179.16 23.171" xmlns="http://www.w3.org/2000/svg">
                    <g id="svgGroup" strokeLinecap="round" fillRule="evenodd" fontSize="9pt" stroke="#000" strokeWidth="0.25mm" fill="none" style={{stroke:"#000",strokeWidth:"0.25mm",fill:"none"}}>
		<path d="M 19.372 5.771 L 16.356 8.555 A 6.981 6.981 0 0 0 14.214 6.851 A 6.506 6.506 0 0 0 11.252 6.177 A 7.344 7.344 0 0 0 9.26 6.439 A 6.28 6.28 0 0 0 7.888 7.004 Q 6.409 7.83 5.583 9.309 A 6.524 6.524 0 0 0 4.796 11.868 A 8.038 8.038 0 0 0 4.756 12.673 A 7.344 7.344 0 0 0 5.018 14.665 A 6.28 6.28 0 0 0 5.583 16.037 Q 6.409 17.516 7.888 18.343 A 6.524 6.524 0 0 0 10.447 19.13 A 8.038 8.038 0 0 0 11.252 19.169 A 6.383 6.383 0 0 0 16.029 17.124 A 8.308 8.308 0 0 0 16.356 16.762 L 19.372 19.546 Q 17.922 21.315 15.776 22.243 A 11.324 11.324 0 0 1 12.437 23.098 A 13.918 13.918 0 0 1 10.991 23.171 A 12.303 12.303 0 0 1 7.345 22.644 A 10.972 10.972 0 0 1 5.38 21.823 Q 2.871 20.474 1.436 18.082 A 10.145 10.145 0 0 1 0.017 13.325 A 12.263 12.263 0 0 1 0 12.673 A 10.853 10.853 0 0 1 0.547 9.188 A 9.816 9.816 0 0 1 1.436 7.265 Q 2.871 4.872 5.38 3.524 Q 7.888 2.175 11.02 2.175 A 12.944 12.944 0 0 1 13.829 2.469 A 10.715 10.715 0 0 1 15.791 3.103 Q 17.922 4.031 19.372 5.771 Z" id="0" vectorEffect="non-scaling-stroke"/>
		<path d="M 26.475 22.729 A 10.139 10.139 0 0 0 29.087 23.055 A 10.994 10.994 0 0 0 30.214 22.999 A 8.88 8.88 0 0 0 33.495 22.026 Q 35.438 20.996 36.54 19.169 A 7.455 7.455 0 0 0 37.254 17.603 A 8.356 8.356 0 0 0 37.642 15.022 A 9.396 9.396 0 0 0 37.625 14.445 A 7.721 7.721 0 0 0 36.54 10.875 Q 35.438 9.048 33.495 8.019 A 8.526 8.526 0 0 0 31.661 7.306 A 10.105 10.105 0 0 0 29.087 6.989 A 11.066 11.066 0 0 0 28.033 7.038 A 9.003 9.003 0 0 0 24.665 8.019 Q 22.707 9.048 21.605 10.875 A 7.455 7.455 0 0 0 20.892 12.441 A 8.356 8.356 0 0 0 20.503 15.022 A 9.396 9.396 0 0 0 20.521 15.599 A 7.721 7.721 0 0 0 21.605 19.169 Q 22.707 20.996 24.665 22.026 A 8.643 8.643 0 0 0 26.475 22.729 Z M 29.087 19.343 A 4.207 4.207 0 0 0 30.513 19.11 A 3.651 3.651 0 0 0 31.944 18.169 A 3.95 3.95 0 0 0 32.94 16.23 A 5.765 5.765 0 0 0 33.06 15.022 A 5.646 5.646 0 0 0 32.91 13.681 A 3.916 3.916 0 0 0 31.944 11.876 A 3.725 3.725 0 0 0 29.302 10.706 A 4.988 4.988 0 0 0 29.087 10.701 Q 27.347 10.701 26.216 11.876 A 3.946 3.946 0 0 0 25.196 13.866 A 5.74 5.74 0 0 0 25.085 15.022 A 5.565 5.565 0 0 0 25.241 16.376 A 3.897 3.897 0 0 0 26.216 18.169 A 3.783 3.783 0 0 0 28.926 19.341 A 5.032 5.032 0 0 0 29.087 19.343 Z" id="1" vectorEffect="non-scaling-stroke"/>
		<path d="M 56.608 13.891 L 56.608 22.823 L 52.084 22.823 L 52.084 14.587 A 6.756 6.756 0 0 0 52.013 13.568 Q 51.933 13.049 51.766 12.632 A 2.763 2.763 0 0 0 51.272 11.818 A 2.62 2.62 0 0 0 49.904 11.015 Q 49.486 10.911 48.99 10.905 A 5.094 5.094 0 0 0 48.923 10.904 A 4.479 4.479 0 0 0 47.738 11.053 A 3.3 3.3 0 0 0 46.197 11.963 A 3.334 3.334 0 0 0 45.452 13.23 Q 45.277 13.75 45.216 14.386 A 7.514 7.514 0 0 0 45.182 15.109 L 45.182 22.823 L 40.658 22.823 L 40.658 7.221 L 44.979 7.221 L 44.979 9.048 A 5.914 5.914 0 0 1 46.926 7.648 A 6.953 6.953 0 0 1 47.212 7.526 A 7.49 7.49 0 0 1 49.408 7.019 A 8.973 8.973 0 0 1 50.141 6.989 A 7.858 7.858 0 0 1 52.237 7.255 A 5.883 5.883 0 0 1 54.825 8.729 Q 56.347 10.214 56.57 12.925 A 11.793 11.793 0 0 1 56.608 13.891 Z" id="2" vectorEffect="non-scaling-stroke"/>
		<path d="M 69.542 18.879 L 70.76 22.069 A 4.109 4.109 0 0 1 69.914 22.546 A 5.516 5.516 0 0 1 69.122 22.809 A 8.174 8.174 0 0 1 67.344 23.051 A 9.18 9.18 0 0 1 67.077 23.055 Q 64.293 23.055 62.771 21.634 A 4.535 4.535 0 0 1 61.519 19.545 Q 61.248 18.62 61.248 17.458 L 61.248 11.049 L 58.841 11.049 L 58.841 7.569 L 61.248 7.569 L 61.248 3.77 L 65.772 3.77 L 65.772 7.569 L 69.658 7.569 L 69.658 11.049 L 65.772 11.049 L 65.772 17.4 A 3.076 3.076 0 0 0 65.827 17.999 Q 65.897 18.351 66.056 18.624 A 1.629 1.629 0 0 0 66.28 18.923 A 1.652 1.652 0 0 0 67.103 19.39 Q 67.364 19.455 67.674 19.459 A 3.16 3.16 0 0 0 67.715 19.459 A 3.302 3.302 0 0 0 68.649 19.332 A 2.699 2.699 0 0 0 69.542 18.879 Z" id="3" vectorEffect="non-scaling-stroke"/>
		<path d="M 82.998 6.989 L 82.998 11.165 Q 82.244 11.107 81.983 11.107 Q 80.259 11.107 79.198 11.963 A 3.465 3.465 0 0 0 78.938 12.195 Q 78.014 13.106 77.865 14.783 A 7.608 7.608 0 0 0 77.836 15.457 L 77.836 22.823 L 73.312 22.823 L 73.312 7.221 L 77.633 7.221 L 77.633 9.28 A 4.967 4.967 0 0 1 79.57 7.683 A 5.973 5.973 0 0 1 79.823 7.569 A 7.231 7.231 0 0 1 81.556 7.092 A 9.66 9.66 0 0 1 82.998 6.989 Z" id="4" vectorEffect="non-scaling-stroke"/>
		<path d="M 85.695 22.823 L 85.695 7.221 L 90.219 7.221 L 90.219 22.823 L 85.695 22.823 Z M 87.037 4.931 A 3.497 3.497 0 0 0 87.957 5.046 A 3.708 3.708 0 0 0 88.521 5.005 A 2.671 2.671 0 0 0 89.987 4.307 A 2.433 2.433 0 0 0 90.598 3.41 A 2.675 2.675 0 0 0 90.77 2.436 A 2.332 2.332 0 0 0 90.61 1.561 A 2.294 2.294 0 0 0 89.987 0.696 A 2.656 2.656 0 0 0 88.861 0.106 A 3.648 3.648 0 0 0 87.957 0 A 3.741 3.741 0 0 0 87.345 0.048 A 2.681 2.681 0 0 0 85.927 0.725 A 2.466 2.466 0 0 0 85.405 1.4 A 2.403 2.403 0 0 0 85.144 2.523 A 2.876 2.876 0 0 0 85.146 2.623 A 2.342 2.342 0 0 0 85.927 4.321 A 2.619 2.619 0 0 0 87.037 4.931 Z" id="5" vectorEffect="non-scaling-stroke"/>
		<path d="M 98.745 21.025 L 98.745 22.823 L 94.424 22.823 L 94.424 1.305 L 98.948 1.305 L 98.948 8.874 A 5.626 5.626 0 0 1 102.274 7.1 A 8.161 8.161 0 0 1 103.646 6.989 A 8.168 8.168 0 0 1 106.571 7.506 A 7.695 7.695 0 0 1 107.605 7.99 Q 109.388 8.99 110.403 10.803 A 7.909 7.909 0 0 1 111.319 13.576 A 10.226 10.226 0 0 1 111.418 15.022 A 9.658 9.658 0 0 1 111.172 17.247 A 7.666 7.666 0 0 1 110.403 19.242 Q 109.388 21.054 107.605 22.055 Q 105.821 23.055 103.646 23.055 Q 100.427 23.055 98.745 21.025 Z M 102.863 19.343 A 4.207 4.207 0 0 0 104.289 19.11 A 3.651 3.651 0 0 0 105.72 18.169 A 3.95 3.95 0 0 0 106.716 16.23 A 5.765 5.765 0 0 0 106.836 15.022 A 5.646 5.646 0 0 0 106.686 13.681 A 3.916 3.916 0 0 0 105.72 11.876 A 3.725 3.725 0 0 0 103.078 10.706 A 4.988 4.988 0 0 0 102.863 10.701 A 4.207 4.207 0 0 0 101.437 10.934 A 3.651 3.651 0 0 0 100.007 11.876 A 3.95 3.95 0 0 0 99.011 13.814 A 5.765 5.765 0 0 0 98.89 15.022 A 5.646 5.646 0 0 0 99.041 16.363 A 3.916 3.916 0 0 0 100.007 18.169 A 3.725 3.725 0 0 0 102.648 19.339 A 4.988 4.988 0 0 0 102.863 19.343 Z" id="6" vectorEffect="non-scaling-stroke"/>
		<path d="M 125.628 7.221 L 130.152 7.221 L 130.152 22.823 L 125.86 22.823 L 125.86 20.967 Q 124.961 21.982 123.714 22.519 A 6.69 6.69 0 0 1 121.243 23.052 A 7.712 7.712 0 0 1 121.017 23.055 A 8.647 8.647 0 0 1 118.973 22.827 A 5.946 5.946 0 0 1 116.145 21.286 Q 114.602 19.767 114.383 16.988 A 12.141 12.141 0 0 1 114.347 16.037 L 114.347 7.221 L 118.871 7.221 L 118.871 15.37 Q 118.871 18.7 121.338 19.089 A 4.465 4.465 0 0 0 122.032 19.14 A 4.038 4.038 0 0 0 123.225 18.972 A 3.188 3.188 0 0 0 124.642 18.082 A 3.334 3.334 0 0 0 125.35 16.863 Q 125.524 16.351 125.589 15.726 A 7.632 7.632 0 0 0 125.628 14.935 L 125.628 7.221 Z" id="7" vectorEffect="non-scaling-stroke"/>
		<path d="M 143.202 18.879 L 144.42 22.069 A 4.109 4.109 0 0 1 143.574 22.546 A 5.516 5.516 0 0 1 142.782 22.809 A 8.174 8.174 0 0 1 141.004 23.051 A 9.18 9.18 0 0 1 140.737 23.055 Q 137.953 23.055 136.431 21.634 A 4.535 4.535 0 0 1 135.179 19.545 Q 134.908 18.62 134.908 17.458 L 134.908 11.049 L 132.501 11.049 L 132.501 7.569 L 134.908 7.569 L 134.908 3.77 L 139.432 3.77 L 139.432 7.569 L 143.318 7.569 L 143.318 11.049 L 139.432 11.049 L 139.432 17.4 A 3.076 3.076 0 0 0 139.487 17.999 Q 139.557 18.351 139.716 18.624 A 1.629 1.629 0 0 0 139.94 18.923 A 1.652 1.652 0 0 0 140.763 19.39 Q 141.024 19.455 141.334 19.459 A 3.16 3.16 0 0 0 141.375 19.459 A 3.302 3.302 0 0 0 142.309 19.332 A 2.699 2.699 0 0 0 143.202 18.879 Z" id="8" vectorEffect="non-scaling-stroke"/>
		<path d="M 151.291 22.729 A 10.139 10.139 0 0 0 153.903 23.055 A 10.994 10.994 0 0 0 155.03 22.999 A 8.88 8.88 0 0 0 158.311 22.026 Q 160.254 20.996 161.356 19.169 A 7.455 7.455 0 0 0 162.07 17.603 A 8.356 8.356 0 0 0 162.458 15.022 A 9.396 9.396 0 0 0 162.441 14.445 A 7.721 7.721 0 0 0 161.356 10.875 Q 160.254 9.048 158.311 8.019 A 8.526 8.526 0 0 0 156.477 7.306 A 10.105 10.105 0 0 0 153.903 6.989 A 11.066 11.066 0 0 0 152.849 7.038 A 9.003 9.003 0 0 0 149.481 8.019 Q 147.523 9.048 146.421 10.875 A 7.455 7.455 0 0 0 145.708 12.441 A 8.356 8.356 0 0 0 145.319 15.022 A 9.396 9.396 0 0 0 145.337 15.599 A 7.721 7.721 0 0 0 146.421 19.169 Q 147.523 20.996 149.481 22.026 A 8.643 8.643 0 0 0 151.291 22.729 Z M 153.903 19.343 A 4.207 4.207 0 0 0 155.329 19.11 A 3.651 3.651 0 0 0 156.76 18.169 A 3.95 3.95 0 0 0 157.756 16.23 A 5.765 5.765 0 0 0 157.876 15.022 A 5.646 5.646 0 0 0 157.726 13.681 A 3.916 3.916 0 0 0 156.76 11.876 A 3.725 3.725 0 0 0 154.118 10.706 A 4.988 4.988 0 0 0 153.903 10.701 Q 152.163 10.701 151.032 11.876 A 3.946 3.946 0 0 0 150.012 13.866 A 5.74 5.74 0 0 0 149.901 15.022 A 5.565 5.565 0 0 0 150.057 16.376 A 3.897 3.897 0 0 0 151.032 18.169 A 3.783 3.783 0 0 0 153.742 19.341 A 5.032 5.032 0 0 0 153.903 19.343 Z" id="9" vectorEffect="non-scaling-stroke"/>
		<path d="M 175.16 6.989 L 175.16 11.165 Q 174.406 11.107 174.145 11.107 Q 172.421 11.107 171.36 11.963 A 3.465 3.465 0 0 0 171.1 12.195 Q 170.176 13.106 170.027 14.783 A 7.608 7.608 0 0 0 169.998 15.457 L 169.998 22.823 L 165.474 22.823 L 165.474 7.221 L 169.795 7.221 L 169.795 9.28 A 4.967 4.967 0 0 1 171.732 7.683 A 5.973 5.973 0 0 1 171.985 7.569 A 7.231 7.231 0 0 1 173.718 7.092 A 9.66 9.66 0 0 1 175.16 6.989 Z" id="10" vectorEffect="non-scaling-stroke"/>
	</g>
</svg>

                    <svg width="224.373" height="28.449" id="ps" className="position_box" viewBox="0 0 224.373 28.449" xmlns="http://www.w3.org/2000/svg">
                    <g id="svgGroup" strokeLinecap="round" fillRule="evenodd" fontSize="9pt" stroke="#000" strokeWidth="0.25mm" fill="none" style={{stroke:"#000",strokeWidth:"0.25mm",fill:"none"}}>
		<path d="M 8.787 17.226 L 4.698 17.226 L 4.698 22.823 L 0 22.823 L 0 2.523 L 8.787 2.523 Q 11.437 2.523 13.4 3.391 A 8.584 8.584 0 0 1 13.471 3.422 Q 15.457 4.321 16.53 5.974 A 6.62 6.62 0 0 1 17.523 8.698 A 8.588 8.588 0 0 1 17.603 9.889 A 7.841 7.841 0 0 1 17.326 12.011 A 6.511 6.511 0 0 1 16.53 13.79 A 6.66 6.66 0 0 1 14.069 16.046 A 8.249 8.249 0 0 1 13.47 16.342 Q 11.484 17.226 8.787 17.226 Z M 8.526 6.351 L 4.698 6.351 L 4.698 13.398 L 8.526 13.398 A 7.853 7.853 0 0 0 9.736 13.311 Q 10.359 13.214 10.862 13.007 A 3.399 3.399 0 0 0 11.745 12.485 A 2.97 2.97 0 0 0 12.786 10.635 A 4.401 4.401 0 0 0 12.847 9.889 Q 12.847 8.178 11.745 7.265 A 3.546 3.546 0 0 0 10.586 6.64 Q 9.717 6.351 8.526 6.351 Z" id="0" vectorEffect="non-scaling-stroke"/>
		<path d="M 32.103 7.221 L 36.627 7.221 L 36.627 22.823 L 32.335 22.823 L 32.335 20.967 Q 31.436 21.982 30.189 22.519 A 6.69 6.69 0 0 1 27.717 23.052 A 7.712 7.712 0 0 1 27.492 23.055 A 8.647 8.647 0 0 1 25.447 22.827 A 5.946 5.946 0 0 1 22.62 21.286 Q 21.076 19.767 20.858 16.988 A 12.141 12.141 0 0 1 20.822 16.037 L 20.822 7.221 L 25.346 7.221 L 25.346 15.37 Q 25.346 18.7 27.812 19.089 A 4.465 4.465 0 0 0 28.507 19.14 A 4.038 4.038 0 0 0 29.7 18.972 A 3.188 3.188 0 0 0 31.117 18.082 A 3.334 3.334 0 0 0 31.825 16.863 Q 31.999 16.351 32.064 15.726 A 7.632 7.632 0 0 0 32.103 14.935 L 32.103 7.221 Z" id="1" vectorEffect="non-scaling-stroke"/>
		<path d="M 45.153 21.025 L 45.153 22.823 L 40.832 22.823 L 40.832 1.305 L 45.356 1.305 L 45.356 8.874 A 5.626 5.626 0 0 1 48.682 7.1 A 8.161 8.161 0 0 1 50.054 6.989 A 8.168 8.168 0 0 1 52.978 7.506 A 7.695 7.695 0 0 1 54.012 7.99 Q 55.796 8.99 56.811 10.803 A 7.909 7.909 0 0 1 57.727 13.576 A 10.226 10.226 0 0 1 57.826 15.022 A 9.658 9.658 0 0 1 57.58 17.247 A 7.666 7.666 0 0 1 56.811 19.242 Q 55.796 21.054 54.013 22.055 Q 52.229 23.055 50.054 23.055 Q 46.835 23.055 45.153 21.025 Z M 49.271 19.343 A 4.207 4.207 0 0 0 50.697 19.11 A 3.651 3.651 0 0 0 52.128 18.169 A 3.95 3.95 0 0 0 53.124 16.23 A 5.765 5.765 0 0 0 53.244 15.022 A 5.646 5.646 0 0 0 53.093 13.681 A 3.916 3.916 0 0 0 52.128 11.876 A 3.725 3.725 0 0 0 49.486 10.706 A 4.988 4.988 0 0 0 49.271 10.701 A 4.207 4.207 0 0 0 47.845 10.934 A 3.651 3.651 0 0 0 46.414 11.876 A 3.95 3.95 0 0 0 45.418 13.814 A 5.765 5.765 0 0 0 45.298 15.022 A 5.646 5.646 0 0 0 45.449 16.363 A 3.916 3.916 0 0 0 46.414 18.169 A 3.725 3.725 0 0 0 49.056 19.339 A 4.988 4.988 0 0 0 49.271 19.343 Z" id="2" vectorEffect="non-scaling-stroke"/>
		<path d="M 60.842 22.823 L 60.842 1.305 L 65.366 1.305 L 65.366 22.823 L 60.842 22.823 Z" id="3" vectorEffect="non-scaling-stroke"/>
		<path d="M 69.571 22.823 L 69.571 7.221 L 74.095 7.221 L 74.095 22.823 L 69.571 22.823 Z M 70.913 4.931 A 3.497 3.497 0 0 0 71.833 5.046 A 3.708 3.708 0 0 0 72.396 5.005 A 2.671 2.671 0 0 0 73.863 4.307 A 2.433 2.433 0 0 0 74.474 3.41 A 2.675 2.675 0 0 0 74.646 2.436 A 2.332 2.332 0 0 0 74.485 1.561 A 2.294 2.294 0 0 0 73.863 0.696 A 2.656 2.656 0 0 0 72.736 0.106 A 3.648 3.648 0 0 0 71.833 0 A 3.741 3.741 0 0 0 71.221 0.048 A 2.681 2.681 0 0 0 69.803 0.725 A 2.466 2.466 0 0 0 69.281 1.4 A 2.403 2.403 0 0 0 69.02 2.523 A 2.876 2.876 0 0 0 69.022 2.623 A 2.342 2.342 0 0 0 69.803 4.321 A 2.619 2.619 0 0 0 70.913 4.931 Z" id="4" vectorEffect="non-scaling-stroke"/>
		<path d="M 92.829 10.962 L 89.32 12.847 A 4.793 4.793 0 0 0 88.405 11.67 A 3.693 3.693 0 0 0 85.811 10.701 A 4.423 4.423 0 0 0 84.333 10.939 A 3.832 3.832 0 0 0 82.882 11.861 Q 81.722 13.021 81.722 15.022 A 5.572 5.572 0 0 0 81.872 16.352 A 3.82 3.82 0 0 0 82.882 18.183 A 3.914 3.914 0 0 0 85.538 19.336 A 5.187 5.187 0 0 0 85.811 19.343 A 3.931 3.931 0 0 0 87.567 18.961 Q 88.4 18.552 89.001 17.706 A 5.369 5.369 0 0 0 89.32 17.197 L 92.829 19.111 Q 91.988 20.967 90.147 22.011 A 7.95 7.95 0 0 1 87.391 22.943 A 10.342 10.342 0 0 1 85.84 23.055 A 10.394 10.394 0 0 1 83.195 22.729 A 8.858 8.858 0 0 1 81.359 22.026 Q 79.373 20.996 78.257 19.169 A 7.65 7.65 0 0 1 77.157 15.594 A 9.277 9.277 0 0 1 77.14 15.022 A 8.237 8.237 0 0 1 77.549 12.393 A 7.409 7.409 0 0 1 78.257 10.875 Q 79.373 9.048 81.36 8.019 A 9.229 9.229 0 0 1 84.764 7.039 A 11.33 11.33 0 0 1 85.84 6.989 A 10.01 10.01 0 0 1 88.014 7.215 A 7.816 7.816 0 0 1 90.147 8.019 Q 91.988 9.048 92.829 10.962 Z" id="5" vectorEffect="non-scaling-stroke"/>
		<path d="M 102.37 20.822 L 103.965 17.284 A 9.91 9.91 0 0 0 105.776 18.327 A 12.616 12.616 0 0 0 107.068 18.821 Q 108.866 19.401 110.664 19.401 A 10.741 10.741 0 0 0 111.707 19.354 Q 112.752 19.252 113.406 18.927 A 2.615 2.615 0 0 0 113.622 18.807 A 2.374 2.374 0 0 0 114.142 18.38 A 1.672 1.672 0 0 0 114.579 17.226 Q 114.579 16.501 114.014 16.023 A 3.633 3.633 0 0 0 113.346 15.579 Q 112.992 15.395 112.564 15.254 A 16.666 16.666 0 0 0 111.95 15.067 Q 111.257 14.869 110.307 14.648 A 55.654 55.654 0 0 0 110.171 14.616 A 48.317 48.317 0 0 1 108.612 14.22 Q 107.885 14.022 107.267 13.823 A 20.785 20.785 0 0 1 106.372 13.514 Q 104.893 12.963 103.835 11.745 Q 102.802 10.557 102.777 8.596 A 7.675 7.675 0 0 1 102.776 8.497 Q 102.776 6.728 103.733 5.293 Q 104.69 3.857 106.619 3.016 Q 108.547 2.175 111.331 2.175 Q 113.274 2.175 115.13 2.639 A 12.504 12.504 0 0 1 117.021 3.268 A 9.927 9.927 0 0 1 118.378 3.973 L 116.928 7.54 Q 114.115 5.945 111.302 5.945 A 9.726 9.726 0 0 0 110.274 5.996 Q 109.065 6.125 108.388 6.583 A 2.37 2.37 0 0 0 107.834 7.091 A 1.864 1.864 0 0 0 107.445 8.265 A 1.53 1.53 0 0 0 108.05 9.524 A 2.416 2.416 0 0 0 108.533 9.817 Q 109.437 10.239 111.133 10.651 A 35.307 35.307 0 0 0 111.853 10.817 A 48.317 48.317 0 0 1 113.412 11.213 Q 114.139 11.412 114.757 11.61 A 20.785 20.785 0 0 1 115.652 11.919 Q 117.131 12.47 118.19 13.659 A 4.012 4.012 0 0 1 119.073 15.368 Q 119.248 16.053 119.248 16.878 Q 119.248 18.618 118.277 20.054 A 5.793 5.793 0 0 1 116.744 21.574 A 8.082 8.082 0 0 1 115.362 22.33 A 9.556 9.556 0 0 1 113.356 22.934 Q 112.386 23.119 111.271 23.16 A 17.487 17.487 0 0 1 110.635 23.171 Q 108.228 23.171 105.981 22.519 A 14.706 14.706 0 0 1 104.419 21.974 Q 103.633 21.646 102.996 21.253 A 7.603 7.603 0 0 1 102.37 20.822 Z" id="7" vectorEffect="non-scaling-stroke"/>
		<path d="M 126.672 21.17 L 126.672 28.449 L 122.148 28.449 L 122.148 7.221 L 126.469 7.221 L 126.469 9.019 Q 128.151 6.989 131.37 6.989 A 8.168 8.168 0 0 1 134.294 7.506 A 7.695 7.695 0 0 1 135.328 7.99 Q 137.112 8.99 138.127 10.803 A 7.909 7.909 0 0 1 139.043 13.576 A 10.226 10.226 0 0 1 139.142 15.022 A 9.658 9.658 0 0 1 138.896 17.247 A 7.666 7.666 0 0 1 138.127 19.242 Q 137.112 21.054 135.329 22.055 Q 133.545 23.055 131.37 23.055 A 7.611 7.611 0 0 1 129.348 22.801 A 5.508 5.508 0 0 1 126.672 21.17 Z M 130.587 19.343 A 4.207 4.207 0 0 0 132.013 19.11 A 3.651 3.651 0 0 0 133.444 18.169 A 3.95 3.95 0 0 0 134.44 16.23 A 5.765 5.765 0 0 0 134.56 15.022 A 5.646 5.646 0 0 0 134.409 13.681 A 3.916 3.916 0 0 0 133.444 11.876 A 3.725 3.725 0 0 0 130.802 10.706 A 4.988 4.988 0 0 0 130.587 10.701 A 4.207 4.207 0 0 0 129.161 10.934 A 3.651 3.651 0 0 0 127.73 11.876 A 3.95 3.95 0 0 0 126.734 13.814 A 5.765 5.765 0 0 0 126.614 15.022 A 5.646 5.646 0 0 0 126.765 16.363 A 3.916 3.916 0 0 0 127.73 18.169 A 3.725 3.725 0 0 0 130.372 19.339 A 4.988 4.988 0 0 0 130.587 19.343 Z" id="8" vectorEffect="non-scaling-stroke"/>
		<path d="M 157.354 16.298 L 145.551 16.298 Q 145.87 17.748 147.059 18.589 A 4.469 4.469 0 0 0 148.772 19.312 A 6.246 6.246 0 0 0 150.017 19.43 A 6.979 6.979 0 0 0 151.132 19.345 A 5.235 5.235 0 0 0 152.178 19.068 A 5.012 5.012 0 0 0 153.518 18.287 A 6.075 6.075 0 0 0 153.932 17.922 L 156.339 20.532 A 7.088 7.088 0 0 1 152.627 22.743 Q 151.467 23.036 150.086 23.054 A 13.989 13.989 0 0 1 149.901 23.055 Q 147.262 23.055 145.232 22.026 Q 143.202 20.996 142.1 19.169 A 7.721 7.721 0 0 1 141.015 15.599 A 9.396 9.396 0 0 1 140.998 15.022 Q 140.998 12.731 142.086 10.89 Q 143.173 9.048 145.073 8.019 A 8.592 8.592 0 0 1 148.669 7.009 A 10.31 10.31 0 0 1 149.321 6.989 Q 151.612 6.989 153.468 7.975 Q 155.324 8.961 156.383 10.803 A 7.93 7.93 0 0 1 157.355 13.739 A 10.153 10.153 0 0 1 157.441 15.08 Q 157.441 15.162 157.364 16.174 A 356.268 356.268 0 0 1 157.354 16.298 Z M 145.493 13.659 L 153.178 13.659 Q 152.946 12.18 151.902 11.296 A 3.713 3.713 0 0 0 149.765 10.429 A 4.83 4.83 0 0 0 149.35 10.411 A 4.488 4.488 0 0 0 148.079 10.584 A 3.619 3.619 0 0 0 146.769 11.281 A 3.564 3.564 0 0 0 145.603 13.138 A 4.75 4.75 0 0 0 145.493 13.659 Z" id="9" vectorEffect="non-scaling-stroke"/>
		<path d="M 173.826 13.92 L 173.826 22.823 L 169.592 22.823 L 169.592 20.88 Q 168.316 23.055 164.836 23.055 Q 163.07 23.055 161.765 22.467 A 5.627 5.627 0 0 1 161.718 22.446 Q 160.399 21.837 159.703 20.764 A 4.341 4.341 0 0 1 159.01 18.497 A 5.235 5.235 0 0 1 159.007 18.328 A 4.626 4.626 0 0 1 159.272 16.728 A 3.985 3.985 0 0 1 160.645 14.906 Q 162.284 13.659 165.706 13.659 L 169.302 13.659 A 3.887 3.887 0 0 0 169.197 12.728 A 2.572 2.572 0 0 0 168.403 11.383 Q 167.717 10.774 166.509 10.63 A 6.78 6.78 0 0 0 165.706 10.585 Q 164.459 10.585 163.256 10.977 A 7.354 7.354 0 0 0 162.111 11.454 A 5.724 5.724 0 0 0 161.211 12.035 L 159.587 8.874 A 8.834 8.834 0 0 1 161.281 7.95 A 11.51 11.51 0 0 1 162.647 7.482 Q 164.43 6.989 166.315 6.989 A 12.212 12.212 0 0 1 168.465 7.166 Q 170.562 7.542 171.883 8.715 Q 173.617 10.255 173.804 13.193 A 11.497 11.497 0 0 1 173.826 13.92 Z M 169.302 17.893 L 169.302 16.298 L 166.199 16.298 A 6.352 6.352 0 0 0 165.253 16.362 Q 163.415 16.641 163.415 18.125 A 1.784 1.784 0 0 0 163.536 18.795 Q 163.703 19.209 164.097 19.503 A 2.351 2.351 0 0 0 164.847 19.867 Q 165.335 20.01 165.967 20.01 A 4.271 4.271 0 0 0 167.174 19.845 A 3.672 3.672 0 0 0 168.026 19.474 A 2.922 2.922 0 0 0 169.278 17.958 A 3.667 3.667 0 0 0 169.302 17.893 Z" id="10" vectorEffect="non-scaling-stroke"/>
		<path d="M 189.544 22.823 L 184.614 16.704 L 182.439 18.85 L 182.439 22.823 L 177.915 22.823 L 177.915 1.305 L 182.439 1.305 L 182.439 13.485 L 189.051 7.221 L 194.445 7.221 L 187.949 13.833 L 195.025 22.823 L 189.544 22.823 Z" id="11" vectorEffect="non-scaling-stroke"/>
		<path d="M 211.584 16.298 L 199.781 16.298 Q 200.1 17.748 201.289 18.589 A 4.469 4.469 0 0 0 203.002 19.312 A 6.246 6.246 0 0 0 204.247 19.43 A 6.979 6.979 0 0 0 205.362 19.345 A 5.235 5.235 0 0 0 206.408 19.068 A 5.012 5.012 0 0 0 207.748 18.287 A 6.075 6.075 0 0 0 208.162 17.922 L 210.569 20.532 A 7.088 7.088 0 0 1 206.857 22.743 Q 205.697 23.036 204.316 23.054 A 13.989 13.989 0 0 1 204.131 23.055 Q 201.492 23.055 199.462 22.026 Q 197.432 20.996 196.33 19.169 A 7.721 7.721 0 0 1 195.245 15.599 A 9.396 9.396 0 0 1 195.228 15.022 Q 195.228 12.731 196.316 10.89 Q 197.403 9.048 199.303 8.019 A 8.592 8.592 0 0 1 202.899 7.009 A 10.31 10.31 0 0 1 203.551 6.989 Q 205.842 6.989 207.698 7.975 Q 209.554 8.961 210.613 10.803 A 7.93 7.93 0 0 1 211.585 13.739 A 10.153 10.153 0 0 1 211.671 15.08 Q 211.671 15.162 211.594 16.174 A 356.268 356.268 0 0 1 211.584 16.298 Z M 199.723 13.659 L 207.408 13.659 Q 207.176 12.18 206.132 11.296 A 3.713 3.713 0 0 0 203.995 10.429 A 4.83 4.83 0 0 0 203.58 10.411 A 4.488 4.488 0 0 0 202.309 10.584 A 3.619 3.619 0 0 0 200.999 11.281 A 3.564 3.564 0 0 0 199.833 13.138 A 4.75 4.75 0 0 0 199.723 13.659 Z" id="12" vectorEffect="non-scaling-stroke"/>
		<path d="M 224.373 6.989 L 224.373 11.165 Q 223.619 11.107 223.358 11.107 Q 221.634 11.107 220.572 11.963 A 3.465 3.465 0 0 0 220.313 12.195 Q 219.389 13.106 219.24 14.783 A 7.608 7.608 0 0 0 219.211 15.457 L 219.211 22.823 L 214.687 22.823 L 214.687 7.221 L 219.008 7.221 L 219.008 9.28 A 4.967 4.967 0 0 1 220.944 7.683 A 5.973 5.973 0 0 1 221.198 7.569 A 7.231 7.231 0 0 1 222.931 7.092 A 9.66 9.66 0 0 1 224.373 6.989 Z" id="13" vectorEffect="non-scaling-stroke"/>
	</g>
</svg>
                        </>
                    )}

                    



                    
                    
        </div>
        
        </>
        
    )
}

export default HomeInfo

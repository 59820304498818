import React from 'react'
import "./Footer.css"

const Footer = () => {
    return (
        <div className="footer_container">
            <div className="link_container">
                <div className="footer_text">Connect with Me!!</div>
                <div title="Resume" className='mouse-effect'><a aria-label="Resume" title="Resume" target="_blank"  rel="nofollow noreferrer" href="https://drive.google.com/file/d/1cu2gUbW4DCLlnRhyHWHhXf2JkLDCCkaE/view?usp=sharing"><i className="fas fa-file-alt icon"></i></a></div>
                <div className='mouse-effect'><a aria-label="Mail" title="Mail" target="_blank" rel="nofollow noreferrer" href="mailto:jaypatel32157@gmail.com"><i className="fas fa-envelope icon"></i></a></div>
                <div className='mouse-effect'><a aria-label="Instagram" title="Instagram" target="_blank" rel="nofollow noreferrer" href="https://www.instagram.com/jaypatel98196/"><i className="fab fa-instagram icon"></i></a></div>
                <div className='mouse-effect'><a aria-label="Twitter" title="Twitter" target="_blank" rel="nofollow noreferrer" href="https://twitter.com/jaypate83597666"><i className="fab fa-twitter icon"></i></a></div>
                <div className='mouse-effect'><a aria-label="LinkedIn" title="LinkedIn" target="_blank" rel="nofollow noreferrer" href="https://www.linkedin.com/in/--jaypatel--/"><i className="fab fa-linkedin icon"></i></a></div>
                <div className='mouse-effect'><a aria-label="GitHub" title="GitHub" target="_blank" rel="nofollow noreferrer" href="https://github.com/jaypatel31"><i className="fab fa-github icon"></i></a></div>
            </div>
        </div>
    )
}

export default Footer

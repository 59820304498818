import React,{useState,useEffect} from 'react'
import axios from "axios"
import {useLocation} from "react-router-dom"
import "./sideinfobar.css"
import {skills} from "../../data/skills"
import {experience} from "../../data/experience"
import {projects} from "../../data/projects"
import {education} from "../../data/education"

const SideInfoBar = ({sliderState,setSliderState,insideDate, setInsideDate,setCurrentData,currentData,setPallateStatus}) => {
    const [currentRoute, setCurrentRoute] = useState("/")
    
    const [activeTab, setActiveTab] = useState()

    const [lineSelect, setLineSelect] = useState(0)
    const [quote, setQuote] = useState("");

    const location = useLocation();
    let path = location.pathname
    useEffect(() => {
        setPallateStatus(false);
        setCurrentRoute(path);
        if(path==="/skills") setCurrentData(skills);
        if(path==="/experience") setCurrentData(experience);
        if(path==="/projects") setCurrentData(projects);
        if(path==="/education") setCurrentData(education);
        setLineSelect(0);
    }, [path])

    useEffect(() => {
        if(currentData) setActiveTab(currentData[0].data[0].name)
    }, [currentData])

    useEffect(() => {
        if(activeTab && currentData[lineSelect]){
            let dt = currentData[lineSelect].data.filter(dt=>{
                if(dt.name==activeTab){
                    return dt
                }
            })
            setInsideDate(dt[0])
        }
    }, [activeTab])

    useEffect(() => {
        let getData=async()=>{
            let data =  await axios.get("https://api.quotable.io/random?maxLength=50")
            setQuote(data.data);
        }
        if(currentRoute==="/"){
            getData()
        }
    }, [currentRoute])

    return (
        <>
        <div className="sideinfobar_container" style={(!sliderState)?{left:"-233px"}:{left:"48px"}} onClick={()=>setPallateStatus(false)}>
        <div className="title">{(currentRoute!="/")?currentRoute.replace("/","").toUpperCase():"HOME"}</div>
        {
            (currentRoute==="/")?(
            <>
                
                <div className="para">Hello, <strong>Dear User</strong> Welcome to my portfolio website. I hope you enjoy your stay while navigating.</div>
                <div className="para">I am Patel Jay a Full Stack Web Developer who loves to create software that helps the communities.</div>
                <div className="para">I describe myself as a enthusiastic learner so you can always find me doing AI/ML, Open Source, Research work, Community Contribution, etc.</div>
                <div className="para quote">{quote!==""?quote.content:""}<div className="authour">-{quote!==""?quote.author:""}</div></div>
            </>
            ):((currentRoute==="/contact")?(
            <>
                <div className="para">Hope, you enjoy the tour of the website.</div>
                <div className="para">Feel free to connect with me either by filling the form or shooting an email to me.</div>
                <div className="para"><strong>Happy Learning !!!</strong></div> 
            </>
            ):(<>
                {
                    currentData && currentData.map((skill,index)=>{
                        return(
                            <details className="summary tree-nav__item is-expandable" key={index} onClick={()=>{setLineSelect(index)}}>
                                <summary className="tree-nav__item-title">{skill.name}</summary>
                                <div className="menu">
                                    <ul>
                                        {
                                            skill.data.map((sk,ind)=>{
                                                return(
                                                    <li key={ind} onClick={()=>{setActiveTab(sk.name)}} className={`${(activeTab==sk.name)?"menu_active":""}`}><i className={`${sk.icon} code_icon`} style={{color:sk.color}}></i> <span>{sk.name}</span></li>
                                                )
                                            })
                                        }
                                    </ul>
                                </div>
                            </details>
                        )
                    })
                }
                
                </>)
            )
        }
        
            
        </div>
        <div className="sidebar_opener" onClick={()=>setSliderState(prevState => !prevState)}><i className={`fas fa-chevron-${(!sliderState)?"right":"left"}`}></i></div>
        </>
    )
}

export default SideInfoBar

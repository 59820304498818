import React from 'react'
import "./experience.css";
import Loader from '../loader/Loader'
import img from "../../assets/img/exp.svg"
import { Helmet } from 'react-helmet';

const Experience = ({loading,setLoading,insideDate,setPallateStatus}) => {
    return (
        <div id="experience_container"  onClick={()=>setPallateStatus(false)}>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Jay Patel - Experience</title>
                <link rel="canonical" href="https://pateljay.me/#/experience" />
                <meta name="description" content="All the Professonal Experience possesed by Jay Patel right from the Internships to Community Engagement."/>
            </Helmet>
            <img src={img} alt="Experience Page Icon" style={{width:"250px",position:"absolute",right:"5%",top:"10%"}}/>
           {(insideDate && !insideDate.data.length)?(
               <>
               <div className="experience_heading">
                   <span className="number_system">1</span>
                   <div className="company_name">{insideDate.data.name}</div>
                </div>
               <div className="experience_info_container">
                    <div className="experience_line_container">
                        <span className="number_system">2</span>
                        <div className="experience_line">
                            <div><span className="var">const</span> <span className="var_name">Position</span> <span>=</span> <span className="string">"{insideDate.data.position}"</span>;</div>
                        </div>
                    </div>
                    <div className="experience_line_container">
                        <span className="number_system">3</span>
                        <div className="experience_line">
                            <div><span className="var">const</span> <span className="var_name">From</span> = <span className="var">new</span> <span className="inbuilt_func">Date</span> (<span className="string">"{insideDate.data.from}"</span>);</div>
                        </div>
                    </div>
                    <div className="experience_line_container" style={{marginBottom:"0px"}}>
                        <span className="number_system">4</span>
                        <div className="experience_line">
                            <div><span className="var">const</span> <span className="custom_func">responsibilites</span> = () <span className="var">=&gt;</span> {'{'}</div>
                        </div>
                    </div>
                    <div className="responsibilites">
                        <ul>
                        {insideDate.data.responsibilites}
                        </ul>
                    </div>
                    <div className="experience_line_container">
                        <span className="number_system">{document.getElementsByClassName('li_line').length+5}</span>
                        <div className="experience_line">
                            <div>{'}'}</div>
                        </div>
                    </div>
               </div>
               </>
           ):<Loader/>} 
        </div>
        
    )
}

export default Experience

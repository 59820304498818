import React from 'react'
import "./project.css"
import Loader from '../loader/Loader'
import img from "../../assets/img/project.svg"
import Popup from "reactjs-popup";
import { Helmet } from 'react-helmet';

const Project = ({loading,setLoading,insideDate,setPallateStatus}) => {
    return (
        <div id="projects_container" onClick={()=>setPallateStatus(false)}>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Jay Patel - Projects</title>
                <link rel="canonical" href="https://pateljay.me/#/projects" />
                <meta name="description" content="All the project completed by Jay Patel in the various domains like Frontend, Backend, Research Works, etc." />
            </Helmet>
            <img src={img} alt="Project Page Icon" style={{width:"200px",position:"absolute",right:"5%",bottom:"5%"}}/>
            {(insideDate && !insideDate.data.length)?(
               <>
                <div className="project_heading">
                   <span className="number_system">1</span>
                   <div className="project_name">{insideDate.name.replace("-"," ").slice(0,insideDate.name.indexOf("."))}</div>
                </div>
                <div className="projects_info_container">
                    <div className="project_line_container" style={{marginBottom:"0px"}}>
                        <span className="number_system">2</span>
                        <div className="project_line">
                            <div><span className='tag_symbol'>{'<'}</span><span className="html_tag">p</span><span className='tag_symbol'>{'>'}</span></div>
                        </div>
                    </div>
                    <div className="project_description">
                        <span className="number_system">3</span>
                        <div style={{marginBottom:"0px"}}>
                            {insideDate.data.description}
                        </div>
                        <Popup
                            trigger={insideDate.data.imgLink}
                            modal
                        >
                            {close => (
                            <div className="modal">
                                <button className="close" onClick={close}>
                                    &times;
                                </button>
                                
                                {insideDate.data.imgLgLink}
                            </div>
                            )}
                        </Popup>

                        
                    </div>
                    <div className="project_line_container">
                        <span className="number_system">4</span>
                        <div className="project_line">
                            <div><span className='tag_symbol'>{'</'}</span><span className="html_tag">p</span><span className='tag_symbol'>{'>'}</span></div>
                        </div>
                    </div>
                    <div className="project_line_container">
                        <span className="number_system">5</span>
                        <div className="project_line">
                            <div >
                                <span className='tag_symbol'>{'<'}</span><span className="html_tag">span</span><span className='tag_symbol'>{'>'}</span>
                                    <span className='tag_highlight'> Tech Stack: </span> <span className='techstack_container'>{insideDate.data.techStack} </span>
                                <span className='tag_symbol'>{'</'}</span><span className="html_tag">span</span><span className='tag_symbol'>{'>'}</span>
                            </div>
                        </div>
                    </div>
                    <div className="project_line_container last-liner" >
                        <span className="number_system">6</span>
                        <div className="project_line">
                            <div >
                                <span className='tag_symbol'>{'<'}</span><span className="html_tag">div</span><span className='tag_symbol'>{'>'}</span>
                                    <span className='tag_highlight'> {insideDate.data.text}: </span> <span className='tag_symbol'>{'<'}</span><span className="html_tag">a</span><span className='tag_symbol'>{'>'}</span> <a href={`${insideDate.data.link}`} rel="nofollow noreferrer" className='string'>{insideDate.data.linkText}</a> <span className='tag_symbol'>{'</'}</span><span className="html_tag">a</span><span className='tag_symbol'>{'>'}</span>
                                <span className='tag_symbol'> {'</'}</span><span className="html_tag">div</span><span className='tag_symbol'>{'>'}</span>
                            </div>
                        </div>
                    </div>
                </div>
               </>
           ):<Loader/>}
        </div>
    )
}

export default Project

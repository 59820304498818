import React from 'react'
import "./loader.css";
import logo from "../../assets/img/logo.png"
const Loader = () => {
    return (
        <div className="loader_container">
            <img src={logo} alt="loader"/>
            <div className="loader_text">Loading...</div>
        </div>
    )
}

export default Loader

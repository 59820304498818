import * as AIFont from "react-icons/ai"
import * as FAFont from "react-icons/fa";
import * as SIFont from "react-icons/si";
import * as FIFont from "react-icons/fi";
import * as DIFont from "react-icons/di";

export const skills = [
    {
        name:"Skills",
        data:[
            {
                icon:"fab fa-js-square",
                color:"#FCDC00",
                name:"Frontend.js",
                data:[
                    {
                        name:"HTML5",
                        color:"#E54C21",
                        value:"90%",
                        icon:<AIFont.AiFillHtml5 size={24} color={"#E54C21"}/>
                    },
                    {
                        name:"CSS3",
                        color:"#214CE5",
                        value:"80%",
                        icon:<FAFont.FaCss3Alt size={24} color={"#214CE5"}/>
                    },
                    {
                        name:"JavaScript",
                        color:"#FCDC00",
                        value:"92%",
                        icon:<SIFont.SiJavascript size={24} color={"#FCDC00"}/>
                    },
                    {
                        name:"TypeScript",
                        color:"#0079CC",
                        value:"70%",
                        icon:<SIFont.SiTypescript size={24} color={"#0079CC"}/>
                    },
                    {
                        name:"JSON",
                        color:"#00FF85",
                        value:"90%",
                        icon:<SIFont.SiJson size={24} color={"#00FF85"}/>
                    }
                ]
            },
            {
                icon:"fab fa-node-js",
                color:"#68a063",
                name:"Backend.js",
                data:[
                    {
                        name:"PHP",
                        color:"#787CB4",
                        value:"95%",
                        icon:<SIFont.SiPhp size={24} color={"#787CB4"}/>
                    },
                    {
                        name:"Python",
                        color:"#3671a1",
                        value:"80%",
                        icon:<SIFont.SiPython size={24} color={"#3671a1"}/>
                    },
                    {
                        name:"Node.js",
                        color:"#68a063",
                        value:"85%",
                        icon:<FAFont.FaNodeJs size={24} color={"#68a063"}/>
                    },
                ]
            },
            {
                icon:"fab fa-react",
                color:"#61DBFB",
                name:"Library.jsx",
                data:[
                    {
                        name:"React.js",
                        color:"#61DBFB",
                        value:"90%",
                        icon:<FAFont.FaReact size={24} color={"#61DBFB"}/>
                    },
                    {
                        name:"Angular",
                        color:"#dd1b16",
                        value:"70%",
                        icon:<SIFont.SiAngular size={24} color={"#dd1b16"}/>
                    },
                    {
                        name:"Three.js",
                        color:"#FFFFFF",
                        value:"70%",
                        icon:<SIFont.SiThreedotjs size={24} color={"#FFFFFF"}/>
                    },
                    {
                        name:"Express.js",
                        color:"#67737c",
                        value:"80%",
                        icon:<SIFont.SiExpress size={24} color={"#67737c"}/>
                    },
                    {
                        name:"jQuery",
                        color:"#0268ae",
                        value:"85%",
                        icon:<SIFont.SiJquery size={24} color={"#0268ae"}/>
                    },
                    {
                        name:"Tailwind",
                        color:"#1ac1ba",
                        value:"80%",
                        icon:<SIFont.SiTailwindcss size={24} color={"#1ac1ba"}/>
                    },
                ]
            },
            {
                icon:"fas fa-database",
                color:"#F98600",
                name:"Database.js",
                data:[
                    {
                        name:"MYSQL",
                        color:"#00758f",
                        value:"95%",
                        icon:<SIFont.SiMysql size={24} color={"#00758f"}/>
                    },
                    {
                        name:"MongoDB",
                        color:"#07ac4f",
                        value:"80%",
                        icon:<SIFont.SiMongodb size={24} color={"#07ac4f"}/>
                    },
                    {
                        name:"PostgreSQL",
                        color:"#336791",
                        value:"60%",
                        icon:<SIFont.SiPostgresql size={24} color={"#336791"}/>
                    },
                ]
            },
            {
                icon:"fas fa-cloud",
                color:"#0066FF",
                name:"Cloud.js",
                data:[
                    {
                        name:"GCP",
                        color:"#4086f4",
                        value:"70%",
                        icon:<SIFont.SiGooglecloud size={24} color={"#4086f4"}/>
                    },
                    {
                        name:"Azure",
                        color:"#2b9ee1",
                        value:"75%",
                        icon:<SIFont.SiMicrosoftazure size={24} color={"#2b9ee1"}/>
                    },
                    {
                        name:"AWS",
                        color:"#ff9a00",
                        value:"60%",
                        icon:<SIFont.SiAmazonaws size={24} color={"#ff9a00"}/>
                    },
                ]
            },
            {
                icon:"fab fa-git",
                color:"#f64d27",
                name:"Tools.js",
                data:[
                    {
                        name:"Git",
                        color:"#f64d27",
                        value:"85%",
                        icon:<DIFont.DiGit size={24} color={"#f64d27"}/>
                    },
                    {
                        name:"GitHub",
                        color:"#0e0b0a",
                        value:"75%",
                        icon:<AIFont.AiFillGithub size={24} color={"#0e0b0a"}/>
                    },
                    {
                        name:"VS Code",
                        color:"#2791d6",
                        value:"90%",
                        icon:<SIFont.SiVisualstudiocode size={24} color={"#2791d6"}/>
                    },
                    {
                        name:"Figma",
                        color:"#a359ff",
                        value:"80%",
                        icon:<FIFont.FiFigma size={24} color={"#a359ff"}/>
                    },
                    {
                        name:"Canva",
                        color:"#20bbc6",
                        value:"87%",
                        icon:<SIFont.SiCanva size={24} color={"#20bbc6"}/>
                    },
                ]
            }
        ]
    }
]
import React,{useState,useEffect} from 'react'
import { Link,useLocation } from 'react-router-dom'
import "./sidebar.css"
import logo from "../../assets/img/logo-sm.png"

const Sidebar = ({setPallateStatus}) => {
    const [currentRoute, setCurrentRoute] = useState("/")
    const location = useLocation();
    let path = location.pathname
    useEffect(() => {
        setCurrentRoute(path);
    }, [path])
    
    return (
        <div className="sider_container">
            <nav>
                <ul>
                    <li className="logo"><img src={logo} alt="logo" /></li>
                    <Link to="/">
                        <li title="Home" className={`${(currentRoute==="/"?"active":"")} mouse-effect`} ><i className="fas fa-home icon"></i></li>
                    </Link>
                    <Link to="/skills">
                        <li title="Skills" className={`${(currentRoute==="/skills"?"active":"")} mouse-effect`}><i className="fas fa-tools icon"></i></li>
                    </Link>
                    <Link to="/experience">
                        <li title="Experience" className={`${(currentRoute==="/experience"?"active":"")} mouse-effect`} ><i className="fas fa-briefcase icon"></i></li>
                    </Link>
                    <Link to="/projects">
                        <li title="Projects" className={`${(currentRoute==="/projects"?"active":"")} mouse-effect`}><i className="fas fa-file-code icon"></i></li>
                    </Link>
                    <Link to="/education">
                        <li title="Education" className={`${(currentRoute==="/education"?"active":"")} mouse-effect`}><i className="fas fa-graduation-cap icon"></i></li>
                    </Link>
                    <Link to="/contact">
                        <li title="Contact" className={`${(currentRoute==="/contact"?"active":"")} mouse-effect`}><i className="fas fa-handshake icon"></i></li>
                    </Link>
                    
                        <li title="Setting" className="mouse-effect" onClick={()=>setPallateStatus(prevStatus => !prevStatus)}><i className="fas fa-cog icon"></i></li>

                    
                </ul>
            </nav>
        </div>
    )
}

export default Sidebar

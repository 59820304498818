export const education = [
    {
        name:"Undergraduate",
        data:[
            {
                icon:"fab fa-react",
                color:"#61DBFB",
                name:"B.Tech.jsx",
                data:{
                    
                }
            },
        ]
    },
];
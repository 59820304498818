import './App.css';
import {HashRouter , Routes,Route } from 'react-router-dom'
import {useEffect, useState} from "react";

import Footer from './components/footer/Footer';
import HomeInfo from './components/homeInfo/HomeInfo';
import Sidebar from './components/sidebar/Sidebar';
import SideInfoBar from './components/sideInfoBar/SideInfoBar';
import TitleBar from './components/titleBar/TitleBar';
import SkillInfo from './components/skillInfo/SkillInfo';
import Experience from './components/experience/Experience';
import Project from './components/project/Project';
import Education from './components/education/Education';
import Contact from './components/contact/Contact';
import CommandPallate from './components/command/CommandPallate';
import themeData from "./data/theme";
import Canvas from './components/Canvas/Canvas';

function App() {
  const [sliderState, setSliderState] = useState(false);
  const [loading, setLoading] = useState(true);
  const [insideDate, setInsideDate] = useState()
  const [currentData , setCurrentData] = useState()
  const [pallateStatus, setPallateStatus] = useState(false)
  const [theme, setTheme] = useState("")

  const blurhandler = () =>{
    setPallateStatus(false);
  }

  useEffect(()=>{
    if(localStorage.getItem("jp_theme")){
      setTheme(localStorage.getItem("jp_theme"))
    }else{
      localStorage.setItem("jp_theme","Dark")
      setTheme("Dark");
    }
  },[])

  useEffect(()=>{
    if(theme!==""){
      changeTheme();
      localStorage.setItem("jp_theme",theme)
    }
    let ms;
    const mouseStart = async () =>{
      ms = await window['mouseEffect']()
    } 
    mouseStart();
  },[theme])

  const changeTheme = () =>{
    let r = document.querySelector(':root');
    r.style.setProperty('--primary-color', themeData[theme].primary_color);
    r.style.setProperty('--secondary-color', themeData[theme].secondary_color);
    r.style.setProperty('--font-color', themeData[theme].font_color);
    r.style.setProperty('--html-tag', themeData[theme].html_tag);
    r.style.setProperty('--input-background', themeData[theme].input_background);
    r.style.setProperty('--var', themeData[theme].var);
    r.style.setProperty('--var-name', themeData[theme].var_name);
    r.style.setProperty('--string-name', themeData[theme].string_name);
    r.style.setProperty('--inbuilt-func', themeData[theme].inbuilt_func);
    r.style.setProperty('--custom-func', themeData[theme].custom_func);
    r.style.setProperty('--tag-highlight', themeData[theme].tag_highlight);
    r.style.setProperty('--tag-symbol', themeData[theme].tag_symbol);
    r.style.setProperty('--import-name', themeData[theme].import_name);
    r.style.setProperty('--num-color', themeData[theme].num_color);
    r.style.setProperty('--comment-color', themeData[theme].comment_color);
    r.style.setProperty('--sidebar-color', themeData[theme].sidebar_color);
    r.style.setProperty('--sidebar-border-color', themeData[theme].sidebar_border_color);
    r.style.setProperty('--hover-color', themeData[theme].hover_color);
    r.style.setProperty('--tab-active-color', themeData[theme].tab_active_color);
    r.style.setProperty('--tab-content-color', themeData[theme].tab_content_color);
    r.style.setProperty('--theme-color-name', themeData[theme].theme_name_color);
    r.style.setProperty('--number-system-color', themeData[theme].number_system_color);
    r.style.setProperty('--tab-active-font-color', themeData[theme].tab_active_font_color);
    r.style.setProperty('--menubar-background-color', themeData[theme].menubar_background_color);
    r.style.setProperty('--menubar-active-color', themeData[theme].menubar_active_color);
    r.style.setProperty('--menubar-font-color', themeData[theme].menubar_font_color);
    r.style.setProperty('--footer-background-color', themeData[theme].footer_background_color); 
    r.style.setProperty('--footer-font-color', themeData[theme].footer_font_color);
    r.style.setProperty('--border-sidebar-color', themeData[theme].border_sidebar_color);
    r.style.setProperty('--border-footer-color', themeData[theme].border_footer_color);
    r.style.setProperty('--cp-background-color', themeData[theme].cp_background_color);
    r.style.setProperty('--cp-font-color', themeData[theme].cp_font_color);
    r.style.setProperty('--tab-active-border-bottom-color', themeData[theme].tab_active_border_bottom_color);
    r.style.setProperty('--tab-active-border-top-color', themeData[theme].tab_active_border_top_color);
    r.style.setProperty('--mouse-cover', themeData[theme].mouse_cover);
  }

  return (
    <>
      <div style={{height:"96.5vh"}} className="main_container">
        <HashRouter baseline="/" >
          <Canvas theme={theme}/>
          <Sidebar setPallateStatus={setPallateStatus}/>
          <SideInfoBar sliderState={sliderState} setSliderState={setSliderState} insideDate={insideDate} setInsideDate={setInsideDate} setCurrentData={setCurrentData} currentData={currentData} setPallateStatus={setPallateStatus}/>
          {
            pallateStatus && (
              <CommandPallate setPallateStatus={setPallateStatus} theme={theme} setTheme={setTheme}/>
            )
          }
        <div className={`${(!sliderState)?"":"backward"} details_container`}>
          <TitleBar/>
          <Routes>
            <Route exact path={process.env.PUBLIC_URL+"/"} element={<HomeInfo loading={loading} setLoading={setLoading} setPallateStatus={setPallateStatus} theme={theme}/>}/>
            <Route exact path={process.env.PUBLIC_URL+"/skills"} element={<SkillInfo loading={loading} setLoading={setLoading} insideDate={insideDate} currentData={currentData} setPallateStatus={setPallateStatus} />}/>
            <Route exact path="/experience" element={<Experience loading={loading} setLoading={setLoading} insideDate={insideDate} currentData={currentData} setPallateStatus={setPallateStatus} />}/>
            <Route exact path="/projects" element={<Project loading={loading} setLoading={setLoading} insideDate={insideDate} currentData={currentData} setPallateStatus={setPallateStatus} />}/>
            <Route exact path="/education" element={<Education loading={loading} setLoading={setLoading} insideDate={insideDate} currentData={currentData} setPallateStatus={setPallateStatus} />}/>
            <Route exact path="/contact" element={<Contact loading={loading} setLoading={setLoading} setPallateStatus={setPallateStatus} />}/>
          </Routes>
        </div>
        </HashRouter>
      </div>
      <Footer/>
      
    </>
    
  );
}

export default App;



import React from 'react'
import "./education.css"
import Loader from '../loader/Loader'
import img from "../../assets/img/education.svg"
import { Helmet } from 'react-helmet'

const Education = ({loading,setLoading,insideDate,setPallateStatus}) => {
    return (
        <div id="education_container" onClick={()=>setPallateStatus(false)}>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Jay Patel - Education</title>
                <link rel="canonical" href="https://pateljay.me/#/education" />
                <meta name="description" content="Academic Perfomance of Jay Patel for each semester from the Bachelor's Degree"/>
            </Helmet>
            <img src={img} alt="Education Page Icon" style={{width:"250px",position:"absolute",right:"5%",bottom:"40%"}}/>
            {(insideDate && !insideDate.data.length)?(
               <>
                <div className="education_heading">
                   <span className="number_system">1</span>
                   <div className="education_name">Bachelor of Technology</div>
                </div>
                <div className="education_info_container">
                    <div className="education_line_container">
                        <span className="number_system">2</span>
                        <div className="education_line">
                            <div><span className="tag_highlight">import</span> <span className="import_name">Information and Communication Technology</span> <span className="tag_highlight">as</span> <span className="import_name">ICT</span> <span className="tag_highlight">from</span> <span className="string">"Pandit Deendayal Energy University"</span>;</div>
                        </div>
                    </div>
               </div>
               <div className="education_info_container">
                    <div className="education_line_container">
                        <span className="number_system">3</span>
                        <div className="education_line">
                        <div><span className="var">const</span> <span className="var_name variable">Years</span> = <span className="import_name">ICT</span>.<span className='custom_func'>from</span>(<span className='num'>2019</span>,<span className='num'>2023</span>);</div>
                        </div>
                    </div>
               </div>
               <div className="education_info_container">
                    <div className="education_line_container">
                        <span className="number_system">4</span>
                        <div className="education_line">
                        <div><span className="var">const</span> <span className="var_name variable">FirstSemSPI</span> = <span className="import_name">ICT</span>.<span className='custom_func'>getSPI</span>(<span className='num'>1</span>) <span className='comment'> {"//"}output: 9.98/10</span></div>
                        </div>
                    </div>
               </div>
               <div className="education_info_container">
                    <div className="education_line_container">
                        <span className="number_system">5</span>
                        <div className="education_line">
                        <div><span className="var">const</span> <span className="var_name variable">SecondSemSPI</span> = <span className="import_name">ICT</span>.<span className='custom_func'>getSPI</span>(<span className='num'>2</span>) <span className='comment'> {"//"}output: 10/10</span></div>
                        </div>
                    </div>
               </div>
               <div className="education_info_container">
                    <div className="education_line_container">
                        <span className="number_system">6</span>
                        <div className="education_line">
                        <div><span className="var">const</span> <span className="var_name variable">ThirdSemSPI</span> = <span className="import_name">ICT</span>.<span className='custom_func'>getSPI</span>(<span className='num'>3</span>) <span className='comment'> {"//"}output: 9.6/10</span></div>
                        </div>
                    </div>
               </div>
               <div className="education_info_container">
                    <div className="education_line_container">
                        <span className="number_system">7</span>
                        <div className="education_line">
                        <div><span className="var">const</span> <span className="var_name variable">FourthSemSPI</span> = <span className="import_name">ICT</span>.<span className='custom_func'>getSPI</span>(<span className='num'>4</span>) <span className='comment'> {"//"}output: 9.7/10</span></div>
                        </div>
                    </div>
               </div>
               <div className="education_info_container">
                    <div className="education_line_container">
                        <span className="number_system">8</span>
                        <div className="education_line">
                        <div><span className="var">const</span> <span className="var_name variable">FifthSemSPI</span> = <span className="import_name">ICT</span>.<span className='custom_func'>getSPI</span>(<span className='num'>5</span>) <span className='comment'> {"//"}output: 9.82/10</span></div>
                        </div>
                    </div>
               </div>
               <div className="education_info_container">
                    <div className="education_line_container">
                        <span className="number_system">9</span>
                        <div className="education_line">
                        <div><span className="var">const</span> <span className="var_name variable">SixthSemSPI</span> = <span className="import_name">ICT</span>.<span className='custom_func'>getSPI</span>(<span className='num'>6</span>) <span className='comment'> {"//"}output: Loading...</span></div>
                        </div>
                    </div>
               </div>
               <div className="education_info_container">
                    <div className="education_line_container">
                        <span className="number_system">10</span>
                        <div className="education_line double-digit" >
                        <div><span className="var">const</span> <span className="var_name variable">SeventhSemSPI</span> = <span className="import_name">ICT</span>.<span className='custom_func'>getSPI</span>(<span className='num'>7</span>) <span className='comment'> {"//"}output: Loading...</span></div>
                        </div>
                    </div>
               </div>
               <div className="education_info_container">
                    <div className="education_line_container">
                        <span className="number_system">11</span>
                        <div className="education_line double-digit" >
                        <div><span className="var">const</span> <span className="var_name variable">EigthSemSPI</span> = <span className="import_name">ICT</span>.<span className='custom_func'>getSPI</span>(<span className='num'>8</span>) <span className='comment'> {"//"}output: Loading...</span></div>
                        </div>
                    </div>
               </div>
               <div className="education_info_container">
                    <div className="education_line_container">
                        <span className="number_system">12</span>
                        <div className="education_line double-digit">
                        <div><span className="var">const</span> <span className="var_name variable">OverallCPI</span> = <span className="import_name">ICT</span>.<span className='custom_func'>getCPI</span>(<span className="import_name">FirstSemSPI</span>, <span className="import_name">SecondSemSPI</span>, <span className="import_name">ThirdSemSPI</span>, <span className="import_name">FourthSemSPI</span>) </div>
                        </div>
                    </div>
               </div>
               <div className="education_info_container">
                    <div className="education_line_container">
                        <span className="number_system">13</span>
                        <div className="education_line double-digit">
                        <div><span className='comment'> {"//"}CPI output: 9.81/10</span></div>
                        </div>
                    </div>
               </div>
               </>
           ):<Loader/>}
        </div>
    )
}

export default Education

import React from 'react'
import "./contact.css"
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';
import emailjs from 'emailjs-com';
import { css } from 'glamor';
import { useForm } from 'react-hook-form';
import contact from "../../assets/img/contact.svg"
import {Helmet} from "react-helmet";

const Contact = ({setPallateStatus}) => {
    const {
        register,
        handleSubmit,
        reset,
        formState: { errors }
      } = useForm();

    const toastifySuccess = () => {
        toast.dark('Response sent!', {
          position: 'bottom-left',
          autoClose: 5000,
          hideProgressBar: true, 
          closeOnClick: true,
          pauseOnHover: true,  
          draggable: false,
          className: css({
            background: "#00FF00 !important"
        }),
          toastId: 'notifyToast'
        });
      };

      const onSubmit = async (data) => {
        const { name, email, subject, message } = data;
        try {
            const templateParams = {
            name,
            email,
            subject,
            message
            };
            await emailjs.send(
            process.env.REACT_APP_SERVICE_ID,
            process.env.REACT_APP_TEMPLATE_ID,
            templateParams,
            process.env.REACT_APP_USER_ID
            );
            reset();
            toastifySuccess();
        } catch (e) {
            console.log(e);
        }
      };
    return (
        <div id="contact_container" onClick={()=>setPallateStatus(false)}>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Jay Patel - Contact</title>
                <link rel="canonical" href="https://pateljay.me/#/contact" />
                <meta name="description" content="Jay Patel's Contact Information"/>
            </Helmet>
            <img src={contact} alt="Contact Page Icon" style={{width:"300px",position:"absolute",right:"5%",bottom:"25%"}}/>
            <form onSubmit={handleSubmit(onSubmit)}>
                <div className="contact_heading">
                   <span className="number_system">1</span>
                   <div className="contact_name">Get In Touch</div>
                </div>
                <div className="contact_info_container">
                    <div className="contact_line_container">
                        <span className="number_system">2</span>
                        <div className="contact_line">
                            <div><span className='tag_symbol'>{'<'}</span><span className="html_tag">div</span><span className='tag_symbol'>{'>'}</span></div>
                        </div>
                    </div>
               </div>
               <div className="contact_info_container">
                    <div className="contact_line_container">
                        <span className="number_system">3</span>
                        <div className="contact_line tab">
                            <div>Name: <span className='tag_symbol'>{'<'}</span><span className="html_tag">input</span><span className='import_name'> type</span>=<span className='string'>"text"</span><span className='import_name'> value</span>=<span className='comment'>"</span><input type="text" className='comment' {...register('name', {
                                        required: { value: true, message: 'Please enter your name' },
                                        maxLength: {
                                          value: 30,
                                          message: 'Please use 30 characters or less'
                                        }
                                      })} placeholder='Type Here' /><span className='comment'>"</span><span className='tag_symbol'>{' />'}</span></div>
                        </div>
                    </div>
               </div>
               <div className="contact_info_container">
                    <div className="contact_line_container">
                        <span className="number_system">4</span>
                        <div className="contact_line">
                            <div><span className='tag_symbol'>{'</'}</span><span className="html_tag">div</span><span className='tag_symbol'>{'>'}</span></div>
                        </div>
                    </div>
               </div>
               <div className="contact_info_container partition">
                    <div className="contact_line_container">
                        <span className="number_system">5</span>
                        <div className="contact_line">
                            <div><span className='tag_symbol'>{'<'}</span><span className="html_tag">div</span><span className='tag_symbol'>{'>'}</span></div>
                        </div>
                    </div>
               </div>
               <div className="contact_info_container">
                    <div className="contact_line_container">
                        <span className="number_system">6</span>
                        <div className="contact_line tab">
                            <div>Email: <span className='tag_symbol'>{'<'}</span><span className="html_tag">input</span><span className='import_name'> type</span>=<span className='string'>"email"</span><span className='import_name'> value</span>=<span className='comment'>"</span><input type="email" className='comment' {...register('email', {
                                        required: true,
                                        pattern: /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/
                                      })} placeholder='Type Here' /><span className='comment'>"</span><span className='tag_symbol'>{' />'}</span></div>
                        </div>
                    </div>
               </div>
               <div className="contact_info_container">
                    <div className="contact_line_container">
                        <span className="number_system">7</span>
                        <div className="contact_line">
                            <div><span className='tag_symbol'>{'</'}</span><span className="html_tag">div</span><span className='tag_symbol'>{'>'}</span></div>
                        </div>
                    </div>
               </div>
               <div className="contact_info_container partition">
                    <div className="contact_line_container">
                        <span className="number_system">8</span>
                        <div className="contact_line">
                            <div><span className='tag_symbol'>{'<'}</span><span className="html_tag">div</span><span className='tag_symbol'>{'>'}</span></div>
                        </div>
                    </div>
               </div>
               <div className="contact_info_container">
                    <div className="contact_line_container">
                        <span className="number_system">9</span>
                        <div className="contact_line tab">
                            <div>Message:</div>
                        </div>
                    </div>
               </div>
               <div className="contact_info_container">
                    <div className="contact_line_container">
                        <span className="number_system">10</span>
                        <div className="contact_line tab-double-digit">
                            <div><span className='tag_symbol'>{'<'}</span><span className="html_tag">textarea</span><span className='tag_symbol'>{'>'}</span></div>
                        </div>
                    </div>
               </div>
               <div className="contact_info_container">
                    <div className="contact_line_container" style={{alignItems:"flex-start"}}>
                        <span className="number_system">11</span>
                        <div className="contact_line tab2 double-digit">
                            <div><textarea className='comment' {...register('message', {
                                        required: true
                                      })} placeholder='"Type Here"' cols={15} rows={1} style={{width:"auto",display:"inline-block"}}></textarea></div>
                        </div>
                    </div>
               </div>
               <div className="contact_info_container">
                    <div className="contact_line_container">
                        <span className="number_system">12</span>
                        <div className="contact_line tab-double-digit" >
                            <div><span className='tag_symbol'>{'</'}</span><span className="html_tag">textarea</span><span className='tag_symbol'>{'>'}</span></div>
                        </div>
                    </div>
               </div>
               <div className="contact_info_container">
                    <div className="contact_line_container">
                        <span className="number_system">13</span>
                        <div className="contact_line  double-digit" >
                            <div><span className='tag_symbol'>{'</'}</span><span className="html_tag">div</span><span className='tag_symbol'>{'>'}</span></div>
                        </div>
                    </div>
               </div>
               <div className="contact_info_container">
                    <div className="contact_line_container">
                        <span className="number_system">14</span>
                        <div className="contact_line  double-digit" >
                            <div><span className='tag_symbol'>{'<'}</span><span className="html_tag">button</span><span className='import_name'> type</span>=<span className='string'>"submit"</span><span className='tag_symbol'>{'>'}</span><button className='comment' style={{width:"auto",cursor:"pointer"}}>Send</button><span className='tag_symbol'>{'</'}</span><span className="html_tag">button</span><span className='tag_symbol'>{'>'}</span></div>
                        </div>
                    </div>
               </div>
               <ToastContainer/>
               </form>
        </div>
    )
}

export default Contact

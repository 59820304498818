export const experience = [
    {
        name:"Internships",
        data:[
            {
                icon:"fab fa-js-square",
                color:"#FCDC00",
                name:"Infopercept.js",
                data:{
                    name:"Infopercept Consulting",
                    position:"Full Stack Web Developer",
                    from:"April 2021 - April 2022",
                    responsibilites: <><div className="li_line"><span className="number_system">5</span><li>Currently working on building Custom CMS for the company’s main website on <span className="tag_highlight">MERN</span> Stack.</li></div><div className="li_line"><span className="number_system">6</span><li>Implemented <span className="tag_highlight">SSO</span> for the GSOS portal of <span className="tag_highlight">4 open source</span> services integrated into the platform that increases the <span className="tag_highlight">user experience</span>.</li></div><div className="li_line"><span className="number_system">7</span><li>Increased the pace of development process by <span className="tag_highlight">90%</span> of news generation section through <span className="tag_highlight">PHP, JavaScript</span> scripting that dynamically generates the HTML files from <span className="tag_highlight">excel sheets</span>.</li></div><div className="li_line"><span className="number_system">8</span><li>Developed the GSOS mangement portal with dynamic features like <span className="tag_highlight">quiz, dashboard, war game, support,</span> etc.</li></div></>
                }
            },
            {
                icon:"fab fa-js-square",
                color:"#FCDC00",
                name:"Caarya.js",
                data:{
                    name:"Caarya",
                    position:"Backend Developer",
                    from:"November 2021 - February 2021",
                    responsibilites: <><div className="li_line"><span className="number_system">5</span><li>Developed the dream11 fantasy point system for the <span className="tag_highlight">sportzi</span> portal that calculates the players point based on stats and stored in <span className="tag_highlight">PostgreSQL</span>.</li></div><div className="li_line"><span className="number_system">6</span><li>Developed an <span className="tag_highlight">API</span> to fetch all cricket matches datewise.</li></div><div className="li_line"><span className="number_system">7</span><li>Fixes several backend as well as frontend bugs.</li></div><div className="li_line"><span className="number_system">8</span><li>Currently working on Zunavish platform and fixed the <span className="tag_highlight">30+</span> bugs on the platform.</li></div></>
                }
            },
            {
                icon:"fab fa-js-square",
                color:"#FCDC00",
                name:"Acciojob.js",
                data:{
                    name:"Acciojob",
                    position:"Fronted Mentor",
                    from:"November 2021 - March 2022",
                    responsibilites: <><div className="li_line"><span className="number_system">5</span><li>To Take one on one session with the student and clear the doubts and challenges faced during their learning face.</li></div><div className="li_line"><span className="number_system">6</span><li>Conducted <span className="tag_highlight">100+</span> session till date with the students regarding the mentorship and mock interview preperation to make them industry ready.</li></div><div className="li_line"><span className="number_system">7</span><li>To Provide constant support to the student and to be in touch with them to guide them in proper direction and learning path.</li></div></>
                }
            },
        ]
    },
    {
        name:"Community Engagement",
        data:[
            {
                icon:"fab fa-js-square",
                color:"#FCDC00",
                name:"Google.js",
                data:{
                    name:"Google Developer Student Club",
                    position:"General Secretory",
                    from:"September 2021 - May 2021",
                    responsibilites: <><div className="li_line"><span className="number_system">5</span><li>To work closely with the <span className="tag_highlight">board committee</span> for the upliftment of the community.</li></div><div className="li_line"><span className="number_system">6</span><li>To manage the department lead and the core team consisting of <span className="tag_highlight">33 people</span>.</li></div><div className="li_line"><span className="number_system">7</span><li>To manage the content work of the community.</li></div><div className="li_line"><span className="number_system">8</span><li>To conduct <span className="tag_highlight">event, competitions, workshops</span> on computer science.</li></div></>
                }
            },
            {
                icon:"fab fa-js-square",
                color:"#FCDC00",
                name:"Encode.js",
                data:{
                    name:"Encode",
                    position:"Web Head",
                    from:"October 2020 - May 2022",
                    responsibilites: <><div className="li_line"><span className="number_system">5</span><li>Developed <span className="tag_highlight">5+ websites</span> for the various clubs of the college.</li></div><div className="li_line"><span className="number_system">6</span><li>Conducted <span className="tag_highlight">2 sessions</span> for the campus peers on <span className="tag_highlight">open source contribution, Git and GitHub</span>.</li></div><div className="li_line"><span className="number_system">7</span><li>Developed dynamic features like <span className="tag_highlight">Form Builder, Weekly Coder leaderboard,</span> etc for the organization websites.</li></div><div className="li_line"><span className="number_system">8</span><li>Contributed as <span className="tag_highlight">Backend Developer</span> for the development of Logic Hunt a Quiz Portal in which <span className="tag_highlight">100+ users</span> were participated.</li></div></>
                }
            },
            {
                icon:"fab fa-js-square",
                color:"#FCDC00",
                name:"Microsoft.js",
                data:{
                    name:"Microsoft",
                    position:"Microsoft Learn Student Ambassador - Beta",
                    from:"January 2021 - Present",
                    responsibilites: <><div className="li_line"><span className="number_system">5</span><li>To help new MLSA in their journey and to build a <span className="tag_highlight">community</span> with strong technical background.</li></div><div className="li_line"><span className="number_system">6</span><li>To make community aware about the latest <span className="tag_highlight">Microsoft Technology and services</span>.</li></div><div className="li_line"><span className="number_system">7</span><li>To conduct event, workshops, competitions in local community to make them aware about new technology.</li></div><div className="li_line"><span className="number_system">8</span><li>Conducted <span className="tag_highlight">2 sessions</span> in collaboration with other clubs on <span className="tag_highlight">Version Control System</span>.</li></div></>
                }
            },
            {
                icon:"fab fa-js-square",
                color:"#FCDC00",
                name:"Postman.js",
                data:{
                    name:"Postman",
                    position:"Postman Student Leader",
                    from:"October 2021 - Present",
                    responsibilites: <><div className="li_line"><span className="number_system">5</span><li>To make community <span className="tag_highlight">API literate</span> and make them aware about the latest features of Postman.</li></div><div className="li_line"><span className="number_system">6</span><li>Conducted <span className="tag_highlight">API 101</span> event in the campus to give peers an overview of API and postman.</li></div><div className="li_line"><span className="number_system">7</span><li>To explore various functionality of Postman portal and spread words among local community.</li></div><div className="li_line"><span className="number_system">8</span><li>To engage with postman student community and solves the doubts faced by the users about API and Postman.</li></div></>
                }
            },
            {
                icon:"fab fa-js-square",
                color:"#FCDC00",
                name:"CSI.js",
                data:{
                    name:"Computer Science Society of India",
                    position:"Technical Head",
                    from:"November 2021 - Present",
                    responsibilites: <><div className="li_line"><span className="number_system">5</span><li>Conducted <span className="tag_highlight">one 2 days</span> workshop on web portfolio building with <span className="tag_highlight">100+ participants</span>.</li></div><div className="li_line"><span className="number_system">6</span><li>To came up with various <span className="tag_highlight">technical event</span> idea to make peers around campus aware about it.</li></div><div className="li_line"><span className="number_system">7</span><li>To provide technical stuff like <span className="tag_highlight">resources, ppt,</span> etc to speaker for any session.</li></div><div className="li_line"><span className="number_system">8</span><li>To handle any <span className="tag_highlight">technical glitches</span> during the event and make the workflow of event smoother.</li></div></>
                }
            },
            {
                icon:"fab fa-js-square",
                color:"#FCDC00",
                name:"SAWO.js",
                data:{
                    name:"SAWO",
                    position:"Community Champ",
                    from:"October 2021 - January 2021",
                    responsibilites: <><div className="li_line"><span className="number_system">5</span><li>To make student around the community aware about the <span className="tag_highlight">SAWO product</span> and its functionality.</li></div><div className="li_line"><span className="number_system">6</span><li>To enagage with <span className="tag_highlight">SAWO student community</span> and help them to guide in proper direction in terms of product usage</li></div><div className="li_line"><span className="number_system">7</span><li>To try out any latest features launched by company and provide a valuable <span className="tag_highlight">feedback</span> around it.</li></div><div className="li_line"><span className="number_system">8</span><li>To organize various speaker event around the campus through SAWO.</li></div></>
                }
            },
            {
                icon:"fab fa-js-square",
                color:"#FCDC00",
                name:"Dell.js",
                data:{
                    name:"Dell",
                    position:"Campassador",
                    from:"October 2021 - March 2020",
                    responsibilites: <><div className="li_line"><span className="number_system">5</span><li>To make students participate in various <span className="tag_highlight">Dell programs and competitions</span>.</li></div><div className="li_line"><span className="number_system">6</span><li>To actively enagage among the community and participate in all events.</li></div><div className="li_line"><span className="number_system">7</span><li>To make students aware of the latest dell technology.</li></div></>
                }
            },
            {
                icon:"fab fa-js-square",
                color:"#FCDC00",
                name:"AIESEC.js",
                data:{
                    name:"AIESEC",
                    position:"Incoming Global Talent - Member",
                    from:"February 2021 - August 2021",
                    responsibilites: <><div className="li_line"><span className="number_system">5</span><li>Focus on bringing <span className="tag_highlight">global interns</span> for the companies at Ahmedabad.</li></div><div className="li_line"><span className="number_system">6</span><li>Contacted <span className="tag_highlight">100+ companies</span> for establishing a partnership with AIESEC.</li></div><div className="li_line"><span className="number_system">7</span><li>Attended Several <span className="tag_highlight">International Relation Calls</span> to maintain relation with foreign entities.</li></div><div className="li_line"><span className="number_system">8</span><li>To communicate with global interns who are willing to come to Ahmedabad for Internship.</li></div></>
                }
            },
        ]
    }
];
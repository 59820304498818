import React,{useState,useEffect} from 'react'
import {useLocation,useNavigate} from 'react-router-dom'
import "./titleBar.css"

const TitleBar = () => {
    const [currentRoute, setCurrentRoute] = useState("/")
    const location = useLocation();
    let path = location.pathname
    useEffect(() => {
        setCurrentRoute(path);
    }, [path])

    const history = useNavigate()

    const goToHome = () =>{
        history('/')
    }

    
    return (
        <div className="menu_container">
            <div className={`menu ${(currentRoute==="/")?"active_tab":""}`} >
                <div className="icon_container">
                    <i className="fas fa-chevron-left icon"></i>
                    <i className="fas fa-chevron-right icon"></i>
                </div>
                Home.html
            </div>
            {
                (currentRoute!=="/")?(
                    <div className="menu active_tab">
                        <div className="icon_container">
                            <i className="fas fa-chevron-left icon"></i>
                            <i className="fas fa-chevron-right icon"></i>
                        </div>
                        {currentRoute.replace("/","")[0].toUpperCase()+currentRoute.replace("/","").slice(1)}.html

                        <i className="fas fa-times close_icon" onClick={goToHome}></i>
                    </div>
                ):""
            }
            
        </div>
    )
}

export default TitleBar
